import { network, contracts } from "./config/main"
# import { network, contracts } from "./config/shasta"
# import { network, contracts } from "./config/local"

allowances = []
allowances.push
  description: "Для контракта Token Market"
  contract: contracts.TokenMarket
  tokens: ['USDT','HYHO','ARS','PNX','CLC']
  # tokens: ['USDT','HYHO','ARS','PNX','CLC','BST','DEX']
allowances.push
  description: "Для биржевого контракта торговой пары GIG/USDT"
  contract: contracts.OrderBook["GIG/USDT"]
  tokens: ['GIG', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары IGX/USDT"
  contract: contracts.OrderBook["IGX/USDT"]
  tokens: ['IGX', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары IGXT/USDT"
  contract: contracts.OrderBook["IGXT/USDT"]
  tokens: ['IGXT', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары HYHO/TRX"
  contract: contracts.OrderBook["HYHO/TRX"]
  tokens: ['HYHO']
allowances.push
  description: "Для биржевого контракта торговой пары ARS/USDT"
  contract: contracts.OrderBook["ARS/USDT"]
  tokens: ['ARS', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары PNX/USDT"
  contract: contracts.OrderBook["PNX/USDT"]
  tokens: ['PNX', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары CLC/USDT"
  contract: contracts.OrderBook["CLC/USDT"]
  tokens: ['CLC', 'USDT']
allowances.push
  description: "Для биржевого контракта торговой пары DEX/USDT"
  contract: contracts.OrderBook["DEX/USDT"]
  tokens: ['DEX', 'USDT']
allowances.push
  description: "Для контракта игры King of the Hill"
  contract: contracts.CompetitiveTokenSale
  tokens: ['HYHO']
allowances.push
  description: "Для контракта InvestBox (ARS)"
  contract: contracts.InvestBox.ars
  tokens: ['USDT', 'ARS']
allowances.push
  description: "Для контракта InvestBox (PNX)"
  contract: contracts.InvestBox.pnx
  tokens: ['USDT', 'PNX']
allowances.push
  description: "Для контракта InvestBox (CLC)"
  contract: contracts.InvestBox.clc
  tokens: ['USDT', 'CLC']
allowances.push
  description: "Для контракта Cashback"
  contract: contracts.MultistageSwap['HYHO']
  tokens: ['HYHO']
allowances.push
  description: "Для контракта Cashback Pro"
  contract: contracts.MultistageSwap['ARS']
  tokens: ['ARS']
allowances.push
  description: "Для контракта Swap to ARS раздела Staking"
  contract: contracts.ComboSwap.ARS
  tokens: ['USDT', 'HYHO']
allowances.push
  description: "Для контракта Swap to PNX раздела Staking"
  contract: contracts.ComboSwap.PNX
  tokens: ['USDT', 'ARS']
allowances.push
  description: "Для контракта Swap to CLC раздела Staking"
  contract: contracts.ComboSwap.CLC
  tokens: ['USDT', 'ARS', 'BST']
allowances.push
  description: "Для контракта Swap to IGX раздела Staking"
  contract: contracts.ComboSwap.IGX
  tokens: ['USDT', 'IGXT', 'IGX']
allowances.push
  description: "Для контракта Staking Light (ARS)"
  contract: contracts.AcceleratedStaking.ARS[0]
  tokens: ['ARS']
allowances.push
  description: "Для контракта Staking Basic (ARS)"
  contract: contracts.AcceleratedStaking.ARS[1]
  tokens: ['ARS']
allowances.push
  description: "Для контракта Staking Pro (ARS)"
  contract: contracts.AcceleratedStaking.ARS[2]
  tokens: ['ARS']
allowances.push
  description: "Для контракта Staking Light (PNX)"
  contract: contracts.AcceleratedStaking.PNX[0]
  tokens: ['PNX']
allowances.push
  description: "Для контракта Staking Basic (PNX)"
  contract: contracts.AcceleratedStaking.PNX[1]
  tokens: ['PNX']
allowances.push
  description: "Для контракта Staking Pro (PNX)"
  contract: contracts.AcceleratedStaking.PNX[2]
  tokens: ['PNX']
allowances.push
  description: "Для контракта Staking Light (CLC)"
  contract: contracts.AcceleratedStaking.CLC[0]
  tokens: ['CLC']
allowances.push
  description: "Для контракта Staking Basic (CLC)"
  contract: contracts.AcceleratedStaking.CLC[1]
  tokens: ['CLC']
allowances.push
  description: "Для контракта Staking Pro (CLC)"
  contract: contracts.AcceleratedStaking.CLC[2]
  tokens: ['CLC']
allowances.push
  description: "Для контракта Staking Light (USDT)"
  contract: contracts.AcceleratedStaking.USDT[0]
  tokens: ['USDT']
allowances.push
  description: "Для контракта Staking Basic (USDT)"
  contract: contracts.AcceleratedStaking.USDT[1]
  tokens: ['USDT']
allowances.push
  description: "Для контракта Staking Pro (USDT)"
  contract: contracts.AcceleratedStaking.USDT[2]
  tokens: ['USDT']

export default
  tronLink:
    network: network
    # network: "shasta"
    # network: "nile"
    # network: "local"
    # network: "mainnet"
    nodes:
      "https://api.trongrid.io":
        name: "TRON Mainnet (TronGrid)"
        network: "mainnet"
      "https://api.tronstack.io":
        name: "TRON Mainnet (TronStack)"
        network: "mainnet"
      "https://api.shasta.trongrid.io":
        name: "TRON Shasta Testnet"
        network: "shasta"
      "https://api.nileex.io":
        name: "TRON Nile Testnet"
        network: "nile"
      "https://sun.tronex.io":
        name: "DAppChain Mainnet"
        network: "sun"
      "https://suntest.tronex.io":
        name: "DAppChain Testnet"
        network: "suntest"
      "http://api.trongrid.io":
        name: "TRON Mainnet (TronGrid)"
        network: "mainnet"
      "http://localhost:9090":
        name: "localhost"
        network: "local"
  contracts: contracts
  allowances: allowances
  control:
    permissions:
      'Tron': [
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TBuztd3Eh2VKai1yaReTrWgEJde1SSrCse'
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ],
      'King of the Hill': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
      'Cashback': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
      'Staking': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
      'Exchange': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
        'TGpzqqukcy4X9VqUMCcPQdhsVfF3ZhDaTj'
      ]
      'Cabinet': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
      'Token Market': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
      'Empire Network': [
        'TGgKdmc4s15MstaZbGTammrPojkvAerHwK'
        'TFsceNgCEvz6UWexuv3W145TvHKVjZ7fsn'
        'TLJuy58Wx88nmd2AMmcksPFYR8GJNmdPBk'
        'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
        'TESTD1g76NTQKozjrV8Hv1Se6NbJ9rcZ2c'
      ]
    map:
      'Tron': 'Tron'
      'King of the Hill': 'KingOfTheHill'
      'Cashback': 'Cashback'
      'Staking': 'Staking'
      'Exchange': 'Exchange'
      'Cabinet': 'Cabinet'
      'Token Market': 'TokenMarket'
      'Empire Network': 'Binar'