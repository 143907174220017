




























































import { mapState } from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'

decapitalize = (s) -> s.charAt(0).toLowerCase() + s.slice(1)

export default
  components: {TokenIcon}
  data: ->
    dialog:
      show: false
      locked: false
      title: ""
      contract: ""
      tokens: []
      values: {}
  computed: mapState 'Cabinet', ['allowances', 'loadingAllowances']
  methods:
    editAllowances: (contract, tokens, description) ->
      @dialog.title = "Изменение разрешений " + decapitalize(description)
      @dialog.show = true
      @dialog.tokens = tokens
      @dialog.contract = contract
      @$set(@dialog.values, token, allowance) for {token, allowance} in tokens
      # alert(JSON.stringify(arguments))
    hideDialog: -> @dialog.show = false
    approve: (token, value) ->
      spender = @dialog.contract
      value = tronWeb.toSun(value)
      contractAddress = config.contracts.TRC20[token]
      @$store.dispatch('contracts/load', contractAddress).then (contract) =>
        @dialog.locked = true
        contract.approve(spender, value).send().then =>
          @dialog.locked = false
          @dialog.show = false
          @$store.dispatch('Cabinet/getAllowances')
        .catch =>
          @dialog.locked = false

