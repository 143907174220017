







export default
  props:
    color: String
    color2: String
