






























































import {mapState, mapGetters} from 'vuex'

import {verboseSend, checkTrxBalance, checkTokenBalance, repeat} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

export default
  components: {Allowance, Registration}
  data: ->
    processing: false
  computed: {
    ...mapGetters 'TokenMarket', [
      'userAddress'
      'packagesLeft'
      'price1'
      'price2'
      'payout'
      'referralSpeedup'
      'period'
    ]
    ...mapState 'TokenMarket', ['contract']
  }
  mounted: ->
    repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20[@price1.token])).then (contract) => @tokenContract1 = contract
    repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20[@price2.token])).then (contract) => @tokenContract2 = contract
  methods:
    buy: ->
      # trxPrice = tronWeb.toSun(@currentPackage.trxPrice)
      # tokenPrice = tronWeb.toSun(@currentPackage.tokenPrice)
      console.info @price1
      @processing = true
      if  await checkTokenBalance(@userAddress, tronWeb.toSun(@price1.amount), @price1.token, @tokenContract1) and
          await checkTokenBalance(@userAddress, tronWeb.toSun(@price2.amount), @price2.token, @tokenContract2) and
          await checkRegistration() and
          await checkAllowance(tronWeb.toSun(@price1.amount), @price1.token, @contract.address, 'Разрешить контракту Token Market списывать с вашего адреса') and
          await checkAllowance(tronWeb.toSun(@price2.amount), @price2.token, @contract.address, 'Разрешить контракту Token Market списывать с вашего адреса')
          # await checkAllowance(tokenPrice, "HYHO", config.contracts.MultistageSwap.HYHO, 'Разрешить контракту InvestBox списывать с вашего адреса')
        # verboseSend @contract.buy(), callValue: trxPrice, feeLimit: tronWeb.toSun(200)
        verboseSend @contract.buyPackage(), feeLimit: tronWeb.toSun(200)
        .then (msg) =>
          msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
          msg.duration = 3000
          # @$emit 'reload', silent: true
          setTimeout (=>
            @$emit 'reload', silent: true
            return
            ), 100
        .finally =>
          @processing = false
        return
      @processing = false

