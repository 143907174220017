





















import { BProgress } from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'

export default
  components: {BProgress}
  # data: ->
  computed: {
    ...mapGetters 'TokenMarket', ['payoutProgress']
    exists: -> @payoutProgress.exists
    number: -> @payoutProgress.number
    total: -> @payoutProgress.total
    current: -> @payoutProgress.current
  }
