













import Summary from './Cabinet/Summary.vue'
import Actions from './Cabinet/Actions.vue'
import Events from './Cabinet/Events.vue'
import Ownership from './Cabinet/Ownership.vue'

export default
  components: {Summary, Actions, Events, Ownership}
