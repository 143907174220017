






























import {mapState, mapGetters} from 'vuex'

import {verboseSend, checkTrxBalance, checkTokenBalance, repeat} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

export default
  components: {Allowance, Registration}
  data: ->
    processing: false
  computed: {
    ...mapState 'Cashback',
      started: 'isStartedCashback'
      contract: 'contractCashback'
    # ...mapGetters 'Cashback', ['currentPackage']
    currentPackage: -> @$store.getters['Cashback/currentPackage'].cashback
    ...mapGetters 'Cashback', ['userAddress']
  }
  mounted: ->
    repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20["HYHO"])).then (contract) => @tokenContract = contract
  methods:
    buy: ->
      trxPrice = tronWeb.toSun(@currentPackage.trxPrice)
      tokenPrice = tronWeb.toSun(@currentPackage.tokenPrice)
      @processing = true
      if  await checkTrxBalance(@userAddress, trxPrice) and
          await checkTokenBalance(@userAddress, tokenPrice, "HYHO", @tokenContract) and
          await checkRegistration() and
          await checkAllowance(tokenPrice, "HYHO", config.contracts.MultistageSwap.HYHO, 'Разрешить контракту Cashback списывать с вашего адреса')
        verboseSend @contract.buy(), callValue: trxPrice, feeLimit: tronWeb.toSun(200)
        .then (msg) =>
          msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
          msg.duration = 3000
          @$emit 'reload', silent: true
        .finally =>
          @processing = false
        return
      @processing = false

