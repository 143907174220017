



























# import BigNumber from 'bignumber.js'
import config from '../config.coffee'
import {mapState, mapGetters} from 'vuex'
import {isValidAddress} from '../store/functions.coffee'
import {repeat} from '../store/functions.coffee'

_checkFirstPlay = null

export isFirstPlay = -> _checkFirstPlay(...arguments)

export default
  data: ->
    waiting: false
    parent:
      value: ""
    title: "Начало первой игры"
    visible: false
  computed: {
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
    ...mapState 'Tron', ['contract']
    rules: ->
      value: [
        trigger: 'change'
        validator: (rule, value, callback) =>
          if value.length > 0 and isValidAddress(value)
            if await repeat(=> @contract.playerExists(value).call())
              callback()
            else
              callback(new Error("Нет игрока с таким адресом"))
          else
            callback(new Error("Неправильный адрес Tron"))
      ]
  }

  mounted: ->
    @parent.value = @$store.state.referrer if @$store.state.referrer
    _checkFirstPlay = =>
      # @contract = await @$store.dispatch('contracts/load', config.contracts.ReferralTree)
      isRegistered = await repeat(=> @contract.playerExists(@userAddress).call())
      new Promise (resolve, reject) =>
        @resolve = resolve; @reject = reject
        if isRegistered
          resolve([true, false])
        else
          @visible = true
  methods:
    play: ->
      @$refs.form.validate (valid) =>
        if valid
          @resolve([true, @parent.value])
          @visible = false
    cancel: ->
      @visible = false
      @resolve([false, false])


