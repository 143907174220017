



































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/Exchange.coffee"

import ActiveOrders from "./Exchange/ActiveOrders.vue"
import BuyOrders from "./Exchange/BuyOrders.vue"
import Buy from "./Exchange/Buy.vue"
import SellOrders from "./Exchange/SellOrders.vue"
import Sell from "./Exchange/Sell.vue"
import TradeHistory from "./Exchange/TradeHistory.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    ActiveOrders
    BuyOrders
    Buy
    SellOrders
    Sell
    TradeHistory
  }
  props:
    pair: String
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState('Exchange', ['loading'])
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("Exchange/getContractUserState")
    pair: -> @$store.dispatch("Exchange/load", {@pair})
  }
  mounted: ->
    @$store.dispatch("Exchange/load", {@pair})

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  beforeRouteLeave: (to, from, next) ->
    # console.info 'leave exchange'
    @$store.dispatch 'Exchange/stopUpdates'
    next()
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch 'Exchange/update', {@pair}
      else
        @$store.dispatch("Exchange/reload", {@pair})
  }
}
