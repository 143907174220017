













import {mapGetters, mapState} from 'vuex'

# renderEvent = (describeEvent) ->
#   props: {event: Object}
#   render: (h) ->
#     description = describeEvent(@event.result, @event)
#     # description = [description] unless description instanceof Array
#     d = new Date @event.timestamp
#     h 'p', [
#       h 'small', d.toLocaleString() + ':'
#       h 'br'
#       description
#     ]

renderEvent = (describeEvent) ->
  functional: true
  props: {event: Object}
  render: (h, {props: {event}}) ->
    description = describeEvent(event.result, event)
    d = new Date event.timestamp
    h 'p', [
      h 'small', d.toLocaleString() + ':'
      h 'br'
      description
    ]

export default
  components: {
    DefaultEvent: {
      functional: true
      props: {event: Object}
      render: (h, {props: {event}}) ->
        d = new Date event.timestamp
        h 'p', {style: 'color: red'},  [
          h 'small', d.toLocaleString() + ':'
          h 'br'
          "#{event.name}(#{JSON.stringify(event.result).replaceAll(/[\{\}\"]/ig,'').replaceAll(/([\:\,])/g,'$1 ')})"
        ]
    }
    InterestRateChanged: renderEvent ({from, to}) -> "Множитель выплаты изменен с #{from/100}% на #{to/100}%"
    CommissionChanged: renderEvent ({from, to}) -> "Комиссия изменена с #{from/100}% на #{to/100}%"
    OwnershipTransferred: renderEvent ({previousOwner, newOwner}) ->
      if tronWeb.BigNumber(previousOwner).isZero()
        "Владельцем контракта назначен #{tronWeb.address.fromHex(newOwner)}"
      else
        "Владелец контракта изменен с #{tronWeb.address.fromHex previousOwner} на #{tronWeb.address.fromHex(newOwner)}"
    upperEntryBoundUpdated: renderEvent ({from, to}) -> "Максимальная стоимость игры изменена с #{tronWeb.fromSun from} TRX на #{tronWeb.fromSun to} TRX"
    lowerEntryBoundUpdated: renderEvent ({from, to}) -> "Минимальная стоимость игры изменена с #{tronWeb.fromSun from} TRX на #{tronWeb.fromSun to} TRX"
    ConsolationTokenHolderChanged: renderEvent ({from, to}) -> "Адресом выплачивающем токены назначен #{tronWeb.address.fromHex to}"
    ConsolationRateChanged: renderEvent ({from, to}) -> "Коэффициент конверсии TRX в HYHO изменен с #{tronWeb.fromSun from} на #{tronWeb.fromSun to}"
    ConsolationTokenAddressChanged: renderEvent ({from, to}) ->
      if tronWeb.BigNumber(from).isZero()
        "Адресом токена установлен #{tronWeb.address.fromHex to}"
      else
        "Адрес токена изменене с #{tronWeb.address.fromHex from} на #{tronWeb.address.fromHex to}"
    SplittingUpdated: renderEvent ({from, to, level}) -> "Реферальный бонус #{level}-го уровня изменен с #{from/100}% на #{to/100}%"
    Played: renderEvent ({number, amount, player}) -> "Игрок #{tronWeb.address.fromHex player} сыграл #{tronWeb.fromSun amount} TRX (игра №#{number})"
    Awarded: renderEvent ({number, prize, player}) -> "Игрок #{tronWeb.address.fromHex player} получает приз #{tronWeb.fromSun prize} TRX за игру №#{number})"
    Collected: renderEvent ({amount}) -> "Собрана комиссия #{tronWeb.fromSun amount} TRX"
    Withdrawn: renderEvent ({number, consolation, prize, player}) ->
      "Игрок #{tronWeb.address.fromHex player} выходит из очереди, забирая #{tronWeb.fromSun prize} TRX и #{tronWeb.fromSun consolation} токенов (игра №#{number})"
  }
  computed:
    cashbackEvents: -> @$store.state.Cashback.eventsCashback.sort((a,b) -> b.timestamp - a.timestamp)
    cashbackProEvents: -> @$store.state.Cashback.eventsCashbackPro.sort((a,b) -> b.timestamp - a.timestamp)
  mounted: ->
    # console.info @$options.components
  filters:
    fromSun: (x) -> tronWeb.fromSun(x)
    addressFromHex: (x) -> tronWeb.address.fromHex(x)
