









































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/Binar.coffee"

import BinarAccruals from "./Binar/Accruals.vue"
import BinarBonuses from "./Binar/Bonuses.vue"
import BinarBranchSelector from "./Binar/BranchSelector.vue"
import BinarDetails from "./Binar/Details.vue"
import BinarGraph from "./Binar/Graph.vue"
import BinarHistory from "./Binar/History.vue"
import BinarPackage from "./Binar/Package.vue"
import BinarPurchasesUnderneath from "./Binar/PurchasesUnderneath.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    BinarAccruals
    BinarBonuses
    BinarBranchSelector
    BinarDetails
    BinarGraph
    BinarHistory
    BinarPackage
    BinarPurchasesUnderneath
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  props:
    id: String
  computed: {
    ...mapState('Binar', ['loading'])
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("TokenMarket/getContractUserState")
  }
  mounted: ->
    @$store.dispatch("Binar/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch('Binar/silentReload')
      else
        @$store.dispatch("Binar/reload")
  }
}
