





















































































































































import { mapState } from 'vuex'
import TronAddress from './TronAddress.vue'
import AddressIcon from './AddressIcon.vue'

import { VBurger } from 'vue-burger'

export default
  components: {TronAddress, AddressIcon, VBurger}
  props:
    showControl: false
  data: ->
    activeLink: "/"
    showDrawer: false
    # burgerStyle:
      # '--padding': '10px'
      # '--layer-bg-color': 'yellow'
      # '--active-layer-bg-color': '#ABABAB'
      # '--layer-width': '20px'
  beforeRouteEnter: (to, from, next) -> next((vm) => vm.activeLink = to.path)
  beforeRouteUpdate: (to, from, next) ->
    @activeLink = to.path
    next()
  computed: mapState 'TronLink',
    address: (state) -> state.account.address
  mounted: ->
    @activeLink = @$route.path
    # console.info(@$route)
