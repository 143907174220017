// import 'typeface-roboto/index.css'

import config from './config.coffee'
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.coffee'
import './element-ui.coffee'

import VueScreen from 'vue-screen';
import VueCountdownTimer from 'vuejs-countdown-timer'
import Clipboard from 'v-clipboard'
import VBurger from 'vue-burger'

import './element-variables.scss'
import './perfect-scrollbar.css'

window.config = config
window.store = store


Vue.use(VueScreen, config.breakpoints)
Vue.use(VueCountdownTimer)
Vue.use(Clipboard)
Vue.use(VBurger)

Vue.config.productionTip = false

// window.store = store
// window.contracts = config.contracts
// window.config = config

store.dispatch('initialize')

// import { DashItem } from "vue-responsive-dash";

// window.DashItem = DashItem;

// // console.info(DashItem.methods.mounted)

// let oldMounted = DashItem.mounted

// DashItem.mounted = function(){
//   this.$refs.item = document.createElement('div')
//   oldMounted.call(this)
// }


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')