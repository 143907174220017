var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding-top: 0px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Покупки партнеров")])]),_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"label":"№","align":"center","width":"52"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var number = ref.row.number;
return [_vm._v(_vm._s(number))]}}])}),_c('el-table-column',{attrs:{"label":"Адрес","align":"left","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('tron-address',{attrs:{"value":scope.row.address,"show-icon":"","show-link":"","show-copy":"","compact":""}})}}])}),_c('el-table-column',{attrs:{"label":"Стоимость","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var price = ref_row.price;
var extraTokenSymbol = ref_row.extraTokenSymbol;
return [_vm._v(_vm._s(price.base)+" "+_vm._s(_vm.baseTokenSymbol)+" + "+_vm._s(price.extra)+" "+_vm._s(extraTokenSymbol))]}}])}),_c('el-table-column',{attrs:{"label":"Личное ускорение","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var speedup = ref.row.speedup;
return [_vm._v(_vm._s((+speedup.toFixed(1)).toLocaleString('ru-RU'))+" "+_vm._s(_vm.baseTokenSymbol))]}}])})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }