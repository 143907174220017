export randomAddress = -> tronWeb.utils.accounts.generateAccount().address.base58

import faker from 'faker'
import Chance from 'chance'

chance = new Chance

export randomPackage = ->
  {currencyCode, amount} = faker.finance
  price: [
    {amount: +amount(), token: currencyCode()}
    {amount: +amount(), token: currencyCode()}
  ]
  payoutEquivalent: +amount()
  period: chance.floating(min: 10, max: 200)*24*60*60*1000
  payoutToken: currencyCode()
  bonus: +amount()

export randomBinarStructure = (size) ->
  result = {}
  for n in [1..size]
    result[n] = randomAddress()
  result

export randomHistoryItem = ->
  {currencyCode, amount} = faker.finance
  day = 24*60*60*1000
  now = +new Date
  b = now - chance.floating(min: 1, max: 50)*day
  boughtAt: b
  completedAt: b + chance.floating(min: 50, max: 70)*day
  price: [+amount(), +amount()]
  priceToken: currencyCode()
  payout: +amount()
  payoutToken: currencyCode()

export randomPurchaseUnderneathItem = ->
  {currencyCode, amount} = faker.finance
  day = 24*60*60*1000
  now = +new Date
  b = now - chance.floating(min: 1, max: 100)*day
  boughtAt: b
  price: [+amount(), +amount()]
  priceToken: currencyCode()
  address: randomAddress()
  branch: `Math.random() > 0.5 ? 'left' : 'right'`
  bonus: +amount()