


















































import TokenIcon from '../../components/TokenIcon.vue'
import {mapGetters, mapState} from 'vuex'
import {
  verboseSend
  tokenSymbol as address2symbol
  } from '../../store/functions.coffee'
import config from '../../config.coffee'

export default
  components: {TokenIcon}
  data: ->
    loading:
      reconfigure: false
      collect: false
    newCfg:
      basePrice: null
      extraPrice: null
      earlyPayout: null
      regularPayout: null
      earlyReceiptPeriod: null
      freezePeriod: null
      referralShare: null
      extraToken: null
      payoutToken: null
  computed: {
    ...mapState 'TokenMarket', ['contract']
    ...mapGetters 'TokenMarket', ['tokenSymbols', 'commission', 'oldCfg']
  }
  methods: {
    reconfigure: ->
      @loading.reconfigure = true
      verboseSend @contract.reconfigurePackages(
        tronWeb.toSun(@newCfg.basePrice || 0),
        tronWeb.toSun(@newCfg.extraPrice || 0),
        tronWeb.toSun(@newCfg.earlyPayout || 0),
        tronWeb.toSun(@newCfg.regularPayout || 0),
        Math.round((@newCfg.earlyReceiptPeriod || 0) * 24 * 3600),
        Math.round((@newCfg.freezePeriod || 0) * 24 * 3600),
        Math.round(@newCfg.referralShare || 0),
        @newCfg.extraToken || '410000000000000000000000000000000000000000',
        @newCfg.payoutToken || '410000000000000000000000000000000000000000'
      ), feeLimit: 200000000
      .then (msg) =>
        @$store.dispatch('TokenMarket/silentReload')
      .finally =>
        @loading.reconfigure = false
    collect: ->
      @loading.collect = true
      verboseSend @contract.getCommission()
      .then (msg) =>
        # @$emit 'reload', silent: true
        # @$store.dispatch('KingOfTheHill/reload')
      .finally =>
        @loading.collect = false
    address2symbol: (addr) -> address2symbol[addr] or '???'
  }
