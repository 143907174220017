export default [{
	breakpoint: 'MobilS'
	breakpointWidth: 1
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 6, height: 10, id: 1, component: 'Rewards'}
		{ x: 0, y: 10, width: 6, height: 10, id: 2, component: 'GamesPlayed'}
		{ x: 0, y: 20, width: 6, height: 10, id: 3, component: 'Players'}
		{ x: 0, y: 56, width: 6, height: 10, id: 4, component: 'YourReward'}
		{ x: 0, y: 66, width: 6, height: 10, id: 5, component: 'YourGames'}
		{ x: 0, y: 76, width: 6, height: 10, id: 6, component: 'Partners'}
		{ x: 0, y: 45, width: 6, height: 11, id: 7, component: 'NextRewardProgress'}
		{ x: 0, y: 30, width: 6, height: 15, id: 8, component: 'Play'}
		{ x: 0, y: 86, width: 6, height: 11, id: 9, component: 'YourRewardProgress'}
		{ x: 0, y: 97, width: 6, height: 35, id: 10, component: 'MainQueue'}
		{ x: 0, y: 165, width: 6, height: 37, id: 11, component: 'PersonalQueue'}
		{ x: 0, y: 132, width: 6, height: 33, id: 12, component: 'CompletedGames'}
		{ x: 0, y: 202, width: 6, height: 33, id: 13, component: 'PartnerGames'}
	]
}
{
	breakpoint: 'MobilM'
	breakpointWidth: 374
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 3, height: 10, id: 1, component: 'Rewards'}
		{ x: 0, y: 10, width: 3, height: 10, id: 2, component: 'GamesPlayed'}
		{ x: 0, y: 20, width: 3, height: 10, id: 3, component: 'Players'}
		{ x: 3, y: 0, width: 3, height: 10, id: 4, component: 'YourReward'}
		{ x: 3, y: 10, width: 3, height: 10, id: 5, component: 'YourGames'}
		{ x: 3, y: 20, width: 3, height: 10, id: 6, component: 'Partners'}
		{ x: 0, y: 44, width: 6, height: 11, id: 7, component: 'NextRewardProgress'}
		{ x: 0, y: 30, width: 6, height: 14, id: 8, component: 'Play'}
		{ x: 0, y: 55, width: 6, height: 11, id: 9, component: 'YourRewardProgress'}
		{ x: 0, y: 66, width: 6, height: 32, id: 10, component: 'MainQueue'}
		{ x: 0, y: 130, width: 6, height: 37, id: 11, component: 'PersonalQueue'}
		{ x: 0, y: 98, width: 6, height: 32, id: 12, component: 'CompletedGames'}
		{ x: 0, y: 167, width: 6, height: 33, id: 13, component: 'PartnerGames'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 3, height: 10, id: 1, component: 'Rewards'}
		{ x: 0, y: 10, width: 3, height: 10, id: 2, component: 'GamesPlayed'}
		{ x: 0, y: 20, width: 3, height: 10, id: 3, component: 'Players'}
		{ x: 3, y: 0, width: 3, height: 10, id: 4, component: 'YourReward'}
		{ x: 3, y: 10, width: 3, height: 10, id: 5, component: 'YourGames'}
		{ x: 3, y: 20, width: 3, height: 10, id: 6, component: 'Partners'}
		{ x: 0, y: 44, width: 6, height: 11, id: 7, component: 'NextRewardProgress'}
		{ x: 0, y: 30, width: 6, height: 14, id: 8, component: 'Play'}
		{ x: 0, y: 55, width: 6, height: 11, id: 9, component: 'YourRewardProgress'}
		{ x: 0, y: 66, width: 6, height: 32, id: 10, component: 'MainQueue'}
		{ x: 0, y: 130, width: 6, height: 37, id: 11, component: 'PersonalQueue'}
		{ x: 0, y: 98, width: 6, height: 32, id: 12, component: 'CompletedGames'}
		{ x: 0, y: 167, width: 6, height: 32, id: 13, component: 'PartnerGames'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 605
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 2, height: 10, id: 1, component: 'Rewards'}
		{ x: 2, y: 0, width: 2, height: 10, id: 2, component: 'GamesPlayed'}
		{ x: 4, y: 0, width: 2, height: 10, id: 3, component: 'Players'}
		{ x: 0, y: 10, width: 2, height: 10, id: 4, component: 'YourReward'}
		{ x: 2, y: 10, width: 2, height: 10, id: 5, component: 'YourGames'}
		{ x: 4, y: 10, width: 2, height: 10, id: 6, component: 'Partners'}
		{ x: 0, y: 34, width: 3, height: 11, id: 7, component: 'NextRewardProgress'}
		{ x: 0, y: 20, width: 6, height: 14, id: 8, component: 'Play'}
		{ x: 3, y: 34, width: 3, height: 11, id: 9, component: 'YourRewardProgress'}
		{ x: 0, y: 45, width: 6, height: 32, id: 10, component: 'MainQueue'}
		{ x: 0, y: 109, width: 6, height: 32, id: 11, component: 'PersonalQueue'}
		{ x: 0, y: 77, width: 6, height: 32, id: 12, component: 'CompletedGames'}
		{ x: 0, y: 141, width: 6, height: 32, id: 13, component: 'PartnerGames'}
	]
}
{
	breakpoint: 'L'
	breakpointWidth: 1034
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 1, height: 10, id: 1, component: 'Rewards'}
		{ x: 1, y: 0, width: 1, height: 10, id: 2, component: 'GamesPlayed'}
		{ x: 2, y: 0, width: 1, height: 10, id: 3, component: 'Players'}
		{ x: 3, y: 0, width: 1, height: 10, id: 4, component: 'YourReward'}
		{ x: 4, y: 0, width: 1, height: 10, id: 5, component: 'YourGames'}
		{ x: 5, y: 0, width: 1, height: 10, id: 6, component: 'Partners'}
		{ x: 0, y: 10, width: 2, height: 13, id: 7, component: 'NextRewardProgress'}
		{ x: 2, y: 10, width: 2, height: 13, id: 8, component: 'Play'}
		{ x: 4, y: 10, width: 2, height: 13, id: 9, component: 'YourRewardProgress'}
		{ x: 0, y: 23, width: 3, height: 32, id: 10, component: 'MainQueue'}
		{ x: 3, y: 23, width: 3, height: 32, id: 11, component: 'PersonalQueue'}
		{ x: 0, y: 55, width: 3, height: 32, id: 12, component: 'CompletedGames'}
		{ x: 3, y: 55, width: 3, height: 32, id: 13, component: 'PartnerGames'}
	]
}
]

# export default [{
# 	breakpoint: 'minimal'
# 	numberOfCols: 6
# 	rowHeight: 2
# 	items: [
# 		{ x: 0, y: 0, width: 1, height: 10, id: 1, component: 'Rewards'}
# 		{ x: 1, y: 0, width: 1, height: 10, id: 2, component: 'GamesPlayed'}
# 		{ x: 2, y: 0, width: 1, height: 10, id: 3, component: 'Players'}
# 		{ x: 3, y: 0, width: 1, height: 10, id: 4, component: 'YourReward'}
# 		{ x: 4, y: 0, width: 1, height: 10, id: 5, component: 'YourGames'}
# 		{ x: 5, y: 0, width: 1, height: 10, id: 6, component: 'Partners'}
# 		{ x: 0, y: 10, width: 2, height: 11, id: 7, component: 'NextRewardProgress'}
# 		{ x: 2, y: 10, width: 2, height: 13, id: 8, component: 'Play'}
# 		{ x: 4, y: 10, width: 2, height: 11, id: 9, component: 'YourRewardProgress'}
# 		{ x: 0, y: 23, width: 3, height: 32, id: 10, component: 'MainQueue'}
# 		{ x: 3, y: 23, width: 3, height: 32, id: 11, component: 'PersonalQueue'}
# 		{ x: 0, y: 55, width: 3, height: 32, id: 12, component: 'CompletedGames'}
# 		{ x: 3, y: 55, width: 3, height: 32, id: 13, component: 'PartnerGames'}
# 	]
# }
# ]

# export default [{
#   breakpoint: 'xl'
#   numberOfCols: 6
#   rowHeight: 5
#   items: [
#     { x: 0, y:  0, width: 1, height:  6, id:  1, component: 'Rewards' }
#     { x: 1, y:  0, width: 1, height:  6, id:  2, component: 'GamesPlayed' }
#     { x: 2, y:  0, width: 1, height:  6, id:  3, component: 'Players' }
#     { x: 3, y:  0, width: 1, height:  6, id:  4, component: 'YourReward' }
#     { x: 4, y:  0, width: 1, height:  6, id:  5, component: 'YourGames' }
#     { x: 5, y:  0, width: 1, height:  6, id:  6, component: 'Partners' }
#     { x: 0, y:  6, width: 2, height:  7, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y:  6, width: 2, height:  7, id:  8, component: 'Play' }
#     { x: 4, y:  6, width: 2, height:  7, id:  9, component: 'YourRewardProgress' }
#     { x: 0, y: 12, width: 3, height: 20, id: 10, component: 'MainQueue' }
#     { x: 3, y: 12, width: 3, height: 20, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 33, width: 3, height: 20, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 33, width: 3, height: 20, id: 13, component: 'PartnerGames' }
#   ]
# }]
# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]

