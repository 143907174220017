












import { mapGetters } from 'vuex'
import icon from '../../components/icons/cup.vue'

export default
  components: {icon}
  computed:
    mapGetters 'KingOfTheHill', ['bank']
    # bank: -> 10000
