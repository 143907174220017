import { render, staticRenderFns } from "./TokensGained.vue?vue&type=template&id=def6daca&scoped=true&lang=pug&"
import script from "./TokensGained.vue?vue&type=script&lang=coffee&"
export * from "./TokensGained.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "def6daca",
  null
  
)

export default component.exports