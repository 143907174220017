












import { mapGetters } from 'vuex'
import icon from '../../components/icons/level3.vue'

export default
  components: {icon}
  computed:
    mapGetters 'Cabinet', ['partners']
