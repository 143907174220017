# Exchange

import config from '../config.coffee'
import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'

import BigNumber from 'bignumber.js'

import {address2hex, Multicall} from '../multicall.coffee'

export default
  namespaced: true
  state: =>
    currentPair: null
    initialized: false
    loading: false
    loadingUpdates: false
    stopUpdates: true
    contract: null
    events: []
    numberSettings: null
    rangeSettings: null
    baseCurrencyInfo: null
    quoteCurrencyInfo: null
    checksum: null
    timeout: null
    settings:
      priceBase: BigNumber(1)
    limitBuyRanges:
      minPrice: BigNumber(1)
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'currentPair'
      'loading'
      'contract'
      'events'
      'numberSettings'
      'rangeSettings'
      'baseCurrencyInfo'
      'quoteCurrencyInfo'
      'checksum'
      'timeout'
      'loadingUpdates'
      'stopUpdates'
    ]
    parameters: (state, params) -> state.swap.parameters = params
  }
  actions:
    load: ({ state, dispatch }, {pair}) -> dispatch (if state.initialized and state.currentPair == pair then 'reload' else 'initialize'), {pair}
    initialize: ({state, commit, dispatch}, {pair}) ->
      return if state.loading
      console.info 'loading start'
      commit('loading', true) if state.currentPair != pair
      commit('currentPair', pair)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('contracts/load', config.contracts.OrderBook[state.currentPair], root: true).then (c) ->
          commit 'contract', c
          dispatch('getContractState')
      ]
      console.info 'loading end'
      commit('loading', false)
      commit('initialization')
      dispatch 'startUpdates'
    reload: ({ commit, dispatch, state }, {pair}) ->
      return if state.loading
      commit('loading', true) if state.currentPair != pair
      commit('currentPair', pair)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    update: ({state, commit, dispatch}) ->
      return if state.loadingUpdates
      commit 'loadingUpdates', true
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)
      await Promise.all [
        dispatch('getContractEvents')
        Promise.all [
          repeat(-> contract.rangeSettings().call()).then commitResult 'rangeSettings'
          repeat(-> contract.checksum().call()).then commitResult 'checksum'
        ]
      ]
      commit 'loadingUpdates', false
    getContractEvents: ({commit, state}) ->
      # repeat(-> getAllContractEvents(config.contracts.OrderBook[state.currentPair])).then (e) -> commit 'events', e
      getAllContractEvents(config.contracts.OrderBook[state.currentPair]).then (e) -> commit 'events', e
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      # dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        numberSettings: contract.methodInstances.numberSettings
        rangeSettings: contract.methodInstances.rangeSettings
        baseCurrencyInfo: contract.methodInstances.baseCurrencyInfo
        quoteCurrencyInfo: contract.methodInstances.quoteCurrencyInfo
        checksum: contract.methodInstances.checksum
      }

      contractHexAddress = contract.address.replace(/^41/,'0x')

      for name, method of methods
        methods[name] = {
          contract: contractHexAddress
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          # parameters: []
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # return
      # Promise.all [
      #   repeat(-> contract.numberSettings().call()).then commitResult 'numberSettings'
      #   repeat(-> contract.rangeSettings().call()).then commitResult 'rangeSettings'
      #   repeat(-> contract.baseCurrencyInfo().call()).then commitResult 'baseCurrencyInfo'
      #   repeat(-> contract.quoteCurrencyInfo().call()).then commitResult 'quoteCurrencyInfo'
      #   repeat(-> contract.checksum().call()).then commitResult 'checksum'
      # ]
    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      # state.contract.ordersOf(userAddress).call().then ({list}) -> commit 'orders', list
    startUpdates: ({state, commit, dispatch}) ->
      # console.info 'start checking updates'
      commit 'stopUpdates', false
      dispatch('checkUpdates') if state.timeout == null
    checkUpdates: ({state, commit, dispatch}) ->
      # console.info 'check updates', state.stopUpdates
      return if state.stopUpdates
      commit 'timeout', setTimeout (->
        checksum = await repeat(-> state.contract.checksum().call())
        # console.info checksum
        await dispatch 'update' if checksum != state.checksum
        await dispatch 'checkUpdates'
        return
      ), 3000
    stopUpdates: ({state, commit}) ->
      clearTimeout state.timeout
      commit 'timeout', null
      commit 'stopUpdates', true
  getters:
    userAddress: -> arguments[2].TronLink.account.address
    currentPair: ({currentPair}) -> currentPair.split('/')
    bids: (_, {orderBook: {buy: list}, priceDivisor, currency}) ->
      prices = {}
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for id, {asset, value, timestamp} of list
        price = asset.div(currency.quote.divisor).div(value.div(currency.base.divisor))
        price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
        prices[price] ||= {volume: BigNumber(0), total: BigNumber(0), orders: []}
        prices[price].total = prices[price].total.plus(asset)
        prices[price].volume = prices[price].volume.plus(value)
        prices[price].orders.push {id, asset, value, timestamp}
      for price, {total, volume, orders} of prices
        total = total.div(currency.quote.divisor).toNumber()
        volume = volume.div(currency.base.divisor).toNumber()
        result.push {price, total, volume, orders}
      for row in result
        row.orders.sort (a,b) -> a.timestamp - b.timestamp
      result.sort (a,b) -> b.price - a.price
    asks: (_, {orderBook: {sell: list}, priceDivisor, currency}) ->
      prices = {}
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for id, {asset, value, timestamp} of list
        price = value.div(currency.base.divisor).div(asset.div(currency.quote.divisor))
        price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
        prices[price] ||= {volume: BigNumber(0), total: BigNumber(0), orders: []}
        prices[price].total = prices[price].total.plus(value)
        prices[price].volume = prices[price].volume.plus(asset)
        prices[price].orders.push {id, asset, value, timestamp}
      for price, {total, volume, orders} of prices
        total = total.div(currency.quote.divisor).toNumber()
        volume = volume.div(currency.base.divisor).toNumber()
        result.push {price, total, volume, orders}
      for row in result
        row.orders.sort (a,b) -> a.timestamp - b.timestamp
      result.sort (a,b) -> a.price - b.price
    minAsk: (_, {asks, priceDivisor}) -> BigNumber(asks[0]?.price or 0).times(priceDivisor)
    maxBid: (_, {bids, priceDivisor}) -> BigNumber(bids[0]?.price or 0).times(priceDivisor)
    orders: (_, {orderBook, userAddress, priceDivisor, currency}) ->
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      [
        ...Object.values(orderBook.buy).filter(({trader}) -> trader == userAddress).map ({asset: total, value: volume, id}) ->
          price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
          price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
          volume = tronWeb.fromSun(volume).toString()
          total = tronWeb.fromSun(total).toString()
          {id, type: 'buy', volume, price, total}
        ...Object.values(orderBook.sell).filter(({trader}) -> trader == userAddress).map ({asset: volume, value: total, id}) ->
          price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
          price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
          volume = tronWeb.fromSun(volume).toString()
          total = tronWeb.fromSun(total).toString()
          {id, type: 'sell', volume, price, total}
      ].sort (a,b) -> b.id - a.id
    trades: ({events},{priceDivisor, currency}) ->
      result = []
      decimals = Math.ceil(Math.log10(priceDivisor.toNumber()))
      for {name, result: {asset, seller, buyer, value}, timestamp} in events
        switch name
          when "MarketBuy"
            total = BigNumber(asset)
            volume = BigNumber(value)
            price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
            price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
            total = tronWeb.fromSun asset
            volume = tronWeb.fromSun value
            type = "buy"
            trader = tronWeb.address.fromHex buyer
            result.push {timestamp, type, total, volume, price, trader}
          when "MarketSell"
            total = BigNumber(value)
            volume = BigNumber(asset)
            price = total.div(currency.quote.divisor).div(volume.div(currency.base.divisor))
            price = price.times(priceDivisor).integerValue().div(priceDivisor).toNumber().toFixed(decimals)
            total = tronWeb.fromSun value
            volume = tronWeb.fromSun asset
            type = "sell"
            trader = tronWeb.address.fromHex seller
            result.push {timestamp, type, total, volume, price, trader}
      result.sort (a,b) -> b.timestamp - a.timestamp
    myTrades: (_, {trades, userAddress}) -> trades.filter ({trader}) -> trader == userAddress
    orderBook: ({events}) ->
      orders = {buy: {}, sell: {}}
      for {name, result, timestamp} in events
        switch name
          when "LimitBuy"
            {asset, value, trader, id} = result
            asset = BigNumber(asset)
            value = BigNumber(value)
            trader = tronWeb.address.fromHex trader
            orders.buy[result.id] = {id, asset, value, trader}
            orders.buy[result.id].timestamp ||= timestamp
          when "LimitSell"
            {asset, value, trader, id} = result
            asset = BigNumber(asset)
            value = BigNumber(value)
            trader = tronWeb.address.fromHex trader
            orders.sell[result.id] = {id, asset, value, trader}
            orders.sell[result.id].timestamp ||= timestamp
          when "Cancel"
            delete orders.buy[result.id]
            delete orders.sell[result.id]
          when "Close"
            delete orders.buy[result.id]
            delete orders.sell[result.id]
      orders
    buyIds: (_, {bids}) ->
      result = []
      for {orders} in bids
        for {id} in orders
          result.push id
      result
    sellIds: (_, {asks}) ->
      result = []
      for {orders} in asks
        for {id} in orders
          result.push id
      result
    priceDivisor: ({numberSettings: {priceDivisor}}) -> BigNumber(priceDivisor.toString())
    currency: ({numberSettings, baseCurrencyInfo, quoteCurrencyInfo}) ->
      base:
        unit: BigNumber(numberSettings.baseCurrencyUnit.toString())
        divisor: BigNumber(numberSettings.baseDivisor.toString())
        symbol: baseCurrencyInfo.symbol
        name: baseCurrencyInfo.name
        address: tronWeb.address.fromHex(baseCurrencyInfo.tokenAddress)
      quote:
        unit: BigNumber(numberSettings.quoteCurrencyUnit.toString())
        divisor: BigNumber(numberSettings.quoteDivisor.toString())
        symbol: quoteCurrencyInfo.symbol
        name: quoteCurrencyInfo.name
        address: tronWeb.address.fromHex(quoteCurrencyInfo.tokenAddress)
    sellPrice: ({rangeSettings}) ->
      min: BigNumber(rangeSettings._minSellPrice.toString())
      max: BigNumber(rangeSettings._maxSellPrice.toString())
    buyPrice: ({rangeSettings}) ->
      min: BigNumber(rangeSettings._minBuyPrice.toString())
      max: BigNumber(rangeSettings._maxBuyPrice.toString())
    maxBaseAsset: ({rangeSettings}) -> BigNumber(rangeSettings._maxBaseAsset.toString())
    maxBaseValue: ({rangeSettings}) -> BigNumber(rangeSettings._maxBaseValue.toString())
    minBaseAsset: ({rangeSettings}) -> BigNumber(rangeSettings._minBaseAsset.toString())
    minBaseValue: ({rangeSettings}) -> BigNumber(rangeSettings._minBaseValue.toString())
    maxQuoteAsset: ({rangeSettings}) -> BigNumber(rangeSettings._maxQuoteAsset.toString())
    maxQuoteValue: ({rangeSettings}) -> BigNumber(rangeSettings._maxQuoteValue.toString())
    minQuoteAsset: ({rangeSettings}) -> BigNumber(rangeSettings._minQuoteAsset.toString())
    minQuoteValue: ({rangeSettings}) -> BigNumber(rangeSettings._minQuoteValue.toString())





