# Staking

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'

import {address2hex, Multicall} from '../multicall.coffee'

import config from '../config.coffee'

STAKING_TYPE =
  0: "Light"
  1: "Basic"
  2: "Pro"

humanizeParams = ({parameters, speedups}) ->
  days = parameters.period.toNumber()/(24*3600)
  price:
    min: tronWeb.fromSun(parameters.minPrice)
    max: tronWeb.fromSun(parameters.maxPrice)
  dailyROI: (parameters.yield.toNumber()/days).toFixed(1)
  days: days
  speedups: speedups.map (e) -> e.toNumber()*100/parameters.percentBase.toNumber()

export default
  namespaced: true
  state: =>
    currentToken: null
    initialized: false
    loading: false
    IN1_tokenInfo: null
    IN2_tokenInfo: null
    OUT_tokenInfo: null
    contracts:
      staking: []
      swap: null
    events:
      staking: []
      swap: null
    swap:
      parameters: {}
    staking: []
    available: []
    profit:
      Light: 0
      Basic: 0
      Pro: 0
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'currentToken'
      'loading'
      'contracts'
      'events'
      'staking'
      'available'
      'profit'
      'IN1_tokenInfo'
      'IN2_tokenInfo'
      'OUT_tokenInfo'
    ]
    parameters: (state, params) -> state.swap.parameters = params
  }
  actions:
    load: ({ state, dispatch }, {token}) -> dispatch (if state.initialized and state.currentToken == token then 'reload' else 'initialize'), {token}
    initialize: ({state, commit, dispatch}, {token}) ->
      return if state.loading
      console.info 'loading start'
      commit('loading', true) if state.currentToken != token
      commit('currentToken', token)
      await Promise.all [
        dispatch('getContractEvents')
        Promise.all([
          dispatch('contracts/load', config.contracts.ComboSwap[state.currentToken], root: true)
          Promise.all config.contracts.AcceleratedStaking[state.currentToken].map (address) -> dispatch('contracts/load', address, root: true)
        ]).then ([swap, staking]) ->
          commit 'contracts', {swap, staking}
          dispatch('getContractState')
      ]
      console.info 'loading end'
      commit('loading', false)
      commit('initialization')
      dispatch('updateProfit')
    reload: ({ commit, dispatch, state }, {token}) ->
      return if state.loading
      commit('loading', true) if state.currentToken != token
      commit('currentToken', token)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}, {token}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    getContractEvents: ({commit, state}) ->
      Promise.all([
        # repeat(-> getAllContractEvents(config.contracts.ComboSwap[state.currentToken]))
        getAllContractEvents(config.contracts.ComboSwap[state.currentToken])
        # Promise.all config.contracts.AcceleratedStaking[state.currentToken].map (address) -> repeat(-> getAllContractEvents(address))
        Promise.all config.contracts.AcceleratedStaking[state.currentToken].map getAllContractEvents
      ]).then ([swap, staking]) ->
        commit 'events', {swap, staking}
      .catch(console.error)
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      {swap, staking} = state.contracts
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = [
        swap.methodInstances.getParameters # parameters
        swap.methodInstances.IN1_tokenInfo # IN1_tokenInfo
        swap.methodInstances.IN2_tokenInfo # IN2_tokenInfo
        swap.methodInstances.OUT_tokenInfo # OUT_tokenInfo
        staking[0].methodInstances.getParameters # s0 getParameters
        staking[0].methodInstances.referralSpeedups # s0 referralSpeedups
        staking[1].methodInstances.getParameters # s1 getParameters
        staking[1].methodInstances.referralSpeedups # s1 referralSpeedups
        staking[2].methodInstances.getParameters # s2 getParameters
        staking[2].methodInstances.referralSpeedups # s2 referralSpeedups
      ]

      # contractHexAddress = contract.address.replace(/^41/,'0x')

      calls = for method in methods
        contract: method.contract.address.replace(/^41/,'0x')
        signature: '0x'+method.signature
        # functionSelector: method.functionSelector
        inputs: method.inputs
        outputs: method.outputs
        # parameters: []
      # console.info calls

      results = await repeat(=> @multicall(calls))

      commit 'parameters', results[0]
      commit 'IN1_tokenInfo', results[1]
      commit 'IN2_tokenInfo', results[2]
      commit 'OUT_tokenInfo', results[3]

      # for i, value of results
      #   commit names[i], value

      commit 'staking', [
        { parameters: results[4], speedups: results[5] }
        { parameters: results[6], speedups: results[7] }
        { parameters: results[8], speedups: results[9] }
      ]

      # return
      # Promise.all [
      #   repeat(-> swap.getParameters().call()).then(commitResult 'parameters')
      #   repeat(-> swap.IN1_tokenInfo().call()).then(commitResult 'IN1_tokenInfo')
      #   repeat(-> swap.IN2_tokenInfo().call()).then(commitResult 'IN2_tokenInfo')
      #   repeat(-> swap.OUT_tokenInfo().call()).then(commitResult 'OUT_tokenInfo')
      #   Promise.all staking.map (contract) ->
      #     Promise.all([
      #       repeat(-> contract.getParameters().call())
      #       repeat(-> contract.referralSpeedups().call())
      #     ]).then ([parameters, speedups]) -> {parameters, speedups}
      #   .then commitResult 'staking'
      # ]

    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = state.contracts.staking.map (s) -> s.methodInstances.availableFor

      userHexAddress = address2hex userAddress

      console.info userHexAddress

      calls = for method in methods
        contract: method.contract.address.replace(/^41/,'0x')
        signature: '0x'+method.signature
        # functionSelector: method.functionSelector
        inputs: method.inputs
        outputs: method.outputs
        parameters: [userHexAddress]

      results = await repeat(=> @multicall(calls))

      commit 'available', results

      # return
      # Promise.all state.contracts.staking.map (contract) ->
      #   repeat(-> contract.availableFor(userAddress).call()).then ({available}) -> available
      # .then commitResult 'available'
    updateProfit: ({state, getters, dispatch, commit}) ->
      profit = {Light: 0, Basic: 0, Pro: 0}
      for {timer: {end, start}, amount, type, completed} in getters.myStakes
        now = +new Date
        if completed or now > end
          profit[type] += amount.final
        else
          if now > start
            profit[type] += amount.mined + amount.leftToMine * (now - start) / (end - start)
      for taken, i in getters.taken
        profit[STAKING_TYPE[i]] -= taken
      commit 'profit', profit
      setTimeout (-> dispatch('updateProfit')), 500

  getters:
    userAddress: -> arguments[2].TronLink.account.address
    currentToken: ({currentToken}) -> currentToken
    staking: (store) ->
      light: humanizeParams store.staking[0]
      basic: humanizeParams store.staking[1]
      pro: humanizeParams store.staking[2]
    profit: (state) ->
      total: Math.round(state.profit.Light + state.profit.Basic + state.profit.Pro).toLocaleString('ru-RU')
      light: Math.round(state.profit.Light).toLocaleString('ru-RU')
      basic: Math.round(state.profit.Basic).toLocaleString('ru-RU')
      pro: Math.round(state.profit.Pro).toLocaleString('ru-RU')
    mined: -> 50000
    pushed: (state, {myStakes}) -> myStakes.map((e) -> e.amount.base).reduce ((a,b) -> a+b), 0
    swapTokens: (state) ->
      in1: state.IN1_tokenInfo.symbol
      in2: state.IN2_tokenInfo.symbol
      out: state.OUT_tokenInfo.symbol
    mySwaps: (state, {userAddress}) ->
      state.events.swap.filter(({result}) -> tronWeb.address.fromHex(result.user) == userAddress).map ({timestamp, transaction, result: {in1, in2, out}}) ->
        tx: transaction
        timestamp: timestamp
        in1: +tronWeb.fromSun in1
        in2: +tronWeb.fromSun in2
        out: +tronWeb.fromSun out
      .sort (a,b) -> b.timestamp - a.timestamp
    taken: (state, {userAddress}) ->
      result = [0, 0, 0]
      for events, k in state.events.staking
        for event in events
          if event.name == "Taken" and tronWeb.address.fromHex(event.result.user) == userAddress
            result[k] += +tronWeb.fromSun event.result.amount
      return result
    allStakes: (state) ->
      stakes = {}
      for events, k in state.events.staking
        for event in events
          switch event.name
            # event StakeCreated(address user, uint number, uint start, uint end, uint baseAmount, uint finalAmount);
            when "StakeCreated"
              {user, number, start, end, baseAmount, finalAmount} = event.result
              timestamp = event.timestamp
              number = +number
              timer = {start: 1000*(+start), end: 1000*(+end) }
              amount =
                base: +tronWeb.fromSun baseAmount
                final: +tronWeb.fromSun finalAmount
                mined: 0
                leftToMine: +tronWeb.fromSun finalAmount
              completed = false
              user = tronWeb.address.fromHex user
              stake = {timestamp,number,timer,amount,completed,user,type: STAKING_TYPE[k]}
              stakes[user] ||= [[],[],[]]
              stakes[user][k][number - 1] = stake
            # event StakeUpdated(address user, uint number, uint start, uint end, uint leftToMine, uint mined);
            when "StakeUpdated"
              {user, number, start, end, leftToMine, mined} = event.result
              user = tronWeb.address.fromHex user
              i = number - 1
              stakes[user][k][i].timer.start = 1000*(+start)
              stakes[user][k][i].timer.end = 1000*(+end)
              stakes[user][k][i].amount.mined += +tronWeb.fromSun mined
              stakes[user][k][i].amount.leftToMine = +tronWeb.fromSun leftToMine

            # event StakeCompleted(address user, uint number);
            when "StakeCompleted"
              {user, number} = event.result
              user = tronWeb.address.fromHex user
              i = +number - 1
              stakes[user][k][i].completed = true
      return stakes
    myStakes: (state, {userAddress, allStakes}) -> (allStakes[userAddress] || []).flat()
    partnerStakes: (state, {userAddress, allStakes}, rootState, rootGetters) ->
      result = []
      for address, level of rootGetters['Cabinet/addressToLevel']
        stakes = (allStakes[address] || []).flat()
        if stakes and stakes.length > 0
          for stake in stakes
            result.push({
              level
              amount: stake.amount.base
              address
              timestamp: stake.timestamp
            })
      return result

