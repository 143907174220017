var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding: 0; padding-top: 10px; height: calc(100% - 50px)"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Активные ордера")]),_c('el-table',{attrs:{"data":_vm.orders,"size":"mini"}},[_c('el-table-column',{attrs:{"label":"Цена","prop":"price","align":"right"}}),_c('el-table-column',{attrs:{"label":_vm.currentPair[0],"prop":"volume","align":"right"}}),_c('el-table-column',{attrs:{"label":_vm.currentPair[1],"prop":"total","align":"right"}}),_c('el-table-column',{attrs:{"label":"Тип","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 'sell')?[_vm._v("Продажа")]:[_vm._v("Покупка")]]}}])}),_c('el-table-column',{attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var id = ref_row.id;
var price = ref_row.price;
var type = ref_row.type;
return [_c('el-button',{staticClass:"reposition",attrs:{"size":"mini"},on:{"click":function($event){return _vm.cancel(id, type)}}},[_vm._v("Отменить")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }