




























import TronAddress from '../../components/TronAddress.vue'
import AddressIcon from '../../components/AddressIcon.vue'

import { mapGetters } from 'vuex'

export default
  components: {TronAddress, AddressIcon}
  data: ->
    currentPage: 1
    pageSize: 5
  computed: {
    ...mapGetters 'Staking', {items: 'partnerStakes'}
    ...mapGetters 'Staking', ['currentToken']
    pageData: ->
      @items.slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
