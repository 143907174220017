



































import config from '../config.coffee'
import TokenIcon from './TokenIcon.vue'
import BigNumber from 'bignumber.js'
import {mapState, mapGetters, mapActions} from 'vuex'
import {repeat} from '../store/functions.coffee'

_checkAllowance = null

export checkAllowance = -> _checkAllowance(...arguments)

export default
  components: {TokenIcon}
  data: ->
    waiting: false
    allowance: null
    minValue: null
    title: "Требуется разрешение на списание токенов"
    visible: false
    contractName: ""
    tokenSymbol: "HYHO"
    description: ""
  computed: {
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
    rules: ->
      allowance: [
        trigger: 'change',
        validator: (rule, value, callback) =>
          if BigNumber(tronWeb.toSun value).lt BigNumber(tronWeb.toSun @minValue)
            callback(new Error("Необходимо разрешить как минимум #{@minValue} #{@tokenSymbol}"))
          else
            callback()
      ]
  }
  mounted: ->
    _checkAllowance = (value, symbol, contractAddress, description) =>
      @tokenContract = await @$store.dispatch('contracts/load', config.contracts.TRC20[symbol])
      allowance = await repeat(=> @tokenContract.allowance(@userAddress, contractAddress).call())
      allowance = if allowance.remaining
        BigNumber(allowance.remaining.toString())
      else
        BigNumber(allowance.toString())
      # console.info "allowance", allowance.toString()
      # console.info(tronWeb.fromSun(allowance.toString()), symbol)
      @allowance = +tronWeb.fromSun(value.toString())
      @minValue = +tronWeb.fromSun(value.toString())
      @contractName = name; @tokenSymbol = symbol
      @contractAddress = contractAddress; @description = description
      # return promise
      new Promise (resolve, reject) =>
        @resolve = resolve; @reject = reject
        # console.info(value.toString())
        if allowance.gte value
          resolve(true)
        else
          @visible = true
  methods:
    allow: (event) ->
      # event.preventDefault() if event
      @waiting = true
      @$refs.form.validate (valid) =>
        if valid
          @tokenContract.approve(@contractAddress, tronWeb.toSun(@allowance)).send().then => @resolve(true)
          .catch (e) =>
            console.error(e)
            @resolve(false)
          .finally =>
            @waiting = false
            @visible = false
        @waiting = false
    cancel: ->
      @visible = false
      @resolve(false)


