











import { mapGetters } from 'vuex'
import icon from '../../components/icons/flag.vue'

export default
  components: {icon}
  computed: mapGetters 'Tron', ['games']
