













import Summary from './Exchange/Summary.vue'
import Actions from './Exchange/Actions.vue'
import Events from './Exchange/Events.vue'
import Ownership from './Exchange/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
