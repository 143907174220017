var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(false),expression:"false"}],staticClass:"full-height",attrs:{"body-style":"padding-top: 5px","element-loading-text":"Отправка"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Покупка "+_vm._s(_vm.currentPair[0]))])]),_c('el-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('el-tab-pane',{attrs:{"label":"Лимитный","name":"limit"}},[_c('el-form',{ref:"limitBuy",attrs:{"model":this,"rules":_vm.rules,"status-icon":""}},[_c('el-form-item',{attrs:{"label":"Покупаете:","prop":"limitVolume"},scopedSlots:_vm._u([{key:"error",fn:function(ref){
var error = ref.error;
return [_c('div',{staticClass:"el-form-item__error",staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){_vm.limitVolume = error.value}}},[_vm._v(_vm._s(error.text)),_c('span',{staticStyle:{"color":"#409eff","position":"absolute","right":"0"}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("подставить")])])]}}])},[_c('el-input',{attrs:{"value":_vm.inputString.limitVolume == null ? _vm.limitVolume : _vm.inputString.limitVolume,"type":"number","disabled":_vm.processing,"min":_vm.fromSun(_vm.minLimitVolume),"max":_vm.fromSun(_vm.maxLimitVolume),"step":_vm.fromSun(_vm.stepBase)},on:{"input":function($event){return _vm.input('limitVolume', $event)},"blur":function($event){return _vm.blur('limitVolume')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":_vm.currentPair[0],"size":24}}),_vm._v(_vm._s(_vm.currentPair[0]))]},proxy:true}])})],1),_c('el-form-item',{attrs:{"label":("Цена за 1 " + (_vm.currentPair[0]) + ":"),"prop":"limitPrice"},scopedSlots:_vm._u([{key:"error",fn:function(ref){
var error = ref.error;
return [_c('div',{staticClass:"el-form-item__error",staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){_vm.limitPrice = error.value}}},[_vm._v(_vm._s(error.text)),_c('span',{staticStyle:{"color":"#409eff","position":"absolute","right":"0"}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("подставить")])])]}}])},[_c('el-input',{attrs:{"value":_vm.inputString.limitPrice == null ? _vm.limitPrice : _vm.inputString.limitPrice,"type":"number","disabled":_vm.processing,"min":_vm.minPrice.div(_vm.priceDivisor),"max":_vm.maxPrice.div(_vm.priceDivisor),"step":_vm.stepPrice},on:{"input":function($event){return _vm.input('limitPrice', $event)},"blur":function($event){return _vm.blur('limitPrice')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":_vm.currentPair[1],"size":24}}),_vm._v(_vm._s(_vm.currentPair[1]))]},proxy:true}])})],1),_c('el-form-item',{attrs:{"label":"Всего:","prop":"limitTotal"},scopedSlots:_vm._u([{key:"error",fn:function(ref){
var error = ref.error;
return [_c('div',{staticClass:"el-form-item__error",staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){_vm.limitTotal = error.value}}},[_vm._v(_vm._s(error.text)),_c('span',{staticStyle:{"color":"#409eff","position":"absolute","right":"0"}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("подставить")])])]}}])},[_c('el-input',{attrs:{"value":_vm.inputString.limitTotal == null ? _vm.limitTotal : _vm.inputString.limitTotal,"type":"number","disabled":_vm.processing,"min":_vm.fromSun(_vm.minLimitTotal),"max":_vm.fromSun(_vm.maxLimitTotal),"step":_vm.fromSun(_vm.stepQuote)},on:{"input":function($event){return _vm.input('limitTotal', $event)},"blur":function($event){return _vm.blur('limitTotal')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":_vm.currentPair[1],"size":24}}),_vm._v(_vm._s(_vm.currentPair[1]))]},proxy:true}])})],1),_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"success","loading":_vm.processing},on:{"click":_vm.limitBuy}},[_vm._v("Купить")])],1)],1),_c('el-tab-pane',{attrs:{"label":"Рыночный","name":"market","disabled":_vm.orders.length == 0}},[_c('el-form',{ref:"marketBuy",attrs:{"model":this,"rules":_vm.rules,"status-icon":""}},[_c('el-form-item',{attrs:{"label":"Покупаете:","prop":"marketVolume"},scopedSlots:_vm._u([{key:"error",fn:function(ref){
var error = ref.error;
return [_c('div',{staticClass:"el-form-item__error",staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){_vm.marketVolume = error.value}}},[_vm._v(_vm._s(error.text)),_c('span',{staticStyle:{"color":"#409eff","position":"absolute","right":"0"}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("подставить")])])]}}])},[_c('el-input',{attrs:{"value":_vm.inputString.marketVolume == null ? _vm.marketVolume : _vm.inputString.marketVolume,"type":"number","disabled":_vm.processing,"min":_vm.fromSun(_vm.minMarketVolume),"max":_vm.fromSun(_vm.maxMarketVolume),"step":_vm.fromSun(_vm.stepBase)},on:{"input":function($event){return _vm.input('marketVolume', $event)},"blur":function($event){return _vm.blur('marketVolume')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":_vm.currentPair[0],"size":24}}),_vm._v(_vm._s(_vm.currentPair[0]))]},proxy:true}])})],1),_c('el-form-item',{attrs:{"label":"Полная стоимость:","prop":"marketTotal"},scopedSlots:_vm._u([{key:"error",fn:function(ref){
var error = ref.error;
return [_c('div',{staticClass:"el-form-item__error",staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){_vm.marketTotal = error.value}}},[_vm._v(_vm._s(error.text)),_c('span',{staticStyle:{"color":"#409eff","position":"absolute","right":"0"}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v("подставить")])])]}}])},[_c('el-input',{attrs:{"value":_vm.inputString.marketTotal == null ? _vm.marketTotal : _vm.inputString.marketTotal,"type":"number","disabled":_vm.processing,"min":_vm.fromSun(_vm.minMarketTotal),"max":_vm.fromSun(_vm.maxMarketTotal),"step":_vm.fromSun(_vm.stepQuote)},on:{"input":function($event){return _vm.input('marketTotal', $event)},"blur":function($event){return _vm.blur('marketTotal')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":_vm.currentPair[1],"size":24}}),_vm._v(_vm._s(_vm.currentPair[1]))]},proxy:true}])})],1),_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"Количество ордеров:"}}),_c('el-slider',{staticStyle:{"margin-bottom":"24px"},attrs:{"value":_vm.market.orders,"input-size":"mini","show-input":"","marks":_vm.sliderMarks,"min":0,"max":_vm.orders.length},on:{"input":_vm.takeOrders}}),_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"success","loading":_vm.processing},on:{"click":_vm.marketBuy}},[_vm._v("Купить")])],1)],1)],1),_c('allowance')],1)}
var staticRenderFns = []

export { render, staticRenderFns }