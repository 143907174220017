































import TronAddress from '../../components/TronAddress.vue'
# import AddressIcon from '../../components/AddressIcon.vue'

import { mapGetters, mapState } from 'vuex'

export default
  components: {TronAddress}
  data: ->
    currentPage: 1
    pageSize: 4
    # pageData: []
    baseTokenSymbol: 'USDT'
    # items: [
    #   number: 33
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ,
    #   number: 34
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ,
    #   number: 35
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ,
    #   number: 36
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ,
    #   number: 37
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ,
    #   number: 38
    #   address: 'TRz83oV1yQrVueH7LVVk3tDBGgxseN1KRJ'
    #   price: {base: 100, extra: 500}
    #   speedup: 45
    #   extraTokenSymbol: 'ARS'
    # ]
  computed: {
    ...mapGetters 'TokenMarket', {items: 'partnerPurchases'}
    # # ...mapGetters 'InvestBox', ['currentToken']
    # ...mapState 'InvestBox',
    #   price1Token: (s) -> s.IN1_tokenInfo.symbol
    #   price2Token: (s) -> s.IN2_tokenInfo.symbol
    #   payoutToken: (s) -> s.OUT_tokenInfo.symbol
    pageData: ->
      [...@items].sort((a,b) -> b.number - a.number).slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
