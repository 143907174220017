





















import { BProgress } from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'

export default
  components: {BProgress}
  computed: {
    ...mapGetters 'TokenMarket', ['userPayoutProgress']
    exists: -> @userPayoutProgress.exists
    number: -> @userPayoutProgress.number
    total: -> @userPayoutProgress.total
    current: -> @userPayoutProgress.current
  }
