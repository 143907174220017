


























import { mapGetters } from 'vuex'
import AddressIcon from '../../components/AddressIcon.vue'

export default
  components: {AddressIcon}
  data: ->
    tab: '1'
  computed:
    mapGetters 'Cabinet', ['partners']
