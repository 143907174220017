var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding-top: 0px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Мои отчеты")])]),_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"label":"Начало","align":"center","width":"152"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var timestamp = ref.row.timestamp;
return [_vm._v(_vm._s(new Date(timestamp).toLocaleString()))]}}])}),_c('el-table-column',{attrs:{"label":"Сумма","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.row.amount;
return [_vm._v(_vm._s(amount.base.toLocaleString('ru-RU'))+" "+_vm._s(_vm.currentToken))]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"Тип","align":"center","width":"60"}}),_c('el-table-column',{attrs:{"label":"Добыто","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stake = ref.row;
return [_c('vue-countdown-timer',{attrs:{"start-time":stake.timer.start,"end-time":stake.timer.end,"interval":500},scopedSlots:_vm._u([{key:"countdown",fn:function(ref){
var ref_props = ref.props;
var days = ref_props.days;
var hours = ref_props.hours;
var minutes = ref_props.minutes;
var seconds = ref_props.seconds;
return [_vm._v(_vm._s((+(stake.amount.mined + stake.amount.leftToMine*(+new Date - stake.timer.start )/(stake.timer.end - stake.timer.start)).toFixed(0)).toLocaleString('ru-RU'))+" "+_vm._s(_vm.currentToken))]}},{key:"end-text",fn:function(){return [_vm._v(_vm._s(stake.amount.final.toLocaleString('ru-RU'))+" "+_vm._s(_vm.currentToken))]},proxy:true}],null,true)})]}}])}),_c('el-table-column',{attrs:{"label":"Статус","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stake = ref.row;
return [(stake.completed || (+new Date) > stake.timer.end)?[_vm._v("Завершен")]:[_vm._v("В работе")]]}}])})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }