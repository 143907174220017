



































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/KingOfTheHill.coffee"

import AvailableTokens from "./KingOfTheHill/AvailableTokens.vue"
import Bank from "./KingOfTheHill/Bank.vue"
import Buy from "./KingOfTheHill/Buy.vue"
import FrozenTokens from "./KingOfTheHill/FrozenTokens.vue"
import LastBuyer from "./KingOfTheHill/LastBuyer.vue"
import Losers from "./KingOfTheHill/Losers.vue"
import MyAchievments from "./KingOfTheHill/MyAchievments.vue"
import PurchasedTokens from "./KingOfTheHill/PurchasedTokens.vue"
import TimeLeft from "./KingOfTheHill/TimeLeft.vue"
import Winners from "./KingOfTheHill/Winners.vue"
import YourPrize from "./KingOfTheHill/YourPrize.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    AvailableTokens
    Bank
    Buy
    FrozenTokens
    LastBuyer
    Losers
    MyAchievments
    PurchasedTokens
    TimeLeft
    Winners
    YourPrize
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState 'KingOfTheHill', kingOfTheHillLoading: 'loading'
    ...mapState 'Cabinet', cabinetLoading: 'loading'
    loading: -> @kingOfTheHillLoading or @cabinetLoading
    ...WYSIWYG.computed
  }
  mounted: ->
    @$store.dispatch("KingOfTheHill/load")
    @$store.dispatch("Cabinet/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch 'KingOfTheHill/silentReload'
      else
        @$store.dispatch("KingOfTheHill/reload")
  }
}
