# TronLink

import config from '../config.coffee'

export default
  namespaced: true
  state: =>
    waiting: true
    installed: false
    ready: false
    account:
      address: false
      name: false
    node:
      fullNode: undefined
      eventServer: undefined
      solidityNode: undefined
  mutations:
    detected: (state) -> state.installed = true
    ready: (state) -> state.ready = true
    account: (state, account) ->
      state.account.address = account.address
      state.account.name = account.name
    node: (state, node) ->
      state.node.fullNode = node.fullNode
      state.node.eventServer = node.eventServer
      state.node.solidityNode = node.solidityNode
    stopWaiting: (state) -> state.waiting = false
  actions:
    initialize: ({dispatch}) ->
      @messageHandler = (e) =>
        if e.data.isTronLink
          switch e.data.message.action
            when "tabReply" then dispatch('tabReply' , e.data.message.data)
            when "setAccount" then dispatch('setAccount' , e.data.message.data)
            when "setNode" then dispatch('setNode' , e.data.message.data)
            else
              dispatch('defaultEventHandler' , e.data.message)
      dispatch('listen')
      dispatch('startDetectionPoll')
    listen: -> window.addEventListener 'message', @messageHandler
    unlisten: -> window.removeEventListener 'message', @messageHandler
    detect: ({commit, state, dispatch, getters}) ->
      # console.info 'try detection'
      commit('detected') if !!window.tronWeb and not state.ready
      commit('ready') if !!window.tronWeb and window.tronWeb.ready
      if state.ready
        # console.info 'detected'
        {base58, name} = tronWeb.defaultAddress
        dispatch('setAccount' , {address: base58, name})
        # console.info tronWeb.ready
        dispatch('stopDetectionPoll')
        dispatch('setNodeFromTronWeb')
        # console.info state.waiting
        # console.info getters.flags
    startDetectionPoll: ({dispatch}) ->
      # console.info 'start detection'
      @poll = setInterval((-> dispatch('detect')), 10)
      setTimeout((-> dispatch('stopDetectionPoll')), 3000)
    stopDetectionPoll: ({commit, state, dispatch}) ->
      # console.info 'stop detection'
      if(state.waiting)
        clearInterval(@poll)
        commit('stopWaiting')
        dispatch 'getReferrerAddress', null, root: true
    setNodeFromTronWeb: ({commit}) -> commit('node', {
      fullNode: tronWeb.fullNode.host
      eventServer: tronWeb.eventServer.host
      solidityNode: tronWeb.solidityNode.host
    })

    # message handlers
    tabReply: ({commit}, msg) ->
      # console.info("tabReply", msg)
      if(msg.success and msg.data.node)
        commit('account', {address: msg.data.address, name: msg.data.name})
        commit('node', msg.data.node)
    setAccount: ({commit}, data) ->
      # console.info("setAccount", data)
      commit('account', data)
    setNode: ({commit}, data) ->
      # console.info("setNode", data)
      commit('node', data.node)
    defaultEventHandler: ({}, message) ->
      # console.log(message.action, message.data)
  getters:
    isInstalled: (s) -> s.installed
    isWaiting: (s) -> s.waiting
    isLocked: (s) -> s.installed and (not s.ready or not !!s.account.address)
    isConnected: (s) -> config.tronLink.network == config.tronLink.nodes[s.node.fullNode]?.network
    flags: (_, getters) ->
      isWaiting: getters.isWaiting
      isLocked: getters.isLocked
      isConnected: getters.isConnected
      isInstalled: getters.isInstalled
