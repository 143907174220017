export default [{
	breakpoint: 'MobilM'
	breakpointWidth: 1
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 24, height: 8, id: 1, component: 'Bank'}
		{ x: 0, y: 8, width: 24, height: 7, id: 2, component: 'LastBuyer'}
		{ x: 0, y: 48, width: 24, height: 8, id: 3, component: 'YourPrize'}
		{ x: 0, y: 15, width: 24, height: 8, id: 4, component: 'TimeLeft'}
		{ x: 0, y: 23, width: 24, height: 25, id: 5, component: 'Buy'}
		{ x: 0, y: 80, width: 24, height: 29, id: 6, component: 'Losers'}
		{ x: 0, y: 56, width: 24, height: 8, id: 7, component: 'AvailableTokens'}
		{ x: 0, y: 72, width: 24, height: 8, id: 8, component: 'FrozenTokens'}
		{ x: 0, y: 64, width: 24, height: 8, id: 9, component: 'PurchasedTokens'}
		{ x: 0, y: 109, width: 24, height: 22, id: 10, component: 'Winners'}
		{ x: 0, y: 131, width: 24, height: 31, id: 11, component: 'MyAchievments'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 24, height: 8, id: 1, component: 'Bank'}
		{ x: 0, y: 8, width: 24, height: 7, id: 2, component: 'LastBuyer'}
		{ x: 0, y: 48, width: 12, height: 8, id: 3, component: 'YourPrize'}
		{ x: 0, y: 15, width: 24, height: 8, id: 4, component: 'TimeLeft'}
		{ x: 0, y: 23, width: 24, height: 25, id: 5, component: 'Buy'}
		{ x: 0, y: 64, width: 24, height: 29, id: 6, component: 'Losers'}
		{ x: 12, y: 48, width: 12, height: 8, id: 7, component: 'AvailableTokens'}
		{ x: 12, y: 56, width: 12, height: 8, id: 8, component: 'FrozenTokens'}
		{ x: 0, y: 56, width: 12, height: 8, id: 9, component: 'PurchasedTokens'}
		{ x: 0, y: 93, width: 24, height: 20, id: 10, component: 'Winners'}
		{ x: 0, y: 113, width: 24, height: 28, id: 11, component: 'MyAchievments'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 767
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 9, height: 8, id: 1, component: 'Bank'}
		{ x: 9, y: 0, width: 15, height: 8, id: 2, component: 'LastBuyer'}
		{ x: 9, y: 16, width: 8, height: 8, id: 3, component: 'YourPrize'}
		{ x: 9, y: 8, width: 15, height: 8, id: 4, component: 'TimeLeft'}
		{ x: 0, y: 8, width: 9, height: 25, id: 5, component: 'Buy'}
		{ x: 0, y: 33, width: 12, height: 25, id: 6, component: 'Losers'}
		{ x: 9, y: 24, width: 8, height: 9, id: 7, component: 'AvailableTokens'}
		{ x: 17, y: 24, width: 7, height: 9, id: 8, component: 'FrozenTokens'}
		{ x: 17, y: 16, width: 7, height: 8, id: 9, component: 'PurchasedTokens'}
		{ x: 0, y: 58, width: 24, height: 20, id: 10, component: 'Winners'}
		{ x: 12, y: 33, width: 12, height: 25, id: 11, component: 'MyAchievments'}
	]
}
{
	breakpoint: 'L'
	breakpointWidth: 1017
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 6, height: 8, id: 1, component: 'Bank'}
		{ x: 6, y: 0, width: 12, height: 8, id: 2, component: 'LastBuyer'}
		{ x: 18, y: 0, width: 5, height: 11, id: 3, component: 'YourPrize'}
		{ x: 0, y: 8, width: 6, height: 8, id: 4, component: 'TimeLeft'}
		{ x: 0, y: 16, width: 6, height: 25, id: 5, component: 'Buy'}
		{ x: 6, y: 8, width: 12, height: 33, id: 6, component: 'Losers'}
		{ x: 18, y: 11, width: 5, height: 10, id: 7, component: 'AvailableTokens'}
		{ x: 18, y: 21, width: 5, height: 10, id: 8, component: 'FrozenTokens'}
		{ x: 18, y: 31, width: 5, height: 10, id: 9, component: 'PurchasedTokens'}
		{ x: 0, y: 41, width: 14, height: 20, id: 10, component: 'Winners'}
		{ x: 14, y: 41, width: 9, height: 20, id: 11, component: 'MyAchievments'}
	]
}
]

# export default [{
# 	breakpoint: 'minimal'
# 	numberOfCols: 24
# 	rowHeight: 5
# 	items: [
# 		{ x: 0, y: 0, width: 6, height: 8, id: 1, component: 'Bank'}
# 		{ x: 6, y: 0, width: 12, height: 8, id: 2, component: 'LastBuyer'}
# 		{ x: 18, y: 0, width: 5, height: 10, id: 3, component: 'YourPrize'}
# 		{ x: 0, y: 8, width: 6, height: 7, id: 4, component: 'TimeLeft'}
# 		{ x: 0, y: 15, width: 6, height: 25, id: 5, component: 'Buy'}
# 		{ x: 6, y: 8, width: 12, height: 32, id: 6, component: 'Losers'}
# 		{ x: 18, y: 10, width: 5, height: 10, id: 7, component: 'AvailableTokens'}
# 		{ x: 18, y: 20, width: 5, height: 10, id: 8, component: 'FrozenTokens'}
# 		{ x: 18, y: 30, width: 5, height: 10, id: 9, component: 'PurchasedTokens'}
# 		{ x: 0, y: 40, width: 14, height: 20, id: 10, component: 'Winners'}
# 		{ x: 14, y: 40, width: 9, height: 20, id: 11, component: 'MyAchievments'}
# 	]
# }
# ]
# export default [{
#   breakpoint: 'xl'
#   numberOfCols: 12
#   rowHeight: 5
#   items: [
#     { x: 0, y:  0, width: 3, height:  7, id:  1, component: 'Bank' }
#     { x: 3, y:  0, width: 6, height:  7, id:  2, component: 'LastBuyer' }
#     { x: 9, y:  0, width: 3, height:  7, id:  3, component: 'YourPrize' }
#     { x: 0, y:  7, width: 3, height:  6, id:  4, component: 'TimeLeft' }
#     { x: 0, y: 16, width: 3, height: 18, id:  5, component: 'Buy' }
#     { x: 3, y:  7, width: 6, height: 24, id:  6, component: 'Losers' }
#     { x: 9, y:  7, width: 3, height:  7, id:  7, component: 'AvailableTokens' }
#     { x: 9, y: 14, width: 3, height:  7, id:  8, component: 'FrozenTokens' }
#     { x: 9, y: 21, width: 3, height:  7, id:  9, component: 'PurchasedTokens' }
#     { x: 0, y: 31, width: 7, height: 20, id: 10, component: 'Winners' }
#     { x: 7, y: 31, width: 5, height: 20, id: 11, component: 'MyAchievments' }
#   ]
# }]
# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]
