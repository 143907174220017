






export default
  data: ->
    checks:
      webWorker: !!window.Worker
      serviceWorker: !!navigator.serviceWorker
      cacheStorage: !!window.CacheStorage
      indexedDB: !!window.indexedDB
  mounted: ->
    openRequest = indexedDB.open "db", 1
    openRequest.onupgradeneeded = ({target: {result: db}}) ->
      console.info "DB initialization..."
      unless db.objectStoreNames.contains 'books'
        db.createObjectStore 'books', keyPath: 'id'

    openRequest.onerror = ({target: {error}}) -> console.error "Error", error
    openRequest.onsuccess = ({target: {result: db}}) ->
      console.info db
      # console.info 'success'
      # console.info arguments
      # console.info target
      # console.info openRequest



