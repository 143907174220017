# Staking

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'

import config from '../config.coffee'

import {address2hex, Multicall} from '../multicall.coffee'

STAKING_TYPE =
  0: "Light"
  1: "Basic"
  2: "Pro"

humanizeParams = ({parameters, speedups}) ->
  days = parameters.period.toNumber()/(24*3600)
  price:
    min: tronWeb.fromSun(parameters.minPrice)
    max: tronWeb.fromSun(parameters.maxPrice)
  dailyROI: (parameters.yield.toNumber()/days).toFixed(1)
  days: days
  speedups: speedups.map (e) -> e.toNumber()*100/parameters.percentBase.toNumber()

export default
  namespaced: true
  state: =>
    current: null
    initialized: false
    loading: false
    IN1_tokenInfo: null
    IN2_tokenInfo: null
    OUT_tokenInfo: null
    contract: null
    events: null
    parameters: null
    available: null
    currentTime: new Date()
    initialTotal: null
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'current'
      'loading'
      'IN1_tokenInfo'
      'IN2_tokenInfo'
      'OUT_tokenInfo'
      'contract'
      'events'
      'parameters'
      'available'
      'currentTime'
      'initialTotal'
    ]
  }
  actions:
    load: ({ state, dispatch }, {id}) -> dispatch (if state.initialized and state.current == id then 'reload' else 'initialize'), {id}
    initialize: ({state, commit, dispatch, getters}, {id}) ->
      return if state.loading
      console.info 'loading start'
      commit('loading', true) if state.current != id
      commit('current', id)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('contracts/load', config.contracts.InvestBox[state.current], root: true).then (c) ->
          commit 'contract', c
          dispatch('getContractState')
      ]
      console.info 'loading end'
      commit('loading', false)
      commit('initialization')
      dispatch('updateCurrentTime')
      commit('initialTotal', getters.total)
    reload: ({ commit, dispatch, state }, {id}) ->
      return if state.loading
      commit('loading', true) if state.current != id
      commit('current', id)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}, {id}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    getContractEvents: ({commit, state}) ->
      getAllContractEvents(config.contracts.InvestBox[state.current]).then((events) => commit('events', events)).catch(console.error)
      # Promise.all([
      #   repeat(-> getAllContractEvents(config.contracts.ComboSwap[state.current]))
      #   Promise.all config.contracts.AcceleratedStaking[state.current].map (address) -> repeat(-> getAllContractEvents(address))
      # ]).then ([swap, staking]) ->
      #   commit 'events', {swap, staking}
      # .catch(console.error)
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        parameters: contract.methodInstances.getParameters
        IN1_tokenInfo: contract.methodInstances.IN1_tokenInfo
        IN2_tokenInfo: contract.methodInstances.IN2_tokenInfo
        OUT_tokenInfo: contract.methodInstances.OUT_tokenInfo
      }

      contractHexAddress = contract.address.replace(/^41/,'0x')

      for name, method of methods
        methods[name] = {
          contract: contractHexAddress
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          # parameters: []
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # Promise.all [
      #   repeat(-> contract.getParameters().call()).then(commitResult 'parameters')
      #   repeat(-> contract.IN1_tokenInfo().call()).then(commitResult 'IN1_tokenInfo')
      #   repeat(-> contract.IN2_tokenInfo().call()).then(commitResult 'IN2_tokenInfo')
      #   repeat(-> contract.OUT_tokenInfo().call()).then(commitResult 'OUT_tokenInfo')
      # ]
    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      contract = state.contract
      Promise.all [
        repeat(-> contract.availableFor(userAddress).call()).then((val) -> commit('available', val.available))
      ]
    updateCurrentTime: ({state, getters, dispatch, commit}) ->
      # profit = {Light: 0, Basic: 0, Pro: 0}
      # for {timer: {end, start}, amount, type, completed} in getters.myStakes
      #   now = +new Date
      #   if completed or now > end
      #     profit[type] += amount.final
      #   else
      #     if now > start
      #       profit[type] += amount.mined + amount.leftToMine * (now - start) / (end - start)
      # for taken, i in getters.taken
      #   profit[STAKING_TYPE[i]] -= taken
      # console.info state.currentTime
      commit 'currentTime', new Date()
      setTimeout (-> dispatch('updateCurrentTime')), 500

  getters:
    now: (state) -> state.currentTime
    userAddress: -> arguments[2].TronLink.account.address
    # current: ({current}) -> current
    package: (state) ->
      count: state.parameters.packageCount.toNumber()
      price1: state.parameters.price1
      price2: state.parameters.price2
      payout: state.parameters.payout
      timespan: state.parameters.timespan.toNumber()
    available: ({available, initialTotal}, {total}) -> +tronWeb.fromSun(available) + (total - initialTotal)
    purchases: (state, {userAddress, now}) ->
      purchases = []
      for event in state.events
        address = tronWeb.address.fromHex event.result.user
        if event.name == "Purchased" and address == userAddress
          price1 = tronWeb.fromSun event.result.price1
          price2 = tronWeb.fromSun event.result.price2
          start = new Date(+event.result.start*1000)
          end = new Date(+event.result.end*1000)
          # now = new Date()
          total = Math.ceil(((+end) - (+start))/(3600*24*1000))
          if now > end
            current = total
          else
            current = Math.floor(((+now) - (+start))/(3600*24*1000))
          days = {total, current}
          total = +tronWeb.fromSun(event.result.payout)
          if now > end
            current = total
          else
            current = (((+now) - (+start))/((+end) - (+start)))*total
          payout = {total, current}
          purchases.push {
            price1
            price2
            payout
            start
            end
            days
          }
      return purchases
    total: (state, {purchases}) -> purchases.map((e) -> e.payout.current).reduce ((a,b) -> a+b), 0
    invested: (state, {purchases}) ->
      token1: purchases.map((e) -> +e.price1).reduce ((a,b) -> a+b), 0
      token2: purchases.map((e) -> +e.price2).reduce ((a,b) -> a+b), 0
    # staking: (store) ->
    #   light: humanizeParams store.staking[0]
    #   basic: humanizeParams store.staking[1]
    #   pro: humanizeParams store.staking[2]
    # profit: (state) ->
    #   total: Math.round(state.profit.Light + state.profit.Basic + state.profit.Pro).toLocaleString('ru-RU')
    #   light: Math.round(state.profit.Light).toLocaleString('ru-RU')
    #   basic: Math.round(state.profit.Basic).toLocaleString('ru-RU')
    #   pro: Math.round(state.profit.Pro).toLocaleString('ru-RU')
    # mined: -> 50000
    # pushed: (state, {myStakes}) -> myStakes.map((e) -> e.amount.base).reduce ((a,b) -> a+b), 0
    # swapTokens: (state) ->
    #   in1: state.IN1_tokenInfo.symbol
    #   in2: state.IN2_tokenInfo.symbol
    #   out: state.OUT_tokenInfo.symbol
    # mySwaps: (state, {userAddress}) ->
    #   state.events.swap.filter(({result}) -> tronWeb.address.fromHex(result.user) == userAddress).map ({timestamp, transaction, result: {in1, in2, out}}) ->
    #     tx: transaction
    #     timestamp: timestamp
    #     in1: +tronWeb.fromSun in1
    #     in2: +tronWeb.fromSun in2
    #     out: +tronWeb.fromSun out
    #   .sort (a,b) -> b.timestamp - a.timestamp
    # taken: (state, {userAddress}) ->
    #   result = [0, 0, 0]
    #   for events, k in state.events.staking
    #     for event in events
    #       if event.name == "Taken" and tronWeb.address.fromHex(event.result.user) == userAddress
    #         result[k] += +tronWeb.fromSun event.result.amount
    #   return result
    # allStakes: (state) ->
    #   stakes = {}
    #   for events, k in state.events.staking
    #     for event in events
    #       switch event.name
    #         # event StakeCreated(address user, uint number, uint start, uint end, uint baseAmount, uint finalAmount);
    #         when "StakeCreated"
    #           {user, number, start, end, baseAmount, finalAmount} = event.result
    #           timestamp = event.timestamp
    #           number = +number
    #           timer = {start: 1000*(+start), end: 1000*(+end) }
    #           amount =
    #             base: +tronWeb.fromSun baseAmount
    #             final: +tronWeb.fromSun finalAmount
    #             mined: 0
    #             leftToMine: +tronWeb.fromSun finalAmount
    #           completed = false
    #           user = tronWeb.address.fromHex user
    #           stake = {timestamp,number,timer,amount,completed,user,type: STAKING_TYPE[k]}
    #           stakes[user] ||= [[],[],[]]
    #           stakes[user][k][number - 1] = stake
    #         # event StakeUpdated(address user, uint number, uint start, uint end, uint leftToMine, uint mined);
    #         when "StakeUpdated"
    #           {user, number, start, end, leftToMine, mined} = event.result
    #           user = tronWeb.address.fromHex user
    #           i = number - 1
    #           stakes[user][k][i].timer.start = 1000*(+start)
    #           stakes[user][k][i].timer.end = 1000*(+end)
    #           stakes[user][k][i].amount.mined += +tronWeb.fromSun mined
    #           stakes[user][k][i].amount.leftToMine = +tronWeb.fromSun leftToMine

    #         # event StakeCompleted(address user, uint number);
    #         when "StakeCompleted"
    #           {user, number} = event.result
    #           user = tronWeb.address.fromHex user
    #           i = +number - 1
    #           stakes[user][k][i].completed = true
    #   return stakes
    # myStakes: (state, {userAddress, allStakes}) -> (allStakes[userAddress] || []).flat()
    # partnerStakes: (state, {userAddress, allStakes}, rootState, rootGetters) ->
    #   result = []
    #   for address, level of rootGetters['Cabinet/addressToLevel']
    #     stakes = (allStakes[address] || []).flat()
    #     if stakes and stakes.length > 0
    #       for stake in stakes
    #         result.push({
    #           level
    #           amount: stake.amount.base
    #           address
    #           timestamp: stake.timestamp
    #         })
    #   return result

