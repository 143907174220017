# Cashback

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'

import config from '../config.coffee'

import {address2hex, Multicall} from '../multicall.coffee'

humanizePackages = (packages) ->
  result = {}
  for [id,count,left,tokenPrice,trxPrice,payout,payoutInTokens,last] in packages
    count = count.toNumber()
    left = left.toNumber()
    tokenPrice = +tronWeb.fromSun(tokenPrice)
    trxPrice = +tronWeb.fromSun(trxPrice)
    payout = +tronWeb.fromSun(payout)
    payoutInTokens = !payoutInTokens.isZero()
    last = !last.isZero()
    result[id.toNumber()] = {count,left,tokenPrice,trxPrice,payout,payoutInTokens,last}
  return result

export default
  namespaced: true
  state: =>
    initialized: false
    loading: false
    contractCashback: null
    contractCashbackPro: null
    eventsCashback: []
    eventsCashbackPro: []
    availableCashback: null
    frozenCashback: null
    availableCashbackPro: null
    frozenCashbackPro: null
    isStartedCashback: null
    isStartedCashbackPro: null
    currentPackageIdCashback: null
    currentPackageIdCashbackPro: null
    currentPackageInfoCashback: {}
    currentPackageInfoCashbackPro: {}
    cashbackOwner: null
    cashbackProOwner: null
    freezeTimeCashback: null
    freezeTimeCashbackPro: null
    cashbackPackageIds: null
    cashbackProPackageIds: null
    cashbackPackages: null
    cashbackProPackages: null
    availableToCollectCashback: null
    availableToCollectCashbackPro: null
    debtsCashback: null
    debtsCashbackPro: null
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'loading'
      'contractCashback'
      'contractCashbackPro'
      'eventsCashback'
      'eventsCashbackPro'
      'availableCashback'
      'frozenCashback'
      'availableCashbackPro'
      'frozenCashbackPro'
      'isStartedCashback'
      'isStartedCashbackPro'
      'currentPackageIdCashback'
      'currentPackageIdCashbackPro'
      'currentPackageInfoCashback'
      'currentPackageInfoCashbackPro'
      'cashbackOwner'
      'cashbackProOwner'
      'freezeTimeCashback'
      'freezeTimeCashbackPro'
      'cashbackPackageIds'
      'cashbackProPackageIds'
      'cashbackPackages'
      'cashbackProPackages'
      'availableToCollectCashback'
      'availableToCollectCashbackPro'
      'debtsCashback'
      'debtsCashbackPro'
    ]
  }
  actions:
    load: ({ state, dispatch }) -> dispatch(if state.initialized then 'reload' else 'initialize')
    initialize: ({state, commit, dispatch}) ->
      return if state.loading
      console.info 'loading start'
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        Promise.all([
          dispatch('contracts/load', config.contracts.MultistageSwap.HYHO, root: true).then((c) => commit('contractCashback', c)).catch(console.error)
          dispatch('contracts/load', config.contracts.MultistageSwap.ARS, root: true).then((c) => commit('contractCashbackPro', c)).catch(console.error)
        ]).then -> Promise.all [
          dispatch('getContractState')
          dispatch('getOwnership')
        ]
      ]
      console.info 'loading end'
      commit('loading', false)
      commit('initialization')
    reload: ({ commit, dispatch, state }) ->
      return if state.loading
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    getOwnership: ({state, commit}) ->
      # state.contractCashback.owner().call().then (hexAddr) -> commit 'cashbackOwner', tronWeb.address.fromHex(hexAddr)
      # state.contractCashbackPro.owner().call().then (hexAddr) -> commit 'cashbackProOwner', tronWeb.address.fromHex(hexAddr)
    getContractEvents: ({commit}) -> Promise.all [
      # repeat(-> getAllContractEvents(config.contracts.MultistageSwap.HYHO)).then((events) => commit('eventsCashback', events)).catch(console.error)
      # repeat(-> getAllContractEvents(config.contracts.MultistageSwap.ARS)).then((events) => commit('eventsCashbackPro', events)).catch(console.error)
      getAllContractEvents(config.contracts.MultistageSwap.HYHO).then((events) => commit('eventsCashback', events)).catch(console.error)
      getAllContractEvents(config.contracts.MultistageSwap.ARS).then((events) => commit('eventsCashbackPro', events)).catch(console.error)
    ]
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      contract1 = state.contractCashback
      contract2 = state.contractCashbackPro
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        isStartedCashback: contract1.methodInstances.isStarted
        currentPackageIdCashback: contract1.methodInstances.currentPackageId
        freezeTimeCashback: contract1.methodInstances.freezeTime
        cashbackPackages: contract1.methodInstances.packages
        cashbackPackageIds: contract1.methodInstances.packageIds
        availableToCollectCashback: contract1.methodInstances.availableToCollect
        debtsCashback: contract1.methodInstances.debts
        isStartedCashbackPro: contract2.methodInstances.isStarted
        currentPackageIdCashbackPro: contract2.methodInstances.currentPackageId
        freezeTimeCashbackPro: contract2.methodInstances.freezeTime
        cashbackProPackages: contract2.methodInstances.packages
        cashbackProPackageIds: contract2.methodInstances.packageIds
        availableToCollectCashbackPro: contract2.methodInstances.availableToCollect
        debtsCashbackPro: contract2.methodInstances.debts
      }

      # contractHexAddress = contract.address.replace(/^41/,'0x')

      for name, method of methods
        methods[name] = {
          contract: method.contract.address.replace(/^41/,'0x')
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          # parameters: []
        }

      methods.cashbackPackageIds.transform = (ids) -> ids.map (e) -> e.toNumber()
      methods.cashbackProPackageIds.transform = methods.cashbackPackageIds.transform

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # return
      # Promise.all [
      #   repeat(-> contract1.isStarted().call()).then(commitResult 'isStartedCashback').then ->
      #     return unless state.isStartedCashback
      #     repeat(-> contract1.currentPackageId().call()).then(commitResult 'currentPackageIdCashback').then ->
      #       console.info('currentPackageIdCashback:', state.currentPackageIdCashback.toNumber())
      #       # contract1.currentPackageInfoCashback().call().then commitResult 'currentPackageInfoCashback'
      #     .catch -> commit 'isStartedCashback', false
      #   repeat(-> contract2.isStarted().call()).then(commitResult 'isStartedCashbackPro').then ->
      #     return unless state.isStartedCashbackPro
      #     repeat(-> contract2.currentPackageId().call()).then(commitResult 'currentPackageIdCashbackPro').then ->
      #       console.info('currentPackageIdCashbackPro:', state.currentPackageIdCashbackPro.toNumber())
      #     .catch -> commit 'isStartedCashbackPro', false
      #   repeat(-> contract1.freezeTime().call()).then commitResult 'freezeTimeCashback'
      #   repeat(-> contract2.freezeTime().call()).then commitResult 'freezeTimeCashbackPro'
      #   repeat(-> contract1.packages().call()).then commitResult 'cashbackPackages'
      #   repeat(-> contract2.packages().call()).then commitResult 'cashbackProPackages'
      #   repeat(-> contract1.packageIds().call()).then ({ids}) -> commit 'cashbackPackageIds', ids.map (e) -> e.toNumber()
      #   repeat(-> contract2.packageIds().call()).then ({ids}) -> commit 'cashbackProPackageIds', ids.map (e) -> e.toNumber()
      #   repeat(-> contract1.availableToCollect().call()).then commitResult 'availableToCollectCashback'
      #   repeat(-> contract2.availableToCollect().call()).then commitResult 'availableToCollectCashbackPro'
      #   repeat(-> contract1.debts().call()).then commitResult 'debtsCashback'
      #   repeat(-> contract2.debts().call()).then commitResult 'debtsCashbackPro'
      # ]

    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      commitResult = (name) -> (value) -> commit(name, value)
      contract1 = state.contractCashback
      contract2 = state.contractCashbackPro

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        availableCashback: contract1.methodInstances.availableFor
        frozenCashback: contract1.methodInstances.frozenFor
        availableCashbackPro: contract2.methodInstances.availableFor
        frozenCashbackPro: contract2.methodInstances.frozenFor
      }

      # contractHexAddress = contract.address.replace(/^41/,'0x')

      userHexAddress = address2hex userAddress

      for name, method of methods
        methods[name] = {
          contract: method.contract.address.replace(/^41/,'0x')
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          parameters: [userHexAddress]
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # return
      # Promise.all [
      #   repeat(-> contract1.availableFor(userAddress).call()).then commitResult 'availableCashback'
      #   repeat(-> contract1.frozenFor(userAddress).call()).then commitResult 'frozenCashback'
      #   repeat(-> contract2.availableFor(userAddress).call()).then commitResult 'availableCashbackPro'
      #   repeat(-> contract2.frozenFor(userAddress).call()).then commitResult 'frozenCashbackPro'
      # ]
  getters:
    userAddress: -> arguments[2].TronLink.account.address
    cashbackTokensAvailable: (state) -> tronWeb.fromSun(state.availableCashback.availableTokens)
    cashbackProTokensAvailable: (state) -> tronWeb.fromSun(state.availableCashbackPro.availableTokens)
    cashbackTrxAvailable: (state) -> tronWeb.fromSun(state.availableCashback.availableTrx)
    cashbackProTrxAvailable: (state) -> tronWeb.fromSun(state.availableCashbackPro.availableTrx)
    cashbackTokensFrozen: (state) -> tronWeb.fromSun(state.frozenCashback.frozenTokens)
    cashbackProTokensFrozen: (state) -> tronWeb.fromSun(state.frozenCashbackPro.frozenTokens)
    cashbackTrxFrozen: (state) -> tronWeb.fromSun(state.frozenCashback.frozenTrx)
    cashbackProTrxFrozen: (state) -> tronWeb.fromSun(state.frozenCashbackPro.frozenTrx)
    cashbackPackages: (state) -> humanizePackages(state.cashbackPackages)
    cashbackProPackages: (state) -> humanizePackages(state.cashbackProPackages)
    freezeTime: (state) ->
      cashback:
        trx: state.freezeTimeCashback.trxFreezeTime.toNumber()
        token: state.freezeTimeCashback.tokenFreezeTime.toNumber()
      cashbackPro:
        trx: state.freezeTimeCashbackPro.trxFreezeTime.toNumber()
        token: state.freezeTimeCashbackPro.tokenFreezeTime.toNumber()
    currentPackage: (state, getters) ->
      cashback: getters.cashbackPackages[state.currentPackageIdCashback?.toNumber() || 0]
      cashbackPro: getters.cashbackProPackages[state.currentPackageIdCashbackPro?.toNumber() || 0]
    debts: (state) ->
      cashback:
        trx: +tronWeb.fromSun(state.debtsCashback.trxDebt)
        token: +tronWeb.fromSun(state.debtsCashback.tokenDebt)
      cashbackPro:
        trx: +tronWeb.fromSun(state.debtsCashbackPro.trxDebt)
        token: +tronWeb.fromSun(state.debtsCashbackPro.tokenDebt)
    availableToCollect: (state) ->
      cashback:
        trx: +tronWeb.fromSun(state.availableToCollectCashback.availableTrx)
        token: +tronWeb.fromSun(state.availableToCollectCashback.availableTokens)
      cashbackPro:
        trx: +tronWeb.fromSun(state.availableToCollectCashbackPro.availableTrx)
        token: +tronWeb.fromSun(state.availableToCollectCashbackPro.availableTokens)
    balances: (_, g) ->
      cashback:
        trx: if g.debts.cashback.trx > 0 then -g.debts.cashback.trx else g.availableToCollect.cashback.trx
        token: if g.debts.cashback.token > 0 then -g.debts.cashback.token else g.availableToCollect.cashback.token
      cashbackPro:
        trx: if g.debts.cashbackPro.trx > 0 then -g.debts.cashbackPro.trx else g.availableToCollect.cashbackPro.trx
        token: if g.debts.cashbackPro.token > 0 then -g.debts.cashbackPro.token else g.availableToCollect.cashbackPro.token
    cashbackSubscription: (state, getters) ->
      state.eventsCashback.filter((e) -> e.name == "PayoutScheduled" and tronWeb.address.fromHex(e.result.user) == getters.userAddress).map ({result, timestamp}) ->
        date: timestamp
        frozenUntil: (+result.frozenUntil)*1000
        amount: tronWeb.fromSun(result.amount)
        tokenPrice: tronWeb.fromSun(result.tokenPrice)
        trxPrice: tronWeb.fromSun(result.trxPrice)
        inTokens: result.inTokens == "true"
      .sort (a,b) -> a.date - b.date
    cashbackProSubscription: (state, getters) ->
      state.eventsCashbackPro.filter((e) -> e.name == "PayoutScheduled" and tronWeb.address.fromHex(e.result.user) == getters.userAddress).map ({result, timestamp}) ->
        date: timestamp
        frozenUntil: (+result.frozenUntil)*1000
        amount: tronWeb.fromSun(result.amount)
        tokenPrice: tronWeb.fromSun(result.tokenPrice)
        trxPrice: tronWeb.fromSun(result.trxPrice)
        inTokens: result.inTokens == "true"
      .sort (a,b) -> a.date - b.date
