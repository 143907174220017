import { render, staticRenderFns } from "./PartnerPurchases.vue?vue&type=template&id=45003b72&scoped=true&lang=pug&"
import script from "./PartnerPurchases.vue?vue&type=script&lang=coffee&"
export * from "./PartnerPurchases.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45003b72",
  null
  
)

export default component.exports