























import { mapGetters, mapState } from 'vuex'
import { BProgress } from 'bootstrap-vue'

export default
  components: {BProgress}
  computed: {
    ...mapGetters 'KingOfTheHill', ['stopAt', 'startAt']
    ...mapState 'KingOfTheHill', ['stopped', 'survivalTimeInterval']
    period: ->
      seconds = @survivalTimeInterval.toNumber()
      minutes = Math.floor(seconds / 60)
      hours   = Math.floor(minutes / 60)
      days    = Math.floor(hours / 24)
      seconds = seconds % 60
      minutes = minutes % 60
      hours   = hours % 24
      @format 0, days*24 + hours, minutes, seconds
  }
  mounted: ->
    # console.info @survivalTimeInterval.toNumber()
  methods:
    format: (days, hours, minutes, seconds) ->
      r = []
      r.push((+days).toString()) unless days == 0
      r.push((+hours).toString().padStart(2, '0')) unless days == 0 and hours == 0
      r.push((+minutes).toString().padStart(2, '0')) unless days == 0 and hours == 0 and miutes == 0
      r.push((+seconds).toString().padStart(2, '0'))
      r.join(':')
    progress: ->
      value: +new Date - @startAt
      max: @stopAt - @startAt

