var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding-top: 0px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Очередь личных игр")])]),_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"prop":"place","label":"Место","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"number","label":"№","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"amount","label":"Сумма","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.row.amount;
return [_vm._v(_vm._s(amount.toLocaleString('ru-RU'))+" TRX")]}}])}),_c('el-table-column',{attrs:{"prop":"amount","label":"Выход из очереди","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var renounce = ref_row.renounce;
var number = ref_row.number;
return [_c('vue-countdown-timer',{attrs:{"start-time":+new Date(),"end-time":+renounce /*+ 127*24*3600*1000*/},scopedSlots:_vm._u([{key:"countdown",fn:function(ref){
var ref_props = ref.props;
var days = ref_props.days;
var hours = ref_props.hours;
var minutes = ref_props.minutes;
var seconds = ref_props.seconds;
return [(+days > 0)?[_vm._v("через "+_vm._s(days)+"д "+_vm._s(hours)+"ч "+_vm._s(minutes)+"м")]:(+hours > 0)?[_vm._v("через "+_vm._s(hours)+"ч "+_vm._s(minutes)+"м")]:[_vm._v("через "+_vm._s(minutes)+":"+_vm._s(seconds))]]}},{key:"end-text",fn:function(){return [_c('el-button',{staticClass:"reposition",attrs:{"size":"small","type":"green"},on:{"click":function($event){return _vm.leaveQueue(number)}}},[_vm._v("Выход")])]},proxy:true}],null,true)})]}}])})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }