












import { mapGetters } from 'vuex'
import icon from '../../components/icons/lock.vue'

export default
  components: {icon}
  computed:
    mapGetters 'KingOfTheHill', ['frozenTokens']
