var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding-top: 0px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("История покупок")])]),_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"label":"Дата","align":"center","width":"152"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var start = ref.row.start;
return [_vm._v(_vm._s(start.toLocaleString()))]}}])}),_c('el-table-column',{attrs:{"label":"Стоимость покупки","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var price1 = ref_row.price1;
var price2 = ref_row.price2;
return [_vm._v(_vm._s(price1)+" "+_vm._s(_vm.price1Token)+" + "+_vm._s(price2)+" "+_vm._s(_vm.price2Token))]}}])}),_c('el-table-column',{attrs:{"label":"Дней в работе","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.row.days;
return [_vm._v(_vm._s(days.current)+"/"+_vm._s(days.total))]}}])}),_c('el-table-column',{attrs:{"label":"Начислено","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var payout = ref.row.payout;
return [_vm._v(_vm._s(Math.round(payout.current).toLocaleString())+" / "+_vm._s(payout.total.toLocaleString())+" "+_vm._s(_vm.payoutToken))]}}])})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }