var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAllowances),expression:"loadingAllowances"}],staticClass:"full-height",attrs:{"body-style":"height: calc(100% - 40px); overflow-y: auto; padding-top: 0; padding-bottom: 20px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Мои разрешения")])]),_c('el-table',{attrs:{"data":_vm.allowances,"show-header":false}},[_c('el-table-column',{attrs:{"prop":"description","align":"left"}}),_c('el-table-column',{attrs:{"prop":"tokens","align":"right","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var tokens = ref_row.tokens;
var contract = ref_row.contract;
var description = ref_row.description;
return [_vm._l((tokens),function(ref){
var token = ref.token;
var allowance = ref.allowance;
return _c('div',{key:token,staticClass:"allowances",on:{"click":function($event){return _vm.editAllowances(contract, tokens, description)}}},[_vm._v(_vm._s(allowance)),_c('token-icon',{staticStyle:{"vertical-align":"-2px","margin-right":"3px","margin-left":"6px"},attrs:{"symbol":token,"size":16}}),_vm._v(_vm._s(token))],1)}),_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit","circle":""},on:{"click":function($event){return _vm.editAllowances(contract, tokens, description)}}})]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.dialog.title,"visible":_vm.dialog.show,"width":"40%","close-on-click-modal":false},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "show", $event)}}},[_vm._l((_vm.dialog.tokens),function(ref){
var token = ref.token;
var allowance = ref.allowance;
return [_c('div',{key:token,staticClass:"input-row"},[_c('el-input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"type":"number","placeholder":"Ведите","min":"0","step":"0.000001","disabled":_vm.dialog.locked},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('token-icon',{staticStyle:{"vertical-align":"-6px","margin-right":"4px"},attrs:{"symbol":token,"size":24}}),_vm._v(_vm._s(token))]},proxy:true}],null,true),model:{value:(_vm.dialog.values[token]),callback:function ($$v) {_vm.$set(_vm.dialog.values, token, $$v)},expression:"dialog.values[token]"}}),_c('el-button',{attrs:{"icon":"el-icon-edit","disabled":_vm.dialog.locked},on:{"click":function($event){return _vm.approve(token, _vm.dialog.values[token])}}},[_vm._v("Изменить")]),_c('el-button',{attrs:{"icon":"el-icon-close","disabled":_vm.dialog.locked},on:{"click":function($event){return _vm.approve(token, 0)}}},[_vm._v("Запретить")])],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }