import Vue from 'vue'
import VueRouter from 'vue-router'
import Tron from './views/Tron.vue'
import Cabinet from './views/Cabinet.vue'
import Exchange from './views/Exchange.vue'
import KingOfTheHill from './views/KingOfTheHill.vue'
import Cashback from './views/Cashback.vue'
import Staking from './views/Staking.vue'
import StakingNoSwap from './views/StakingNoSwap.vue'
import InvestBox from './views/InvestBox.vue'
import TokenMarket from './views/TokenMarket.vue'
import TestPage from './Test.vue'
import Binar from './views/Binar.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: '/empire-network'},
  // { path: '/', redirect: '/staking/tether'},
  { path: '/', redirect: '/exchange/GIG/USDT'},
  { path: "/tron"              , name: 'Tron',              component: Tron,          meta: {menu: "Games", game: "Tron"} },
  { path: "/koth"              , name: 'King of the Hill',  component: KingOfTheHill, meta: {menu: "Games", game: "King of the Hill"} },
  // { path: "/empire-network"    , name: 'Empire Network',    component: Binar,         meta: {menu: "Games", game: "Empire Network"} },
  { path: "/cashback"          , name: 'Cashback',          component: Cashback },
  // { path: "/staking"           , name: 'Staking',          component: Staking },
  { path: "/staking/tether"    , name: 'Staking:USDT',      component: StakingNoSwap,       meta: {menu: "Staking", token: 'USDT', swap: false}, props: {token: 'USDT'} },
  { path: "/staking/aureus"    , name: 'Staking:ARS',       component: Staking,       meta: {menu: "Staking", token: 'ARS'} , props: {token: 'ARS'} },
  { path: "/staking/phoenix"   , name: 'Staking:PNX',       component: Staking,       meta: {menu: "Staking", token: 'PNX'} , props: {token: 'PNX'} },
  { path: "/staking/clc"       , name: 'Staking:CLC',       component: Staking,       meta: {menu: "Staking", token: 'CLC'} , props: {token: 'CLC'} },
  { path: "/staking/inglobax"  , name: 'Staking:IGX',       component: Staking,       meta: {menu: "Staking", token: 'IGX'} , props: {token: 'IGX'} },
  { path: "/exchange/GIG/USDT" , name: 'Exchange:GIG/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'GIG/USDT'} , props: {pair: 'GIG/USDT'} },
  { path: "/exchange/HYHO/TRX" , name: 'Exchange:HYHO/TRX', component: Exchange,      meta: {menu: "Exchange", pair: 'HYHO/TRX'} , props: {pair: 'HYHO/TRX'} },
  { path: "/exchange/ARS/USDT" , name: 'Exchange:ARS/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'ARS/USDT'} , props: {pair: 'ARS/USDT'} },
  { path: "/exchange/PNX/USDT" , name: 'Exchange:PNX/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'PNX/USDT'} , props: {pair: 'PNX/USDT'} },
  { path: "/exchange/CLC/USDT" , name: 'Exchange:CLC/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'CLC/USDT'} , props: {pair: 'CLC/USDT'} },
  { path: "/exchange/DEX/USDT" , name: 'Exchange:DEX/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'DEX/USDT'} , props: {pair: 'DEX/USDT'} },
  { path: "/exchange/DEX/BST"  , name: 'Exchange:DEX/BST',  component: Exchange,      meta: {menu: "Exchange", pair: 'DEX/BST'}  , props: {pair: 'DEX/BST'} },
  { path: "/exchange/IGX/USDT" , name: 'Exchange:IGX/USDT', component: Exchange,      meta: {menu: "Exchange", pair: 'IGX/USDT'} , props: {pair: 'IGX/USDT'} },
  { path: "/exchange/IGXT/USDT", name: 'Exchange:IGXT/USDT',component: Exchange,      meta: {menu: "Exchange", pair: 'IGXT/USDT'}, props: {pair: 'IGXT/USDT'} },
  { path: "/investbox/ars"     , name: 'InvestBox:ARS',     component: InvestBox,     meta: {menu: "InvestBox", subname: 'ARS'} , props: {id: 'ars'} },
  { path: "/investbox/pnx"     , name: 'InvestBox:PNX',     component: InvestBox,     meta: {menu: "InvestBox", subname: 'PNX'} , props: {id: 'pnx'} },
  { path: "/investbox/clc"     , name: 'InvestBox:CLC',     component: InvestBox,     meta: {menu: "InvestBox", subname: 'CLC'} , props: {id: 'clc'} },
  { path: "/market"            , name: 'Token Market',      component: TokenMarket,   meta: {menu: "TokenMarket"} },
  { path: "/cabinet"           , name: 'Cabinet',           component: Cabinet },
  { path: "/test", name: "Test Page", component: TestPage }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
