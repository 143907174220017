






































import RegistrationTron from '../../components/RegistrationTron.vue'
import {isFirstPlay} from '../../components/RegistrationTron.vue'

import {mapState, mapGetters} from 'vuex'
import {Message} from 'element-ui'

import {verboseSend} from '../../store/functions.coffee'

# window.Message = Message

export default
  components: {RegistrationTron}
  data: ->
    value: null
    processing: false
  computed: {
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
    ...mapState 'Tron', ['contract']
    ...mapGetters 'Tron', ['minAmount', 'maxAmount']
    valueInSun: -> tronWeb.toSun @value
    rules: ->
      value: [{
        trigger: 'blur'
        validator: (rule, value, callback) =>
          { balance } = await tronWeb.trx.getAccount(@userAddress)
          if tronWeb.BigNumber(@valueInSun).gt tronWeb.BigNumber(balance) # if value > balance
            callback(new Error("На вашем балансе недостаточно TRX"))
          else
            callback()
      },{
        trigger: 'change'
        validator: (rule, value, callback) =>
          if value < @minAmount
            callback(new Error("Минимальное значение #{@minAmount} TRX"))
          else if value > @maxAmount
            callback(new Error("Минимальное значение #{@maxAmount} TRX"))
          else
            callback()
      }]
  }
  methods:
    play: ->
      @$refs.form.validate (valid) => if valid
        @processing = true
        [checked, parentAddress] = await isFirstPlay()
        unless checked
          @processing = false
          return
        (if parentAddress
          verboseSend @contract["play(address)"](parentAddress), callValue: @valueInSun, feeLimit: 200000000
        else
          verboseSend @contract.play(), callValue: @valueInSun, feeLimit: 200000000)
        .then (msg) =>
          msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
          msg.duration = 3000
          @$emit 'reload', silent: true
        .finally =>
          @processing = false
