









































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/TokenMarket.coffee"

import TokenMarketCard from "./TokenMarket/Card.vue"
import TokenMarketAvailable from "./TokenMarket/Available.vue"
import TokenMarketFrozen from "./TokenMarket/Frozen.vue"
import TokenMarketPayoutProgress from "./TokenMarket/PayoutProgress.vue"
import TokenMarketUserPayoutProgress from "./TokenMarket/UserPayoutProgress.vue"
import TokenMarketPartnerPurchases from "./TokenMarket/PartnerPurchases.vue"
import TokenMarketHistory from "./TokenMarket/History.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    TokenMarketCard
    TokenMarketAvailable
    TokenMarketFrozen
    TokenMarketPayoutProgress
    TokenMarketUserPayoutProgress
    TokenMarketPartnerPurchases
    TokenMarketHistory
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  props:
    id: String
  computed: {
    ...mapState('TokenMarket', ['loading'])
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("TokenMarket/getContractUserState")
    id: -> @$store.dispatch("TokenMarket/load")
  }
  mounted: ->
    @$store.dispatch("TokenMarket/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch('TokenMarket/silentReload')
      else
        @$store.dispatch("TokenMarket/reload")
  }
}
