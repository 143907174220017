






















































import { mapGetters } from 'vuex'
import { formatDate } from '../../store/functions'
import PerfectScrollbar from 'perfect-scrollbar'


import {randomHistoryItem} from '@/mockup'


day = 24*60*60*1000

export default
  data: ->
    now: +new Date
    items: [1..13].map randomHistoryItem
    currentPage: 1
    pageSize: 5
  computed:
    pageData: ->
      [...@items].sort((a,b) -> b.number - a.number).slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      ).map(@transform)
    rows: -> @items.length
  filters:
    date: formatDate 'HH:mm:ss.SSS d MMMM'
  mounted: ->
    nextSecond = => @now = +new Date
    @timer = setInterval nextSecond, 1000
    # # val = @rows
    # val = 5 #if val < 5
    # @$el.parentElement.style.height = (val+1)*48+32+5+45+'px'
    scrollArea = @$el.getElementsByClassName('el-table__body-wrapper')[0]
    if scrollArea
      @ps = new PerfectScrollbar scrollArea, scrollingThreshold: 0, useBothWheelAxes: true
      @$nextTick => @ps.update()
  beforeDestroy: ->
    @ps.destroy() if @ps
    clearInterval @timer
  methods:
    transform: ({boughtAt,completedAt,price,priceToken,payout: totalPayout,payoutToken}) ->
      daysInProgress = @daysInProgress(boughtAt, completedAt)
      if @now < completedAt
        return 'Купили в будущем?' if @now < boughtAt
        ratio = (@now - boughtAt)/(completedAt - boughtAt)
      else
        ratio = 1
      payout = [ratio*totalPayout, totalPayout]
      {boughtAt, price, priceToken, payout, payoutToken, daysInProgress}
    daysInProgress: (boughtAt, completedAt) ->
      total = Math.round (completedAt - boughtAt)/day
      if @now < completedAt
        return 'Купили в будущем?' if @now < boughtAt
        current = Math.round (@now - boughtAt)/day
      else
        current = total
      [current,total]


