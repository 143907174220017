

















































import {mapState, mapGetters} from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'
import {repeat} from '../../store/functions.coffee'
import BigNumber from 'bignumber.js'
# import { MessageBox } from 'element-ui'
import {verboseSend} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

# window.test = checkAllowance

# window.BigNumber = BigNumber

export default
  components: {TokenIcon, Allowance, Registration}
  data: ->
    processing: false
    purchase:
      trxPrice: BigNumber(0)
      hyhoPrice: BigNumber(0)
      gain: BigNumber(0)
  computed: {
    ...mapGetters 'KingOfTheHill', [
      'minPriceTRX'
      'minPriceHYHO'
      'saleRate'
      'userAddress'
    ]
    ...mapState 'KingOfTheHill', ['contract', 'stopped']
    priceTRX:
      get: -> tronWeb.fromSun(@purchase.trxPrice.integerValue().toString())
      set: (trx) ->
        @purchase.trxPrice = BigNumber(tronWeb.toSun(trx))
        @purchase.hyhoPrice = @purchase.trxPrice.times(@minPriceHYHO).div(@minPriceTRX)
        @purchase.gain = @purchase.trxPrice.times(@saleRate).div(100)
    priceHYHO:
      get: -> tronWeb.fromSun(@purchase.hyhoPrice.integerValue().toString())
      set: (hyho) ->
        @purchase.hyhoPrice = BigNumber(tronWeb.toSun(hyho))
        @purchase.trxPrice = @purchase.hyhoPrice.times(@minPriceTRX).div(@minPriceHYHO)
        @purchase.gain = @purchase.trxPrice.times(@saleRate).div(100)
    gainHYHO:
      get: -> tronWeb.fromSun(@purchase.gain.integerValue().toString())
      set: (gain) ->
        @purchase.gain = BigNumber(tronWeb.toSun(gain))
        @purchase.trxPrice = @purchase.gain.times(100).div(@saleRate)
        @purchase.hyhoPrice = @purchase.trxPrice.times(@minPriceHYHO).div(@minPriceTRX)
    rules: ->
      trxPrice: [
        {
          trigger: 'change',
          validator: (rule, value, callback) =>
            if value.lt BigNumber(@minPriceTRX)
              callback(new Error("Минимальное значение #{tronWeb.fromSun(@minPriceTRX)} TRX"))
            else
              callback()
        }, {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            tronWeb.trx.getAccount(@userAddress).then ({balance}) =>
              if !balance or value.gt BigNumber(balance)
                callback(new Error("Недостаточно TRX на балансе"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить баланс"))
        }
      ]
      hyhoPrice: [
        {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            repeat(=> @hyhoContract.balanceOf(@userAddress).call()).then (balance) =>
              # console.info("HYHO:", tronWeb.fromSun(BigNumber(balance).toString()))
              if value.gt BigNumber(balance)
                callback(new Error("Недостаточно HYHO на балансе"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить баланс"))
        }, {
          trigger: 'change',
          validator: (rule, value, callback) =>
            if value.lt BigNumber(@minPriceHYHO)
              callback(new Error("Минимальное значение #{tronWeb.fromSun(@minPriceHYHO)} HYHO"))
            else
              callback()
        }
      ]
  }
  mounted: ->
    @hyhoContract = await @$store.dispatch('contracts/load', config.contracts.TRC20.HYHO)
    # console.info(@hyhoContract)
  methods:
    buy: ->
      @$refs.form.validate (valid) =>
        if valid
          @processing = true

          trxPrice = @purchase.trxPrice.integerValue().toString()
          hyhoPrice = @purchase.hyhoPrice.integerValue().toString()

          if await checkRegistration() and await checkAllowance(
            hyhoPrice, "HYHO"
            config.contracts.CompetitiveTokenSale
            'Разрешить контракту King of the Hill списывать с вашего адреса'
          )

            verboseSend @contract.buy(trxPrice, hyhoPrice), callValue: trxPrice, feeLimit: tronWeb.toSun(200)
            .then (msg) =>
              msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
            # @processing = false
            return

          @processing = false
        else
          return false
      return

