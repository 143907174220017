export default [{
	breakpoint: 'MobilS'
	breakpointWidth: 1
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 6, height: 10, id: 1, component: 'TotalPartners'}
		{ x: 0, y: 10, width: 6, height: 10, id: 2, component: 'Level1'}
		{ x: 0, y: 20, width: 6, height: 10, id: 3, component: 'Level2'}
		{ x: 0, y: 30, width: 6, height: 10, id: 4, component: 'Level3'}
		{ x: 0, y: 40, width: 6, height: 10, id: 5, component: 'Level4'}
		{ x: 0, y: 50, width: 6, height: 10, id: 6, component: 'Level5'}
		{ x: 0, y: 60, width: 6, height: 10, id: 7, component: 'PartnerLink'}
		{ x: 0, y: 70, width: 6, height: 10, id: 8, component: 'Upliner'}
		{ x: 0, y: 80, width: 6, height: 33, id: 9, component: 'PartnersList'}
		{ x: 0, y: 113, width: 6, height: 46, id: 10, component: 'AllowancesInfo'}
		{ x: 0, y: 159, width: 6, height: 69, id: 11, component: 'MyAllowances'}
	]
}
{
	breakpoint: 'MobilM'
	breakpointWidth: 374
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 6, height: 10, id: 1, component: 'TotalPartners'}
		{ x: 0, y: 10, width: 6, height: 10, id: 2, component: 'Level1'}
		{ x: 0, y: 20, width: 6, height: 10, id: 3, component: 'Level2'}
		{ x: 0, y: 30, width: 6, height: 10, id: 4, component: 'Level3'}
		{ x: 0, y: 40, width: 6, height: 10, id: 5, component: 'Level4'}
		{ x: 0, y: 50, width: 6, height: 10, id: 6, component: 'Level5'}
		{ x: 0, y: 60, width: 6, height: 10, id: 7, component: 'PartnerLink'}
		{ x: 0, y: 70, width: 6, height: 10, id: 8, component: 'Upliner'}
		{ x: 0, y: 80, width: 6, height: 33, id: 9, component: 'PartnersList'}
		{ x: 0, y: 113, width: 6, height: 38, id: 10, component: 'AllowancesInfo'}
		{ x: 0, y: 151, width: 6, height: 69, id: 11, component: 'MyAllowances'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 3, height: 10, id: 1, component: 'TotalPartners'}
		{ x: 3, y: 0, width: 3, height: 10, id: 2, component: 'Level1'}
		{ x: 0, y: 10, width: 3, height: 10, id: 3, component: 'Level2'}
		{ x: 3, y: 10, width: 3, height: 10, id: 4, component: 'Level3'}
		{ x: 0, y: 20, width: 3, height: 10, id: 5, component: 'Level4'}
		{ x: 3, y: 20, width: 3, height: 10, id: 6, component: 'Level5'}
		{ x: 0, y: 30, width: 6, height: 10, id: 7, component: 'PartnerLink'}
		{ x: 0, y: 40, width: 6, height: 10, id: 8, component: 'Upliner'}
		{ x: 0, y: 50, width: 6, height: 33, id: 9, component: 'PartnersList'}
		{ x: 0, y: 83, width: 6, height: 32, id: 10, component: 'AllowancesInfo'}
		{ x: 0, y: 115, width: 6, height: 69, id: 11, component: 'MyAllowances'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 767
	numberOfCols: 6
	rowHeight: 2
	items: [
		{ x: 0, y: 0, width: 2, height: 10, id: 1, component: 'TotalPartners'}
		{ x: 2, y: 0, width: 2, height: 10, id: 2, component: 'Level1'}
		{ x: 4, y: 0, width: 2, height: 10, id: 3, component: 'Level2'}
		{ x: 0, y: 10, width: 2, height: 10, id: 4, component: 'Level3'}
		{ x: 2, y: 10, width: 2, height: 10, id: 5, component: 'Level4'}
		{ x: 4, y: 10, width: 2, height: 10, id: 6, component: 'Level5'}
		{ x: 0, y: 20, width: 6, height: 10, id: 7, component: 'PartnerLink'}
		{ x: 0, y: 30, width: 6, height: 10, id: 8, component: 'Upliner'}
		{ x: 0, y: 40, width: 6, height: 33, id: 9, component: 'PartnersList'}
		{ x: 0, y: 73, width: 6, height: 20, id: 10, component: 'AllowancesInfo'}
		{ x: 0, y: 93, width: 6, height: 58, id: 11, component: 'MyAllowances'}
	]
}
{
	breakpoint: 'L2'
	breakpointWidth: 1017
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 6, height: 7, id: 1, component: 'TotalPartners'}
		{ x: 6, y: 0, width: 6, height: 7, id: 2, component: 'Level1'}
		{ x: 12, y: 0, width: 6, height: 7, id: 3, component: 'Level2'}
		{ x: 18, y: 0, width: 6, height: 7, id: 4, component: 'Level3'}
		{ x: 0, y: 7, width: 6, height: 7, id: 5, component: 'Level4'}
		{ x: 6, y: 7, width: 6, height: 7, id: 6, component: 'Level5'}
		{ x: 0, y: 14, width: 13, height: 7, id: 7, component: 'PartnerLink'}
		{ x: 12, y: 7, width: 12, height: 7, id: 8, component: 'Upliner'}
		{ x: 13, y: 14, width: 11, height: 65, id: 9, component: 'PartnersList'}
		{ x: 0, y: 21, width: 13, height: 21, id: 10, component: 'AllowancesInfo'}
		{ x: 0, y: 42, width: 13, height: 37, id: 11, component: 'MyAllowances'}
	]
}
{
	breakpoint: 'L1'
	breakpointWidth: 1298
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 4, height: 7, id: 1, component: 'TotalPartners'}
		{ x: 4, y: 0, width: 4, height: 7, id: 2, component: 'Level1'}
		{ x: 8, y: 0, width: 4, height: 7, id: 3, component: 'Level2'}
		{ x: 12, y: 0, width: 4, height: 7, id: 4, component: 'Level3'}
		{ x: 16, y: 0, width: 4, height: 7, id: 5, component: 'Level4'}
		{ x: 20, y: 0, width: 4, height: 7, id: 6, component: 'Level5'}
		{ x: 0, y: 7, width: 12, height: 7, id: 7, component: 'PartnerLink'}
		{ x: 12, y: 7, width: 12, height: 7, id: 8, component: 'Upliner'}
		{ x: 0, y: 14, width: 10, height: 59, id: 9, component: 'PartnersList'}
		{ x: 10, y: 14, width: 14, height: 16, id: 10, component: 'AllowancesInfo'}
		{ x: 10, y: 30, width: 14, height: 43, id: 11, component: 'MyAllowances'}
	]
}
]

# export default [{
# 	breakpoint: 'minimal'
# 	numberOfCols: 24
# 	rowHeight: 5
# 	items: [
# 		{ x: 0, y: 0, width: 4, height: 7, id: 1, component: 'TotalPartners'}
# 		{ x: 4, y: 0, width: 4, height: 7, id: 2, component: 'Level1'}
# 		{ x: 8, y: 0, width: 4, height: 7, id: 3, component: 'Level2'}
# 		{ x: 12, y: 0, width: 4, height: 7, id: 4, component: 'Level3'}
# 		{ x: 16, y: 0, width: 4, height: 7, id: 5, component: 'Level4'}
# 		{ x: 20, y: 0, width: 4, height: 7, id: 6, component: 'Level5'}
# 		{ x: 0, y: 7, width: 12, height: 7, id: 7, component: 'PartnerLink'}
# 		{ x: 12, y: 7, width: 12, height: 7, id: 8, component: 'Upliner'}
# 		{ x: 0, y: 14, width: 10, height: 59, id: 9, component: 'PartnersList'}
# 		{ x: 10, y: 14, width: 14, height: 16, id: 10, component: 'AllowancesInfo'}
# 		{ x: 10, y: 30, width: 14, height: 43, id: 11, component: 'MyAllowances'}
# 	]
# }
# ]


# export default [{
# 	breakpoint: 'xl'
# 	numberOfCols: 24
# 	rowHeight: 5
# 	items: [
# 		{ x: 1, y: 0, width: 4, height: 7, id: 1, component: 'TotalPartners'}
# 		{ x: 5, y: 0, width: 4, height: 7, id: 2, component: 'Level1'}
# 		{ x: 5, y: 7, width: 4, height: 7, id: 3, component: 'Level2'}
# 		{ x: 9, y: 0, width: 4, height: 7, id: 4, component: 'Level3'}
# 		{ x: 1, y: 7, width: 4, height: 7, id: 5, component: 'Level4'}
# 		{ x: 9, y: 7, width: 4, height: 7, id: 6, component: 'Level5'}
# 		{ x: 1, y: 14, width: 12, height: 7, id: 7, component: 'PartnerLink'}
# 		{ x: 1, y: 21, width: 12, height: 7, id: 8, component: 'Upliner'}
# 		{ x: 13, y: 0, width: 10, height: 28, id: 9, component: 'PartnersList'}
# 		{ x: 4, y: 28, width: 16, height: 16, id: 10, component: 'AllowancesInfo'}
# 		{ x: 4, y: 44, width: 16, height: 43, id: 11, component: 'MyAllowances'}
# 	]
# }
# ]


# export default [{
#   breakpoint: 'xl'
#   numberOfCols: 24
#   rowHeight: 5
#   items: [
#     { x: 0, y:  0, width: 1, height:  5, id:  1, component: 'TotalPartners' }
#     { x: 1, y:  0, width: 1, height:  5, id:  2, component: 'Level1' }
#     { x: 2, y:  0, width: 1, height:  5, id:  3, component: 'Level2' }
#     { x: 0, y:  7, width: 1, height:  5, id:  4, component: 'Level3' }
#     { x: 1, y:  7, width: 1, height:  5, id:  5, component: 'Level4' }
#     { x: 2, y:  7, width: 1, height:  5, id:  6, component: 'Level5' }
#     { x: 0, y: 10, width: 3, height:  7, id:  7, component: 'PartnerLink' }
#     { x: 0, y: 17, width: 3, height:  7, id:  8, component: 'Upliner' }
#     { x: 3, y:  0, width: 3, height: 24, id:  9, component: 'PartnersList' }
#     { x: 0, y: 28, width: 6, height: 12, id: 10, component: 'AllowancesInfo' }
#     { x: 0, y: 40, width: 6, height: 25, id: 11, component: 'MyAllowances' }
#   ]
# }]
# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]
