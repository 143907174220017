





















import { mapGetters } from 'vuex'

export default
  data: ->
    loading: false
    branch: 'left'
  methods:
    test: ->


