var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding: 10px; padding-top: 20px; height: calc(100% - 50px)"}},[_c('div',{staticClass:"vertical-divider"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("1000 USDT"),_c('br'),_vm._v("123 ppl")]),_c('div',{staticClass:"right"},[_vm._v("1000 USDT"),_c('br'),_vm._v("123 ppl")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.changingBranch),expression:"changingBranch"}],staticClass:"switch",attrs:{"element-loading-text":"Смена ветви заполнения","element-loading-spinner":"el-icon-loading"}},[_c('el-radio',{attrs:{"label":"left"},on:{"change":_vm.branchChange},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}},[_vm._v("Заполнять левую ветвь")]),_c('el-radio',{attrs:{"label":"right"},on:{"change":_vm.branchChange},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}},[_vm._v("Заполнять правую ветвь")])],1),_c('svg',{ref:"svg",staticStyle:{"margin-top":"50px"},attrs:{"width":"100%","height":"100%","viewBox":"0 0 108 108","preserveAspectRatio":"xMidYMin meet"}},[_c('defs',_vm._l((_vm.nodes.gradients),function(ref){
var g1x = ref.g1x;
var g1y = ref.g1y;
var g2x = ref.g2x;
var g2y = ref.g2y;
var c1 = ref.c1;
var c2 = ref.c2;
var id = ref.id;
return _c('linearGradient',{attrs:{"id":id,"x1":g1x,"x2":g2x,"y1":g1y,"y2":g2y,"gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"offset":"0%","stop-color":c1}}),_c('stop',{attrs:{"offset":"100%","stop-color":c2}})],1)}),1),_c('g',{staticClass:"tree",attrs:{"transform":"translate(54 54) scale(1 1)"}},[_vm._l((_vm.nodes.pathes),function(attrs){return _c('path',_vm._b({},'path',attrs,false))}),_vm._l((_vm.nodes.items),function(ref,n){
var circle = ref.circle;
var image = ref.image;
return _c('g',{staticClass:"node",class:circle.class,on:{"click":function($event){return _vm.onNodeClick(circle, n+1)}}},[_c('circle',_vm._b({},'circle',circle,false)),(image)?_c('image',_vm._b({},'image',image,false)):_vm._e()])})],2)]),_c('el-popover',{staticClass:"popover",style:(("left:" + (_vm.popup.x) + "px;top:" + (_vm.popup.y) + "px;width:" + (_vm.popup.width) + "px;height:" + (_vm.popup.height) + "px;")),attrs:{"placement":"bottom-end","width":"200","trigger":"manual","visible-arrow":true},model:{value:(_vm.popup.visible),callback:function ($$v) {_vm.$set(_vm.popup, "visible", $$v)},expression:"popup.visible"}},[_c('div',{staticClass:"origin",attrs:{"slot":"reference"},slot:"reference"}),_c('div',[_vm._v(_vm._s(_vm.showTooltopFor))]),_c('div',[_vm._v(_vm._s(_vm.showTooltopFor))]),_c('div',[_vm._v(_vm._s(_vm.showTooltopFor))]),_c('div',[_vm._v(_vm._s(_vm.showTooltopFor))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }