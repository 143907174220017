import { render, staticRenderFns } from "./StakingFormMobile.vue?vue&type=template&id=5e05d988&scoped=true&lang=pug&"
import script from "./StakingFormMobile.vue?vue&type=script&lang=coffee&"
export * from "./StakingFormMobile.vue?vue&type=script&lang=coffee&"
import style0 from "./StakingFormMobile.vue?vue&type=style&index=0&id=5e05d988&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e05d988",
  null
  
)

export default component.exports