









































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/Cashback.coffee"

import CashBackCard from "./Cashback/CashBackCard.vue"
import CashBackProCard from "./Cashback/CashBackProCard.vue"
import CashBackProSubscriptions from "./Cashback/CashBackProSubscriptions.vue"
import CashBackProTokensFrozen from "./Cashback/CashBackProTokensFrozen.vue"
import CashBackProTokens from "./Cashback/CashBackProTokens.vue"
import CashBackProTrons from "./Cashback/CashBackProTrons.vue"
import CashBackProTrxFrozen from "./Cashback/CashBackProTrxFrozen.vue"
import CashBackSubscriptions from "./Cashback/CashBackSubscriptions.vue"
import CashBackTokensFrozen from "./Cashback/CashBackTokensFrozen.vue"
import CashBackTokens from "./Cashback/CashBackTokens.vue"
import CashBackTrons from "./Cashback/CashBackTrons.vue"
import CashBackTrxFrozen from "./Cashback/CashBackTrxFrozen.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    CashBackCard
    CashBackProCard
    CashBackProSubscriptions
    CashBackProTokensFrozen
    CashBackProTokens
    CashBackProTrons
    CashBackProTrxFrozen
    CashBackSubscriptions
    CashBackTokensFrozen
    CashBackTokens
    CashBackTrons
    CashBackTrxFrozen
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState('Cashback', ['loading'])
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("Cashback/getContractUserState")
  }
  mounted: ->
    @$store.dispatch("Cashback/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch 'Cashback/silentReload'
      else
        @$store.dispatch("Cashback/reload")
  }
}
