

























# import BigNumber from 'bignumber.js'
import {mapState, mapGetters} from 'vuex'
import {isValidAddress} from '../store/functions.coffee'
import config from '../config.coffee'
import {repeat} from '../store/functions.coffee'

_checkRegistration = null

export checkRegistration = -> _checkRegistration(...arguments)

export default
  data: ->
    waiting: false
    parent:
      value: ""
    title: "Для продолжения требуется регистрация"
    visible: false
  computed: {
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
    rules: ->
      value: [
        trigger: 'change'
        validator: (rule, value, callback) =>
          if value.length > 0 and isValidAddress(value)
            if await repeat(=> @contract.isRegistered(value).call())
              callback()
            else
              callback(new Error("Данный адрес не зарегистрирован"))
          else
            callback(new Error("Неправильный адрес Tron"))
      ]
  }
  mounted: ->
    @contract = await @$store.dispatch('contracts/load', config.contracts.ReferralTree)
    @parent.value = @$store.state.referrer if @$store.state.referrer
    _checkRegistration = =>
      console.info 'checkRegistration'
      @parent.value = @$store.state.referrer
      isRegistered = await @contract.isRegistered(@userAddress).call()
      new Promise (resolve, reject) =>
        @resolve = resolve; @reject = reject
        if isRegistered
          resolve(true)
        else
          @visible = true
  methods:
    register: ->
      @waiting = true
      @$refs.form.validate (valid) =>
        if valid
          @contract.register(@parent.value).send().then => @resolve(true)
          .catch (e) =>
            console.error(e)
            @resolve(false)
            # @reject(e)
          .finally =>
            @waiting = false
            @visible = false
        @waiting = false
    cancel: ->
      @visible = false
      @resolve(false)


