













import Summary from './KingOfTheHill/Summary.vue'
import Actions from './KingOfTheHill/Actions.vue'
import Events from './KingOfTheHill/Events.vue'
import Ownership from './KingOfTheHill/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
