




























import {mapState, mapGetters} from 'vuex'
# import BigNumber from 'bignumber.js'
import PerfectScrollbar from 'perfect-scrollbar';

import {verboseSend} from '../../store/functions.coffee'

export default
  computed: {
    ...mapGetters 'Exchange', [
      'orders'
      'currentPair'
      'userAddress'
    ]
    ...mapState 'Exchange', ['contract']
  }
  methods:
    cancel: (id, type) ->
      verboseSend (if type == "sell" then @contract.cancelSellOrder(id) else @contract.cancelBuyOrder(id))
      .then (msg) =>
        @$emit 'reload', silent: true
  mounted: ->
    @ps = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[0]
  beforeDestroy: ->
    @ps.destroy()
    @ps = null
