




































































































































import {mapState, mapGetters} from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'
import BigNumber from 'bignumber.js'
# import { MessageBox } from 'element-ui'
import {verboseSend, repeat, roundBy} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
# import Registration, { checkRegistration } from '../../components/Registration.vue'

# window.BigNumber = BigNumber

validateMinMax = (min, max, cur, ctx) -> {
  trigger: 'change',
  validator: (rule, value, callback) =>
    if ctx.toSun(value).lt(ctx[min])
      callback(text: "Минимум #{ctx.fromSun(ctx[min])} #{ctx.currentPair[cur]}", value: ctx.fromSun(ctx[min]))
    else if ctx.toSun(value).gt(ctx[max])
      callback(text: "Максимум #{ctx.fromSun(ctx[max])} #{ctx.currentPair[cur]}", value: ctx.fromSun(ctx[max]))
    else
      callback()
}

validateMinMaxPrice = (min, max, cur, ctx) -> {
  trigger: 'change',
  validator: (rule, value, callback) =>
    if ctx.toPrice(value).lt(ctx[min])
      callback(text: "Минимум #{ctx.fromPrice(ctx[min])} #{ctx.currentPair[cur]}", value: ctx.fromPrice(ctx[min]))
    else if ctx.toPrice(value).gt(ctx[max])
      callback(text: "Максимум #{ctx.fromPrice(ctx[max])} #{ctx.currentPair[cur]}", value: ctx.fromPrice(ctx[max]))
    else
      callback()
}


export default
  components: {TokenIcon, Allowance}
  data: ->
    tab: "limit"
    processing: false
    limit:
      price: BigNumber(1)
      volume: BigNumber(0)
    market:
      volume: BigNumber(0)
      total: BigNumber(0)
      ids: []
      orders: 0
      lockSlider: false
      full: true
      safe: false
    inputString:
      limitVolume: null
      limitTotal: null
      limitPrice: null
      marketVolume: null
      marketTotal: null
  computed: {
    ...mapGetters 'Exchange', [
      'currentPair'
      'userAddress'
      'priceDivisor'
      'sellPrice'
      'maxBid'
      'currency'
      'maxBaseAsset'
      'minBaseAsset'
      'maxQuoteValue'
      'minQuoteValue'
      'bids'
      'orderBook'
      'buyIds'
    ]
    ...mapState 'Exchange', ['contract']
    priceDenominator: -> @priceDivisor
    limitVolume:
      get: -> @fromSun @limit.volume
      set: (volume) ->
        @limit.volume = roundBy @toSun(volume), @currency.base.unit
    limitPrice:
      get: -> @fromPrice @limit.price
      set: (price) ->
        @limit.price = (@toPrice price).integerValue()
        @limit.price = @limit.price.plus(1) if @limit.price.isZero()
    limitTotal:
      # get: -> @fromSun if @limit.price.isZero() then BigNumber(0) else @limit.total.times(@limit.price).div(@priceDenominator).integerValue()
      get: -> @fromSun roundBy(@limit.volume.times(@limit.price).div(@priceDivisor), @currency.quote.unit)
      set: (total) ->
        total = @toSun total
        volume = total.times(@priceDenominator).idiv(@limit.price)

        loop
          break if volume.times(@limit.price).idiv(@priceDenominator).eq total
          if volume.times(@limit.price).idiv(@priceDenominator).gt total
            volume = volume.minus(BigNumber(1))
            break
          else
            volume = volume.plus(BigNumber(1))
        @limit.volume = volume
    marketVolume:
      get: -> @fromSun @market.volume
      set: (volume) ->
        @market.volume = roundBy @toSun(volume), @currency.base.unit
        volume = @market.volume
        total = BigNumber(0)
        @market.ids = []
        @market.full = true
        for {price, orders} in @bids
          for {value: vol, asset: tot, id} in orders
            if volume.gte(vol)
              volume = volume.minus(vol)
              total = total.plus(tot)
              @market.ids.push id
            else
              total = total.plus(volume.times(price)).integerValue(BigNumber.ROUND_FLOOR)
              volume = BigNumber(0)
              @market.ids.push id
              @market.full = false
              break
          break if volume.isZero()
        @market.orders = @market.ids.length
        @market.total = total
    marketTotal:
      get: -> @fromSun @market.total
      set: (total) ->
        @market.total = roundBy @toSun(total), @currency.quote.unit
        total = @market.total
        volume = BigNumber(0)
        @market.ids = []
        @market.full = true
        for {price, orders} in @bids
          for {value: vol, asset: tot, id} in orders
            if total.gte(tot)
              total = total.minus(tot)
              volume = volume.plus(vol)
              @market.ids.push id
            else
              volume = volume.plus(total.div(price)).integerValue(BigNumber.ROUND_FLOOR)
              total = BigNumber(0)
              @market.ids.push id
              @market.full = false
              break
          break if total.isZero()
        @market.orders = @market.ids.length
        @market.volume = volume
    sliderMarks: ->
      marks = {}
      marks[0] = '0'
      marks[@orders.length] = @orders.length.toString()
      marks
    orders: -> Object.values(@orderBook.buy)
    minMarketVolume: -> @currency.base.unit
    minMarketTotal: -> @currency.base.unit
    maxMarketVolume: -> @orders.map(({value}) -> value).reduce(((a,b) -> a.plus(b)), BigNumber(0))
    maxMarketTotal: -> @orders.map(({asset}) -> asset).reduce(((a,b) -> a.plus(b)), BigNumber(0))
    maxLimitVolume: -> @maxBaseAsset
    minLimitVolume: -> @minBaseAsset
    maxLimitTotal: -> @maxQuoteValue
    minLimitTotal: -> @minQuoteValue
    maxPrice: -> BigNumber(@sellPrice.max)
    minPrice: ->
      if @maxBid.isZero() or @sellPrice.min.gt(@maxBid)
        @sellPrice.min
      else
        @maxBid.plus(1)
    stepPrice: -> BigNumber(1).div(@priceDivisor)
    stepBase: -> @currency.base.unit
    stepQuote: -> @currency.quote.unit

    rules: ->
      limitPrice: [
        validateMinMaxPrice('minPrice', 'maxPrice', 1, this)
      ]
      limitVolume: [
        validateMinMax('minLimitVolume', 'maxLimitVolume', 0, this)
      ]
      limitTotal: [
        validateMinMax('minLimitTotal', 'maxLimitTotal', 1, this)
      ]
      marketVolume: [
        validateMinMax('minMarketVolume', 'maxMarketVolume', 0, this)
      ]
      marketTotal: [
        validateMinMax('minMarketTotal', 'maxMarketTotal', 1, this)
      ]

  }
  mounted: ->
    # window.el = @$el.getElementsByTagName('input')[0]
    for el in @$el.getElementsByTagName('input')
      el.addEventListener "keydown", (event) ->
        event.preventDefault() if event.key == '-' or event.key == '+'

    @setPrice()

    # @hyhoContract = await @$store.dispatch('contracts/load', config.contracts.TRC20.HYHO)
    # console.info(@hyhoContract)

    # window.sell = @marketSell_
  methods:
    blur: (field) ->
      @market.lockSlider = false
      @inputString[field] = null
    input: (field, val) ->
      @market.lockSlider = true
      @inputString[field] = val
      this[field] = val if !!val
    fromSun: (value) -> +tronWeb.fromSun value
    toSun: (value) -> BigNumber tronWeb.toSun value
    fromPrice: (value) -> value.div(@priceDenominator).toNumber()
    toPrice: (value) -> BigNumber(value).times(@priceDenominator)
    setPrice: ->
      @limit.price = @minPrice
    limitSell: ->
      @$refs.limitSell.validate (valid) =>
        if valid
          @processing = true


          price = @limit.price.toString()
          amount = @limit.volume.toString()
          total = (@limit.volume.times(@limit.price).div(@priceDenominator)).integerValue().toString()

          if @currentPair[0] == 'TRX'
            verboseSend @contract.limitSell(0, total), callValue: amount
            .then (msg) =>
              msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
          else
            if await checkAllowance(amount, @currentPair[0], @contract.address
              'Разрешить контракту биржи списывать с вашего адреса'
            )
              verboseSend @contract.limitSell(amount, total)
              .then (msg) =>
                msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
                msg.duration = 3000
                @$emit 'reload', silent: true
              .finally =>
                @processing = false
        else
          return false
      return
    marketSell: ->
      @$refs.marketSell.validate (valid) =>
        if valid
          @processing = true


          feeLimit = 500
          ids = @buyIds
          volume = @market.volume.toString()
          console.info volume, ids, ids.length
          if @currentPair[0] == 'TRX'
            verboseSend @contract.marketSell(volume, ids), callValue: volume, feeLimit: tronWeb.toSun(feeLimit)
            .then (msg) =>
              msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
          else
            if await checkAllowance(volume, @currentPair[0], @contract.address
              'Разрешить контракту биржи списывать с вашего адреса'
            )
              verboseSend @contract.marketSell(volume, ids), feeLimit: tronWeb.toSun(feeLimit)
              .then (msg) =>
                msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
                msg.duration = 3000
                @$emit 'reload', silent: true
              .finally =>
                @processing = false

        else
          return false
      return
    takeOrders: (n) ->
      unless @market.lockSlider
        @market.orders = n
        total = BigNumber(0)
        volume = BigNumber(0)
        @market.ids = []
        @market.full = true
        if n > 0
          for {price, orders} in @bids
            for {value: vol, asset: tot, id} in orders
              total = total.plus(tot)
              volume = volume.plus(vol)
              @market.ids.push id
              n -= 1;
              break if n == 0
            break if n == 0
        @market.total = total
        @market.volume = volume
      else
        @market.orders = n

