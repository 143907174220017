














import {mapState, mapGetters} from 'vuex'
import icon from '../../components/icons/handshake.vue'
import { Message } from 'element-ui'

export default
  components: {icon}
  computed: mapGetters "Cabinet", ["refLink"]
  methods:
    copyToClipboard: ->
      @$clipboard @refLink
      Message message: "Ссылка скопирована в буфер обмена", type: "success", duration: 1000
