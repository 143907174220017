









































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/InvestBox.coffee"

import InvestBoxCard from "./InvestBox/Card.vue"
import InvestBoxAvailable from "./InvestBox/Available.vue"
import InvestBoxTotal from "./InvestBox/Total.vue"
import InvestBoxToken1Invested from "./InvestBox/Token1Invested.vue"
import InvestBoxToken2Invested from "./InvestBox/Token2Invested.vue"
import InvestBoxHistory from "./InvestBox/History.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    InvestBoxCard
    InvestBoxAvailable
    InvestBoxTotal
    InvestBoxToken1Invested
    InvestBoxToken2Invested
    InvestBoxHistory
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  props:
    id: String
  computed: {
    ...mapState('InvestBox', ['loading'])
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("InvestBox/getContractUserState")
    id: -> @$store.dispatch("InvestBox/load", {@id})
  }
  mounted: ->
    @$store.dispatch("InvestBox/load", {@id})

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch('InvestBox/silentReload', {@id})
      else
        @$store.dispatch("InvestBox/reload", {@id})
  }
}
