






























import {mapState, mapGetters} from 'vuex'

export default
  data: ->
    currentPage: 1
    pageSize: 5
  computed: {
    ...mapState 'Cashback', []
    ...mapGetters 'Cashback',
      items: 'cashbackSubscription'
    pageData: ->
      @items.slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
