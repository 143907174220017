




















































import {mapState, mapGetters} from 'vuex'

import prettyMilliseconds from 'pretty-ms'

import {verboseSend} from '../../store/functions.coffee'

export default
  data: ->
    loading:
      payTheDebtCashback: false
      payTheDebtCashbackPro: false
      collectCashback: false
      collectCashbackPro: false
  computed: {
    ...mapState 'Cashback', [
      'contractCashback'
      'contractCashbackPro'
      'cashbackPackageIds'
      'cashbackProPackageIds'
    ]
    ...mapGetters 'Cashback', [
      'cashbackPackages'
      'cashbackProPackages'
      'freezeTime'
      'debts'
      'availableToCollect'
      'balances'
    ]
    groups: ->
      group = (name, token, packages, ids) -> {name, token, packages, ids}
      [
        group 'Cashback', 'HYHO', @cashbackPackages, @cashbackPackageIds
        group 'Cashback Pro', 'ARS', @cashbackProPackages, @cashbackProPackageIds
      ]
  }
  filters:
    human: (t) -> prettyMilliseconds t*1000, verbose: true
  methods:
    payTheDebtCashback: ->
      @loading.payTheDebtCashback = true
      verboseSend @contractCashback.payDebts(), callValue: tronWeb.toSun(if @balances.cashback.trx < 0 then -@balances.cashback.trx else 0)
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.payTheDebtCashback = false
    payTheDebtCashbackPro: ->
      @loading.payTheDebtCashbackPro = true
      verboseSend @contractCashbackPro.payDebts(), callValue: tronWeb.toSun(if @balances.cashbackPro.trx < 0 then -@balances.cashbackPro.trx else 0)
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.payTheDebtCashbackPro = false
    collectCashback: ->
      @loading.collectCashback = true
      verboseSend @contractCashback.collect()
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.collectCashback = false
    collectCashbackPro: ->
      @loading.collectCashbackPro = true
      verboseSend @contractCashbackPro.collect()
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.collectCashbackPro = false
