export network = "mainnet"
export contracts =
  TokenMarket: "TYwyWEmZtihgUWFMMqtNuo4YAwcng6C7AM"
  Multicall: "TPjNUMPiHgwutcvGxYCxEowLb9KaPqZDtj"
  Gamber: "TP9iootzX2mopLAKAnAmzdxGSqm1V2TJmH"
  ReferralTree: "TY2LYWz8bHjPCtvr1gc1ugH7jH237kiMdW"
  ComboSwap:
    USDT: "TPPEzPZxr722WZAVfqPPKBYzgh1yDc6pMw"
    ARS: "TPPEzPZxr722WZAVfqPPKBYzgh1yDc6pMw"
    PNX: "TRyiNsUAGRR8HeYnBPjZFNBuGAqMSA1TeA"
    CLC: "TVi4GM7E8EaVqVZGkTTWUpD5sYeALx91EL"
    IGX: "TNVjXYHfF6Mbinhs8F2CpZG4ADGupPVzii"
  OrderBook:
    "HYHO/TRX": "TRpvsp9vbhmChRiaTCwSRL9B7UyDs2mZ79"
    "ARS/USDT": "TUD5uX31yKkXeSPgwCfEorFnjBqCvu5cR9"
    "PNX/USDT": "TL47PsFHi7ijtGjZWHEepVfZUY7Z65y6LD"
    "CLC/USDT": "TM3s1G1nik396JMbTx1Mp8RFCY6cwfxKvZ"
    "DEX/USDT": "TEude3P8LZJvYTQRzqQsAReQPkUkHC7o6s"
    "DEX/BST" : "TDEhxJXd9omvpfJun1LvFu88C6dzw6JFeS"
    "IGX/USDT": "TPmKRUPF3M8tji93531AYftTfCypsBsBiQ"
    "IGXT/USDT": "TSwnVupMn8VQxW8dMGFhbsZ2k77oAr7JVV"
    "GIG/USDT": "TMrXQV748mBtfgYHgqRB6fqhbMc7PSNWXx"
  MultistageSwap:
    "HYHO": "TSGnhZD3Ro6RJteaLNEaG6cajJqFzHczRD"
    "ARS": "TCtbBkgNiSUvNdKkNSecGuqwUp4D4GNiXe"
  AcceleratedStaking:
    USDT: [
      "TTt43Njcavw3EsjjgVstPJi6QHpb7otaWT"
      "TJxzkHte26nqqP7NvRen9a8Gh3RewPQqXw"
      "TUGjjQGkjw7pFmJzmQPneeduYDAALpEReT"
    ]
    ARS: [
      "TXj8UpfPcXvxWshj4v1wCgoDGURwPpvLWV"
      "TGpmyAuqgs3dEett3kfwCa8SCgRg3pD979"
      "TD9KC2qpcSJ6eRuN4NE9H4dkNKYYvBSEk9"
    ]
    PNX: [
      "TTDVN4ySBHXv5r3Gt8e6MDEgG2TW9arE5S"
      "TAdEr3qfargUYTcPFHphAHDE8G6LGGpA7M"
      "TN1tE3LTqWmJNgo7JBWbjA8yPGEeaxxeSj"
    ]
    CLC: [
      "TRVDQaK8FzUKEhpmMPMaUqd1h17XZ6KvSY"
      "TL9ayipAZjEmzySYJMGdNjKgX3hp8nyqWT"
      "TJ1kMXT3fcB9K5dNBmAxteC7oVHQyZj8UK"
    ]
    IGX: [
      "TQ8qbkJ5156pT8wpwbubfQvPsU7prnaqkA"
      "TYVqisyAvvzuLw7ewNyCBJugNwPWMJMEby"
      "TKTETrDoLZfoJh3PrfBJWQs84USocnstsG"
    ]
  CompetitiveTokenSale: "TB8d7PCgaCmTHN2dvGpD1eweoewWNUkVkj"
  InvestBox:
    ars: "TKSufaNZB6ZMjZQajVfoTPw8a2VodjPndE"
    pnx: "TT3RQoAp55Efwca7rMSRNw1n2ppfx9bhTX"
    clc: "TKmLbFYqKshCp52H1MActJQVReQKuUS59W"
  TRC20:
    USDT: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
    HYHO: "TXWigUud8ErHPKTo3ueAKTnxgdh7rSS3tm"
    ARS:  "TY1kDKr6Qo22fk9sWx2P68zLnwxUKDnJgV"
    PNX:  "TNEZySF7a3u14psR3PjWQJEXjQ88XWed8R"
    CLC:  "TVgx7WrWUBu7QNDLvRiFYaQnUKQvGYYsC3"
    TLC:  "TRbyUhtoHWGLHKTCRzfpgQy3nESuq55tCT"
    BST:  "TXh31VDgvzMevmuSPcDPveTULWpVhmUA6X"
    DEX:  "TYacaQJuWhCGnUhKocawnWXU2j1cYxxZx4"
    IGX:  "TNWK7sHv8uPC5DCU1sBToo3HqBma8siBAE"
    IGXT: "TGxDvpEHDi9GJAZgEpzfFJKvArESnk1BkY"
    GIG:  "TNSCaTE25EYzvc8Nmqgw16S9p9j2TrUiGq"