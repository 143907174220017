






























































import { mapGetters } from 'vuex'
import { formatDate } from '@/store/functions'
import TronAddress from '@/components/TronAddress'
import PerfectScrollbar from 'perfect-scrollbar'


import {randomPurchaseUnderneathItem} from '@/mockup'


day = 24*60*60*1000

export default
  components: {TronAddress}
  data: ->
    now: +new Date
    items: [1..13].map randomPurchaseUnderneathItem
    currentPage: 1
    pageSize: 5
  computed:
    pageData: ->
      [...@items].sort((a,b) -> b.number - a.number).slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
    rows: -> @items.length
  filters:
    date: formatDate 'HH:mm:ss.SSS d MMMM'
  mounted: ->
    nextSecond = => @now = +new Date
    @timer = setInterval nextSecond, 1000
    scrollArea = @$el.getElementsByClassName('el-table__body-wrapper')[0]
    if scrollArea
      @ps = new PerfectScrollbar scrollArea, scrollingThreshold: 0, useBothWheelAxes: true
      @$nextTick => @ps.update()
  beforeDestroy: ->
    @ps.destroy() if @ps
    clearInterval @timer
  methods:
    localize: (b) ->
      if b == 'left'
        'Левая'
      else if b == 'right'
        'Правая'
