













































































import { mapGetters, mapState } from 'vuex'
import {verboseSend} from '../../store/functions.coffee'
import PerfectScrollbar from 'perfect-scrollbar'

export default
  data: ->
    currentPage: 1
    pageSize: 5
    baseTokenSymbol: 'USDT'
    processing: {}
  watch:
    rows: (val, oldVal) ->
      val = 5 if val < 5
      @$el.parentElement.style.height = (val+1)*36+49+'px'
  computed: {
    ...mapGetters 'TokenMarket', {items: 'userPayouts'}
    ...mapState 'TokenMarket', ['contract']
    pageData: ->
      [...@items].sort((a,b) -> b.number - a.number).slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
    rows: -> @items.length
  }
  mounted: ->
    val = @rows
    val = 5 if val < 5
    @$el.parentElement.style.height = (val+1)*36+49+'px'
  methods:
    humanizeStatus: (status) ->
      switch status
        when "awaiting" then "В ожидании"
        when "paid" then "Выплачена"
        when "unavailable" then "Недоступна"
        when "unfreezing" then "Размораживается"
        when "unproduced" then "Не произведена"
    getPayout: (number) ->
      @processing[number] = true
      verboseSend @contract.getPayout(number)
      .then (msg) =>
        msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
        msg.duration = 3000
        setTimeout (->
            @$emit 'reload', silent: true
          ), 100

      .finally =>
        @processing[number] = false
