

















































import TronAddress from '../../components/TronAddress.vue'
import AddressIcon from '../../components/AddressIcon.vue'
# import {Message} from 'element-ui'
import {verboseSend} from '../../store/functions.coffee'

import { mapGetters, mapState } from 'vuex'

export default
  components: {TronAddress, AddressIcon}
  data: ->
    currentPage: 1
    pageSize: 5
  computed: {
    ...mapGetters 'Tron', {items: 'personalQueue'}
    ...mapState 'Tron', ['contract']
    pageData: ->
      @items.slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
  methods:
    leaveQueue: (number) ->
      verboseSend @contract.withdraw(number), feeLimit: 200000000
      .then ({message}) =>
        message ='Транзакция отправлена. Информация на странице обновиться через несколько секунд'
        # setTimeout (=> @$store.dispatch 'Tron/reloadPersonalQueue'), 5000
        @$emit 'reload', 3000
      .catch (e) -> Message type: 'error', message: e
