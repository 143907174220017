



































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/StakingNoSwap.coffee"

import MyReports from "./Staking/MyReports.vue"
import PartnerPurchases from "./Staking/PartnerPurchases.vue"
import Profit from "./Staking/Profit.vue"
import StakingBasicForm from "./Staking/StakingBasicForm.vue"
import StakingLightForm from "./Staking/StakingLightForm.vue"
import StakingProForm from "./Staking/StakingProForm.vue"
import StakingFormMobile from "./Staking/StakingFormMobile.vue"
import SwapForm from "./Staking/SwapForm.vue"
import SwapHistory from "./Staking/SwapHistory.vue"
import TokensGained from "./Staking/TokensGained.vue"
import TokensSend from "./Staking/TokensSend.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    MyReports
    PartnerPurchases
    Profit
    StakingBasicForm
    StakingLightForm
    StakingProForm
    SwapForm
    SwapHistory
    TokensGained
    TokensSend
    StakingFormMobile
  }
  props:
    token: String
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState 'Staking', stakingLoading: 'loading'
    ...mapState 'Cabinet', cabinetLoading: 'loading'
    loading: -> @stakingLoading or @cabinetLoading
    ...WYSIWYG.computed
    ...mapState 'TronLink', userAddress: (state) -> state.account.address
  }
  watch: {
    userAddress: -> @$store.dispatch("Staking/getContractUserState")
    token: -> @$store.dispatch("Staking/load", {@token})
  }
  mounted: ->
    @$store.dispatch("Staking/load", {@token})
    @$store.dispatch("Cabinet/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    reload: (options) ->
      if options?.silent
        @$store.dispatch('Staking/silentReload', {@token})
      else
        @$store.dispatch("Staking/reload", {@token})
  }
}
