var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding: 0; padding-top: 0px; height: calc(100% - 40px)"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Покупки в нижестоящих ячейках")])]),_c('div',{staticStyle:{"min-width":"519px"}},[_c('el-table',{attrs:{"data":_vm.pageData,"stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"label":"Дата","align":"center","width":"135"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var boughtAt = ref.row.boughtAt;
return [_vm._v(_vm._s((new Date(boughtAt)).toLocaleString()))]}}])}),_c('el-table-column',{attrs:{"label":"Адрес","header-align":"center","align":"right","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var address = ref.row.address;
return [_c('tron-address',{attrs:{"value":address,"compact":""}})]}}])}),_c('el-table-column',{attrs:{"label":"Стоимость","header-align":"center","align":"right","min-width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var price = ref_row.price;
var priceToken = ref_row.priceToken;
return [_c('nobr',[_vm._v(_vm._s(price[0].toLocaleString('ru-RU'))+" "+_vm._s(priceToken)+" + "+_vm._s(price[1].toLocaleString('ru-RU'))+" USDT")])]}}])}),_c('el-table-column',{attrs:{"label":"Ветвь","align":"center","min-width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var branch = ref.row.branch;
return [_vm._v(_vm._s(_vm.localize(branch)))]}}])}),_c('el-table-column',{attrs:{"label":"Ваш бонус","header-align":"center","align":"right","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var bonus = ref.row.bonus;
return [_vm._v(_vm._s(bonus.toLocaleString('ru-RU'))+" USDT")]}}])})],1)],1),(_vm.items.length > 0)?_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }