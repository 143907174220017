













import Summary from './TokenMarket/Summary.vue'
import Actions from './TokenMarket/Actions.vue'
import Events from './TokenMarket/Events.vue'
import Ownership from './TokenMarket/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
  # mounted: ->
  #   console.info Summary
