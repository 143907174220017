export default [{
# 	breakpoint: 'minimal'
# 	breakpointWidth: 1
# 	numberOfCols: 24
# 	rowHeight: 1
# 	items: [
# 		{ x: 0, y: 0, width: 24, height: 52, id: 1, component: 'TokenMarketCard'}
# 		{ x: 0, y: 52, width: 24, height: 9, id: 2, component: 'TokenMarketAvailable'}
# 		{ x: 0, y: 61, width: 24, height: 9, id: 3, component: 'TokenMarketFrozen'}
# 		{ x: 0, y: 70, width: 24, height: 12, id: 4, component: 'TokenMarketPayoutProgress'}
# 		{ x: 0, y: 82, width: 24, height: 12, id: 5, component: 'TokenMarketUserPayoutProgress'}
# 		{ x: 0, y: 94, width: 24, height: 31, id: 6, component: 'TokenMarketPartnerPurchases'}
# 		{ x: 0, y: 125, width: 24, height: 37, id: 7, component: 'TokenMarketHistory'}
# 	]
# }
# {
	breakpoint: 'e'
	breakpointWidth: 341
	numberOfCols: 24
	rowHeight: 1
	items: [
		{ x: 0, y: 0, width: 24, height: 52, id: 1, component: 'TokenMarketCard'}
		{ x: 0, y: 52, width: 24, height: 9, id: 2, component: 'TokenMarketAvailable'}
		{ x: 0, y: 61, width: 24, height: 9, id: 3, component: 'TokenMarketFrozen'}
		{ x: 0, y: 70, width: 24, height: 12, id: 4, component: 'TokenMarketPayoutProgress'}
		{ x: 0, y: 82, width: 24, height: 12, id: 5, component: 'TokenMarketUserPayoutProgress'}
		{ x: 0, y: 94, width: 24, height: 31, id: 6, component: 'TokenMarketPartnerPurchases'}
		{ x: 0, y: 125, width: 24, height: 26, id: 7, component: 'TokenMarketHistory'}
	]
}
{
	breakpoint: 'c'
	breakpointWidth: 543
	numberOfCols: 24
	rowHeight: 1
	items: [
		{ x: 0, y: 0, width: 24, height: 52, id: 1, component: 'TokenMarketCard'}
		{ x: 0, y: 52, width: 12, height: 9, id: 2, component: 'TokenMarketAvailable'}
		{ x: 12, y: 52, width: 12, height: 9, id: 3, component: 'TokenMarketFrozen'}
		{ x: 0, y: 61, width: 24, height: 12, id: 4, component: 'TokenMarketPayoutProgress'}
		{ x: 0, y: 73, width: 24, height: 12, id: 5, component: 'TokenMarketUserPayoutProgress'}
		{ x: 0, y: 85, width: 24, height: 31, id: 6, component: 'TokenMarketPartnerPurchases'}
		{ x: 0, y: 116, width: 24, height: 26, id: 7, component: 'TokenMarketHistory'}
	]
}
{
	breakpoint: 'b'
	breakpointWidth: 946
	numberOfCols: 24
	rowHeight: 1
	items: [
		{ x: 0, y: 0, width: 14, height: 52, id: 1, component: 'TokenMarketCard'}
		{ x: 14, y: 9, width: 10, height: 9, id: 2, component: 'TokenMarketAvailable'}
		{ x: 14, y: 0, width: 10, height: 9, id: 3, component: 'TokenMarketFrozen'}
		{ x: 14, y: 18, width: 10, height: 12, id: 4, component: 'TokenMarketPayoutProgress'}
		{ x: 14, y: 30, width: 10, height: 12, id: 5, component: 'TokenMarketUserPayoutProgress'}
		{ x: 0, y: 52, width: 24, height: 31, id: 6, component: 'TokenMarketPartnerPurchases'}
		{ x: 0, y: 83, width: 24, height: 26, id: 7, component: 'TokenMarketHistory'}
	]
}
{
	breakpoint: 'a'
	breakpointWidth: 1001
	numberOfCols: 24
	rowHeight: 1
	items: [
		{ x: 0, y: 0, width: 8, height: 52, id: 1, component: 'TokenMarketCard'}
		{ x: 8, y: 0, width: 8, height: 9, id: 2, component: 'TokenMarketAvailable'}
		{ x: 16, y: 0, width: 8, height: 9, id: 3, component: 'TokenMarketFrozen'}
		{ x: 8, y: 9, width: 8, height: 12, id: 4, component: 'TokenMarketPayoutProgress'}
		{ x: 16, y: 9, width: 8, height: 12, id: 5, component: 'TokenMarketUserPayoutProgress'}
		{ x: 8, y: 21, width: 16, height: 31, id: 6, component: 'TokenMarketPartnerPurchases'}
		{ x: 0, y: 52, width: 24, height: 26, id: 7, component: 'TokenMarketHistory'}
	]
}
]