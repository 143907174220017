






















































# import TronAddress from '../../components/TronAddress.vue'
# import AddressIcon from '../../components/AddressIcon.vue'

import { mapGetters, mapState } from 'vuex'

export default
  # components: {TronAddress, AddressIcon}
  data: ->
    currentPage: 1
    pageSize: 5
  computed: {
    ...mapGetters 'InvestBox', {items: 'purchases'}
    # ...mapGetters 'InvestBox', ['currentToken']
    ...mapState 'InvestBox',
      price1Token: (s) -> s.IN1_tokenInfo.symbol
      price2Token: (s) -> s.IN2_tokenInfo.symbol
      payoutToken: (s) -> s.OUT_tokenInfo.symbol
    pageData: ->
      [...@items].sort((a,b) -> b.timestamp - a.timestamp).slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
