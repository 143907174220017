




































import StakingLightForm from './StakingLightForm.vue'
import StakingBasicForm from './StakingBasicForm.vue'
import StakingProForm from './StakingProForm.vue'

import { Slider, SliderItem } from 'vue-easy-slider'

# import { Swiper, SwiperSlide } from 'swiper/vue'
# import 'swiper/swiper.scss'


export default
  components: {
    Slider, SliderItem
    StakingLightForm
    StakingBasicForm
    StakingProForm
  }
  data: -> {}
  computed: { }
  mounted: ->
  methods: {}

