






export default
  props:
    color: String
