import Vue from 'vue'
import Vuex from 'vuex'
import config from "./config.coffee"

Vue.use Vuex

import TronLink from './store/TronLink.coffee'
import contracts from './store/contracts.coffee'
import Tron from './store/Tron.coffee'
import KingOfTheHill from './store/KingOfTheHill.coffee'
import Cashback from './store/Cashback.coffee'
import Staking from './store/Staking.coffee'
import Exchange from './store/Exchange.coffee'
import InvestBox from './store/InvestBox.coffee'
import TokenMarket from './store/TokenMarket.coffee'
import Binar from './store/Binar.coffee'
import Cabinet from './store/Cabinet.coffee'

import {isValidAddress, mutate} from './store/functions.coffee'

LOCALSTORAGE_VERSION = '2021-12-09'

$store = new Vuex.Store
  modules: {
    TronLink
    contracts
    Tron
    KingOfTheHill
    Cashback
    Staking
    Exchange
    InvestBox
    TokenMarket
    Binar
    Cabinet
  }
  state:
    referrer: null
  mutations:
    referrer: mutate('referrer')
  actions:
    initialize: ({dispatch}) ->
      if localStorage.version != LOCALSTORAGE_VERSION
        localStorage.clear()
        localStorage.version = LOCALSTORAGE_VERSION
      await dispatch 'TronLink/initialize'
    getReferrerAddress: ({commit}) ->
      return unless location.search.length > 0
      addr = location.search.slice(1,100)
      if isValidAddress(addr)
        commit 'referrer', addr
      # state.
      # console.log state

export watch = $store.watch

export default $store