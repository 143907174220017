





















































































































import { mapGetters } from 'vuex'

LL = (n) -> 2*n
RR = (n) -> 2*n + 1

ANIMALS = [
  'alligator', 'anteater', 'armadillo', 'auroch', 'badger', 'bat', 'beaver', 'buffalo', 'camel', 'capybara',
  'chameleon', 'cheetah', 'chinchilla', 'chipmunk', 'chupacabra', 'cormorant', 'coyote', 'crow', 'dingo', 'dolphin',
  'duck', 'elephant', 'ferret', 'fox', 'giraffe', 'gopher', 'grizzly', 'hippo', 'hyena', 'ibex',
  'iguana', 'jackal', 'kangaroo', 'koala', 'kraken', 'lemur', 'leopard', 'liger', 'llama', 'manatee',
  'mink', 'monkey', 'moose', 'narwhal', 'orangutan', 'otter', 'panda', 'penguin', 'platypus', 'python',
  'quagga', 'rabbit', 'raccoon', 'rhino', 'sheep', 'shrew', 'skunk', 'squirrel', 'tiger', 'turtle',
  'walrus', 'wolf', 'wolverine', 'wombat'
]

COLORS = [
  '721acb', '841acb', '931acb', 'a51acb', 'b41acb', 'c51acb', 'cb1abf', 'cb1ab1', 'cb1a9f', 'cb1a8d',
  'cb1a7e', 'cb1a6c', 'cb1a5e', 'cb1a4c', 'cb1a3a', 'cb1a2b', 'cb1a1a', 'cb2b1a', 'cb3a1a', 'cb4c1a',
  'cb5e1a', 'cb6c1a', 'cb7e1a', 'cb8d1a', 'cb9f1a', 'cbb11a', 'cbbf1a', 'c5cb1a', 'b4cb1a', 'a5cb1a',
  '93cb1a', '84cb1a', '72cb1a', '61cb1a', '52cb1a', '40cb1a', '31cb1a', '1fcb1a', '1acb25', '1acb34',
  '1acb46', '1acb58', '1acb67', '1acb78', '1acb87', '1acb99', '1acbab', '1acbb9', '1acbcb', '1ab9cb',
  '1aabcb', '1a99cb', '1a87cb', '1a78cb', '1a67cb', '1a58cb', '1a46cb', '1a34cb', '1a25cb', '1f1acb',
  '311acb', '401acb', '521acb', '611acb'
]

import bs58 from 'bs58'

number = (address) -> parseInt(bs58.decode(address).toString('hex').slice(0,42).slice(-3), 16)
animalNumber = (address) -> number(address) % 64
colorNumber = (address) -> Math.floor(number(address) / 64)
animal = (address) -> ANIMALS[animalNumber(address)]
color = (address) -> '#'+COLORS[colorNumber(address)]
radius = 4.2

rotate = (x, y, alpha) -> [
  x*Math.cos(alpha) + y*Math.sin(alpha)
  y*Math.cos(alpha) - x*Math.sin(alpha)
]

isLeft = (n) -> n = Math.floor(n/2) while n > 3; n == 2
deriveBranch = (n) -> if isLeft(n) then 'left' else 'right'
popupPlacement = (n) -> if isLeft(n) then 'right' else 'left'


import {randomBinarStructure} from '@/mockup.coffee'

export default
  data: ->
    branch: 'left'
    changingBranch: false
    popup:
      x: 0
      y: 0
      width: 0
      height: 0
      visible: false
    nodeAddresses: randomBinarStructure(Math.ceil(63*Math.random()))
    currentNode: 1
    radii: [0, 11, 20, 29, 39, 50]
    showTooltopFor: Number
  computed:
    nodes: ->
      gen = (node) ->
        {num, l, phi} = node
        dphi = 2*Math.PI / Math.pow(2,l+1)
        [
          node
          ...(([
            ...gen(num: LL(num), l: l+1, phi: phi + dphi/2)
            ...gen(num: RR(num), l: l+1, phi: phi - dphi/2)
          ] if l < 5) or [])
        ]
      points = {}
      for {num, l, phi} in gen(num: @currentNode, l: 0, phi: Math.PI/2)
        points[num] = {
          num, l, phi,
          cx: @radii[l]*Math.cos(phi)
          cy: @radii[l]*Math.sin(phi)
          # root: num == @currentNode
          empty: !@nodeAddresses[num]
        }
      items = Object.values(points).map ({num, cx, cy, empty}) =>
        fill = color(@nodeAddresses[num]) if @nodeAddresses[num]
        class_ = switch
          when num == @currentNode then "root"
          when @nodeAddresses[num] then "node"
          else "empty"
        result = {}
        result["circle"] = {cx, cy, fill, class: class_}
        result["image"] = {
          x: cx - radius
          y: cy - radius
          width: radius*2
          height: radius*2
          href: "/identicons/#{animal(@nodeAddresses[num])}.png"
        } if !empty
        result
      # images = Object.values(points).filter(({empty}) => !empty).map ({cx, cy, num}) =>
      #   x: cx - radius
      #   y: cy - radius
      #   width: radius*2
      #   height: radius*2
      #   href: "/identicons/#{animal(@nodeAddresses[num])}.png"
      r = radius
      ds = (0.4*r for x in [1..5])
      pathes = []
      gradients = []
      for [parent, child] in [1..31].map((n) -> [[n, 2*n], [n, 2*n+1]]).flat()
        {cx: p1x, cy: p1y} = points[parent]
        {cx: p2x, cy: p2y} = points[child]
        d = ds[points[parent].l]
        l = Math.sqrt(Math.pow(p2x-p1x, 2) + Math.pow(p2y-p1y, 2))
        R = (4*r*r - l*l - 4*d*d)/(8*(d-r))
        g1x = p1x + r*(p2x-p1x) / l; g1y = p1y + r*(p2y-p1y) / l;
        g2x = p2x + r*(p1x-p2x) / l; g2y = p2y + r*(p1y-p2y) / l;
        phi = Math.asin((R + d)/(R + r))
        # {x Cos[\[Alpha]] + y Sin[\[Alpha]], y Cos[\[Alpha]] - x Sin[\[Alpha]]}
        # cos_phi = Math.cos(phi); sin_phi = Math.sin(phi);
        cos_phi = (l/2)/(R+r); sin_phi = (R + d)/(R + r);
        s11x = p1x + (g1x - p1x)*cos_phi + (g1y - p1y)*sin_phi
        s11y = p1y + (g1y - p1y)*cos_phi - (g1x - p1x)*sin_phi
        s21x = p1x + (g1x - p1x)*cos_phi - (g1y - p1y)*sin_phi
        s21y = p1y + (g1y - p1y)*cos_phi + (g1x - p1x)*sin_phi
        s12x = p2x + (g2x - p2x)*cos_phi + (g2y - p2y)*sin_phi
        s12y = p2y + (g2y - p2y)*cos_phi - (g2x - p2x)*sin_phi
        s22x = p2x + (g2x - p2x)*cos_phi - (g2y - p2y)*sin_phi
        s22y = p2y + (g2y - p2y)*cos_phi + (g2x - p2x)*sin_phi


        path  = "M #{s11x} #{s11y} "
        path += "A #{R} #{R} 0 0 0 #{s22x} #{s22y} "
        path += "L #{s12x} #{s12y} "
        path += "A #{R} #{R} 0 0 0 #{s21x} #{s21y}"


        if points[child].empty
          fill = "#eee"
        else
          id = "f#{parent}t#{child}"
          fill = "url(##{id})"
          c1 = color(@nodeAddresses[parent])
          c2 = color(@nodeAddresses[child])
          gradients.push({g1x,g1y,g2x,g2y,c1,c2,id})

        pathes.push({fill, d: path})

      # console.info pathes
      # console.info gradients

      {items, pathes, gradients}

    links: -> []
  mounted: ->
    # window.svg = @$refs.svg
    # window.el = @$el
    [p] = @$el.getElementsByClassName('popover')

    p.addEventListener 'click', @hide
    document.body.addEventListener 'click', @hide, true
  destroyed: ->
    document.body.removeEventListener 'click', @hide
  methods:
    # show: ->
    branchChange: (dir) ->
      # console.info dir
      @changingBranch = true
      # @branch = `dir == 'left' ? 'right' : 'left'`
      setTimeout((=> @changingBranch = false), 15000)
    hide: ->
      if @popup.visible
        @popup.width = 0
        @popup.height = 0
        @popup.visible = false
    onNodeClick: ({cx, cy, class: _class}, n) ->
      if _class != "empty"
        @showTooltopFor = n
        {clientWidth: w, clientHeight: h} = @$refs.svg
        s = Math.min(w,h)
        @popup.width = Math.round(2*radius*s/108)
        @popup.height = Math.round(2*radius*s/108)
        @popup.x = Math.round(w/2 + ((cx - radius)*s/108)) + offset.x
        @popup.y = Math.round(h/2 + ((cy - radius)*s/108)) + offset.y
        # console.info(`isLeft(n) ? 'left' : 'right'`)
        # @popup.placement = popupPlacement(n)
        if @popup.visible
          @popup.visible = false
          @$nextTick => @popup.visible = true
        else
          @popup.visible = true

# correction = {x: -radius/2, y: radius/2}
# correction = {x: radius, y: radius}
# correction = {x: 5.25, y: 5.25}
offset = {x: 12, y: 30}
correction = {x: 0, y: 0}
