











































import TokenIcon from '../../components/TokenIcon.vue'
import {mapGetters, mapState} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'

export default
  components: {TokenIcon}
  data: ->
    loading:
      startNewRound: false
      collect: false
    newRound:
      trxPrice: 100
      tokenPrice: 100
      saleRate: 240
      survivalTime: 12
      referralParts: "15% 10% 5%"
  computed: {
    newRoundArguments: ->
      trxPrice: tronWeb.toSun(@newRound.trxPrice)
      tokenPrice: tronWeb.toSun(@newRound.tokenPrice)
      saleRate: Math.round(@newRound.saleRate)
      survivalTime: @newRound.survivalTime*3600
      referralParts: @newRound.referralParts.split(' ').map((e) -> parseInt e).filter((x) -> x >= 0)
    ...mapState 'KingOfTheHill', ['contract', 'stopped']
  }
  methods: {
    startNewRound: ->
      @loading.startNewRoundButton = true
      {trxPrice, tokenPrice, saleRate, survivalTime, referralParts} = @newRoundArguments
      verboseSend @contract.startNewRound(
        trxPrice, tokenPrice, saleRate, survivalTime, referralParts
      ), feeLimit: 200000000
      .then (msg) =>
        # @$emit 'reload', silent: true
        @$store.dispatch('KingOfTheHill/silentReload')
      .finally =>
        @loading.startNewRoundButton = false
    collect: ->
      @loading.collect = true
      {trxPrice, tokenPrice, saleRate, survivalTime, referralParts} = @newRoundArguments
      verboseSend @contract.collect()
      .then (msg) =>
        # @$emit 'reload', silent: true
        # @$store.dispatch('KingOfTheHill/reload')
      .finally =>
        @loading.collect = false
  }
