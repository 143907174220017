












import {mapState, mapGetters} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'

import icon from '../../components/icons/coin-stack.vue'

export default
  components: {icon}
  computed: {
    ...mapState 'InvestBox', ['contract']
    ...mapGetters 'InvestBox', ['total']
    value: -> @total
    ...mapState 'InvestBox',
      token: (s) -> s.OUT_tokenInfo.symbol
  }
