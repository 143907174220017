export default [{
	breakpoint: 'minimal'
	breakpointWidth: 1
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 24, height: 36, id: 1, component: 'InvestBoxCard'}
		{ x: 0, y: 36, width: 24, height: 8, id: 2, component: 'InvestBoxAvailable'}
		{ x: 0, y: 44, width: 24, height: 10, id: 3, component: 'InvestBoxTotal'}
		{ x: 0, y: 54, width: 24, height: 10, id: 4, component: 'InvestBoxToken1Invested'}
		{ x: 0, y: 64, width: 24, height: 10, id: 5, component: 'InvestBoxToken2Invested'}
		{ x: 0, y: 74, width: 24, height: 29, id: 6, component: 'InvestBoxHistory'}
	]
}
{
	breakpoint: '4'
	breakpointWidth: 319
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 24, height: 36, id: 1, component: 'InvestBoxCard'}
		{ x: 0, y: 36, width: 24, height: 8, id: 2, component: 'InvestBoxAvailable'}
		{ x: 0, y: 44, width: 24, height: 10, id: 3, component: 'InvestBoxTotal'}
		{ x: 0, y: 54, width: 24, height: 10, id: 4, component: 'InvestBoxToken1Invested'}
		{ x: 0, y: 64, width: 24, height: 10, id: 5, component: 'InvestBoxToken2Invested'}
		{ x: 0, y: 74, width: 24, height: 29, id: 6, component: 'InvestBoxHistory'}
	]
}
{
	breakpoint: '3'
	breakpointWidth: 424
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 24, height: 36, id: 1, component: 'InvestBoxCard'}
		{ x: 0, y: 36, width: 24, height: 8, id: 2, component: 'InvestBoxAvailable'}
		{ x: 0, y: 44, width: 24, height: 10, id: 3, component: 'InvestBoxTotal'}
		{ x: 0, y: 54, width: 12, height: 10, id: 4, component: 'InvestBoxToken1Invested'}
		{ x: 12, y: 54, width: 12, height: 10, id: 5, component: 'InvestBoxToken2Invested'}
		{ x: 0, y: 64, width: 24, height: 29, id: 6, component: 'InvestBoxHistory'}
	]
}
{
	breakpoint: '2'
	breakpointWidth: 767
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 0, y: 0, width: 13, height: 38, id: 1, component: 'InvestBoxCard'}
		{ x: 13, y: 0, width: 11, height: 8, id: 2, component: 'InvestBoxAvailable'}
		{ x: 13, y: 8, width: 11, height: 10, id: 3, component: 'InvestBoxTotal'}
		{ x: 13, y: 18, width: 11, height: 10, id: 4, component: 'InvestBoxToken1Invested'}
		{ x: 13, y: 28, width: 11, height: 10, id: 5, component: 'InvestBoxToken2Invested'}
		{ x: 0, y: 38, width: 24, height: 29, id: 6, component: 'InvestBoxHistory'}
	]
}
{
	breakpoint: '1'
	breakpointWidth: 1023
	numberOfCols: 24
	rowHeight: 5
	items: [
		{ x: 2, y: 0, width: 8, height: 36, id: 1, component: 'InvestBoxCard'}
		{ x: 10, y: 0, width: 6, height: 18, id: 2, component: 'InvestBoxAvailable'}
		{ x: 16, y: 0, width: 6, height: 18, id: 3, component: 'InvestBoxTotal'}
		{ x: 10, y: 18, width: 6, height: 18, id: 4, component: 'InvestBoxToken1Invested'}
		{ x: 16, y: 18, width: 6, height: 18, id: 5, component: 'InvestBoxToken2Invested'}
		{ x: 2, y: 36, width: 20, height: 32, id: 6, component: 'InvestBoxHistory'}
	]
}
]