export default [{
	breakpoint: 'MobilS'
	breakpointWidth: 1
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 44, width: 12, height: 33, id: 3, component: 'StakingLightForm'}
		{ x: 0, y: 77, width: 12, height: 33, id: 4, component: 'StakingBasicForm'}
		{ x: 0, y: 110, width: 12, height: 33, id: 5, component: 'StakingProForm'}
		{ x: 0, y: 159, width: 12, height: 8, id: 6, component: 'TokensSend'}
		{ x: 0, y: 151, width: 12, height: 8, id: 7, component: 'TokensGained'}
		{ x: 0, y: 143, width: 12, height: 8, id: 8, component: 'Profit'}
		{ x: 0, y: 167, width: 12, height: 19, id: 9, component: 'MyReports'}
		{ x: 0, y: 186, width: 12, height: 23, id: 10, component: 'PartnerPurchases'}
	]
}
{
	breakpoint: 'MobilM'
	breakpointWidth: 374
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 44, width: 12, height: 33, id: 3, component: 'StakingLightForm'}
		{ x: 0, y: 77, width: 12, height: 33, id: 4, component: 'StakingBasicForm'}
		{ x: 0, y: 110, width: 12, height: 33, id: 5, component: 'StakingProForm'}
		{ x: 0, y: 157, width: 12, height: 7, id: 6, component: 'TokensSend'}
		{ x: 0, y: 150, width: 12, height: 7, id: 7, component: 'TokensGained'}
		{ x: 0, y: 143, width: 12, height: 7, id: 8, component: 'Profit'}
		{ x: 0, y: 164, width: 12, height: 19, id: 9, component: 'MyReports'}
		{ x: 0, y: 183, width: 12, height: 23, id: 10, component: 'PartnerPurchases'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 44, width: 12, height: 33, id: 3, component: 'StakingLightForm'}
		{ x: 0, y: 77, width: 12, height: 33, id: 4, component: 'StakingBasicForm'}
		{ x: 0, y: 110, width: 12, height: 33, id: 5, component: 'StakingProForm'}
		{ x: 0, y: 157, width: 12, height: 7, id: 6, component: 'TokensSend'}
		{ x: 0, y: 150, width: 12, height: 7, id: 7, component: 'TokensGained'}
		{ x: 0, y: 143, width: 12, height: 7, id: 8, component: 'Profit'}
		{ x: 0, y: 164, width: 12, height: 19, id: 9, component: 'MyReports'}
		{ x: 0, y: 183, width: 12, height: 23, id: 10, component: 'PartnerPurchases'}
	]
}
{
	breakpoint: 'S'
	breakpointWidth: 767
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 44, width: 4, height: 33, id: 3, component: 'StakingLightForm'}
		{ x: 4, y: 44, width: 4, height: 33, id: 4, component: 'StakingBasicForm'}
		{ x: 8, y: 44, width: 4, height: 33, id: 5, component: 'StakingProForm'}
		{ x: 4, y: 77, width: 4, height: 7, id: 6, component: 'TokensSend'}
		{ x: 0, y: 77, width: 4, height: 7, id: 7, component: 'TokensGained'}
		{ x: 8, y: 77, width: 4, height: 7, id: 8, component: 'Profit'}
		{ x: 0, y: 84, width: 12, height: 19, id: 9, component: 'MyReports'}
		{ x: 0, y: 103, width: 12, height: 23, id: 10, component: 'PartnerPurchases'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 1017
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 22, width: 4, height: 32, id: 3, component: 'StakingLightForm'}
		{ x: 4, y: 22, width: 4, height: 32, id: 4, component: 'StakingBasicForm'}
		{ x: 8, y: 22, width: 4, height: 32, id: 5, component: 'StakingProForm'}
		{ x: 4, y: 54, width: 4, height: 7, id: 6, component: 'TokensSend'}
		{ x: 0, y: 54, width: 4, height: 7, id: 7, component: 'TokensGained'}
		{ x: 8, y: 54, width: 4, height: 7, id: 8, component: 'Profit'}
		{ x: 0, y: 61, width: 7, height: 30, id: 9, component: 'MyReports'}
		{ x: 7, y: 61, width: 5, height: 30, id: 10, component: 'PartnerPurchases'}
	]
}
{
	breakpoint: 'L'
	breakpointWidth: 1173
	numberOfCols: 12
	rowHeight: 8
	items: [
		{ x: 0, y: 22, width: 4, height: 32, id: 3, component: 'StakingLightForm'}
		{ x: 4, y: 22, width: 4, height: 32, id: 4, component: 'StakingBasicForm'}
		{ x: 8, y: 22, width: 4, height: 32, id: 5, component: 'StakingProForm'}
		{ x: 4, y: 54, width: 4, height: 7, id: 6, component: 'TokensSend'}
		{ x: 0, y: 54, width: 4, height: 7, id: 7, component: 'TokensGained'}
		{ x: 8, y: 54, width: 4, height: 7, id: 8, component: 'Profit'}
		{ x: 0, y: 61, width: 7, height: 30, id: 9, component: 'MyReports'}
		{ x: 7, y: 61, width: 5, height: 30, id: 10, component: 'PartnerPurchases'}
	]
}
]


# export default [{
# 	breakpoint: 'minimal'
# 	numberOfCols: 12
# 	rowHeight: 8
# 	items: [
# 		{ x: 0, y: 0, width: 3, height: 22, id: 1, component: 'SwapForm'}
# 		{ x: 3, y: 0, width: 9, height: 22, id: 2, component: 'SwapHistory'}
# 		{ x: 0, y: 22, width: 4, height: 32, id: 3, component: 'StakingLightForm'}
# 		{ x: 4, y: 22, width: 4, height: 32, id: 4, component: 'StakingBasicForm'}
# 		{ x: 8, y: 22, width: 4, height: 32, id: 5, component: 'StakingProForm'}
# 		{ x: 4, y: 54, width: 4, height: 7, id: 6, component: 'TokensSend'}
# 		{ x: 0, y: 54, width: 4, height: 7, id: 7, component: 'TokensGained'}
# 		{ x: 8, y: 54, width: 4, height: 7, id: 8, component: 'Profit'}
# 		{ x: 0, y: 61, width: 7, height: 30, id: 9, component: 'MyReports'}
# 		{ x: 7, y: 61, width: 5, height: 30, id: 10, component: 'PartnerPurchases'}
# 	]
# }


# {
# 	breakpoint: 'minimal'
# 	breakpointWidth: 1
# 	numberOfCols: 1
# 	rowHeight: 8
# 	items: [
# 		{ x: 0, y: 0, width: 1, height: 22, id: 1, component: 'SwapForm'}
# 		{ x: 0, y: 22, width: 1, height: 22, id: 2, component: 'SwapHistory'}
# 		{ x: 0, y: 78, width: 1, height: 7, id: 6, component: 'TokensSend'}
# 		{ x: 0, y: 85, width: 1, height: 7, id: 7, component: 'TokensGained'}
# 		{ x: 0, y: 92, width: 1, height: 7, id: 8, component: 'Profit'}
# 		{ x: 0, y: 99, width: 1, height: 22, id: 9, component: 'MyReports'}
# 		{ x: 0, y: 121, width: 1, height: 27, id: 10, component: 'PartnerPurchases'}
# 		{ x: 0, y: 44, width: 1, height: 34, id: 11, component: 'StakingFormMobile'}
# 	]
# }
# ]
# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]
