











import { mapGetters } from 'vuex'
import icon from '../../components/icons/coin-stack.vue'

export default
  components: {icon}
  computed: mapGetters 'Tron', ['rewardsTotal']
