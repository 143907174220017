












import {mapState, mapGetters} from 'vuex'
import icon from '../../components/icons/handshake.vue'

export default
  components: {icon}
  computed: {
    # ...mapState 'Cashback', []
    ...mapGetters 'Staking', value: 'pushed'
    ...mapGetters 'Staking', ['currentToken']
  }
