# Cabinet

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'
import config from '../config.coffee'

import {address2hex, Multicall} from '../multicall.coffee'

export default
  namespaced: true
  state: =>
    initialized: false
    contract: null
    addressById: {}
    idByAddress: {}
    parentById: {}
    childrenById: {}
    rootId: 0,
    loadingAllowances: true
    allowances: []
    loading: true
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'contract'
      'addressById'
      'parentById'
      'childrenById'
      'rootId'
      'idByAddress'
      'allowances'
      'loadingAllowances'
      'loading'
    ]
    setAllowanceForToken: (state, {i, j, allowance}) ->
      state.allowances[i].tokens[j].allowance = tronWeb.fromSun(allowance.remaining || allowance)
  }
  actions:
    load: ({ state, dispatch }) ->
      await dispatch(if state.initialized then 'reload' else 'initialize')
    initialize: ({state, commit, dispatch}) ->
      commit('loading', true)
      await Promise.all [
        dispatch('getAllowances')
        dispatch('contracts/load', config.contracts.ReferralTree, root: true).then((c) =>
          commit('contract', c)
          await dispatch('getContractState')
        ).catch(console.error)
      ]
      commit('initialization')
      commit('loading', false)
    reload: ({ commit, dispatch }) ->
      commit('loading', true)
      dispatch('getAllowances')
      await dispatch('getContractState')
      commit('loading', false)
    getContractState: ({state, commit, rootState, getters}) ->
      links = (await repeat(-> state.contract.links().call())).map((pair) -> pair.map(tronWeb.address.fromHex))
      id = 1
      idByAddress = {}
      addressById = {}
      parentById = {}
      childrenById = {}
      rootId = 0

      for [child, parent] in links
        addressById[id] = child
        idByAddress[child] = id++
      for [child, parent] in links
        if idByAddress[parent]
          parentById[idByAddress[child]] = idByAddress[parent]
          childrenById[idByAddress[parent]] ||= new Set()
          childrenById[idByAddress[parent]].add idByAddress[child]
        else
          rootId = idByAddress[child]

      commit('rootId', rootId)
      commit('addressById', addressById)
      commit('idByAddress', idByAddress)
      commit('parentById', parentById)
      commit('childrenById', childrenById)
    getAllowances: ({state, commit, getters, dispatch}) ->
      commit('loadingAllowances', true)
      blankAllowances = []
      for {description, tokens, contract} in config.allowances
        row = {description, tokens: [], contract}
        for token in tokens
          row.tokens.push {token, allowance: 0}
        blankAllowances.push row

      commit('allowances', blankAllowances)

      requests = []

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      calls = []
      functionSelector = "allowance(address,address)"
      inputs = ["address", "address"]
      outputs = ["uint"]
      owner = address2hex(getters.userAddress)
      for {contract, tokens}, i in config.allowances
        spender = address2hex(contract)
        parameters = [owner, spender]
        for token, j in tokens
          contract = address2hex(config.contracts.TRC20[token])
          calls.push {contract, functionSelector, inputs, parameters, outputs}

      results = await repeat(=> @multicall(calls))
      for {contract, tokens}, i in config.allowances
        for token, j in tokens
          commit('setAllowanceForToken', {i, j, allowance: results.shift()})

          #  requests.push dispatch('getAllowanceFor', {i, j, token, contract: allowance.contract})

      # await Promise.all requests
      commit('loadingAllowances', false)

    # getAllowanceFor: ({commit, getters, dispatch}, {i,j, token, contract}) ->
    #   dispatch('contracts/load', config.contracts.TRC20[token], root: true).then (c) ->
    #     repeat(-> c.allowance(getters.userAddress, contract).call()).then (r) ->
    #       commit('setAllowanceForToken', {i, j, allowance: r})
  getters:
    userAddress: -> arguments[2].TronLink.account.address
    refLink: -> location.origin + "/?" + arguments[1].userAddress
    parent: ({idByAddress, parentById, addressById}, {userAddress}) -> addressById[parentById[idByAddress[userAddress]]]
    partners: (state, getters) ->
      result = {
        total: 0
        levels:
          1: []
          2: []
          3: []
          4: []
          5: []
      }
      userId = state.idByAddress[getters.userAddress]
      return result unless userId?
      for id1 in [...(state.childrenById[userId] || [])]
        result.total++
        result.levels[1].push(state.addressById[id1])
        for id2 in [...(state.childrenById[id1] || [])]
          result.total++
          result.levels[2].push(state.addressById[id2])
          for id3 in [...(state.childrenById[id2] || [])]
            result.total++
            result.levels[3].push(state.addressById[id3])
            for id4 in [...(state.childrenById[id3] || [])]
              result.total++
              result.levels[4].push(state.addressById[id4])
              for id5 in [...(state.childrenById[id4] || [])]
                result.total++
                result.levels[5].push(state.addressById[id5])

      return result
    addressToLevel: (state, {partners}) ->
      result = {}
      return result if partners.total == 0
      for level, list of partners.levels
        for address in list
          result[address] = level
      return result