import { render, staticRenderFns } from "./Token2Invested.vue?vue&type=template&id=c2e78524&scoped=true&lang=pug&"
import script from "./Token2Invested.vue?vue&type=script&lang=coffee&"
export * from "./Token2Invested.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e78524",
  null
  
)

export default component.exports