var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding: 0; height: 100%"}},[_c('el-tabs',{staticClass:"full-height",staticStyle:{"border":"0"},attrs:{"type":"border-card","stretch":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('el-tab-pane',{attrs:{"label":"Все сделки","name":"all"}},[_c('el-table',{attrs:{"data":_vm.trades,"stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"timestamp","label":"Время"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var timestamp = ref.row.timestamp;
return [_vm._v(_vm._s(new Date(timestamp).toLocaleTimeString()))]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"Цена"}}),_c('el-table-column',{attrs:{"prop":"volume","label":("Всего " + (_vm.currentPair[0]))}}),_c('el-table-column',{attrs:{"prop":"total","label":("Всего " + (_vm.currentPair[1]))}}),_c('el-table-column',{attrs:{"prop":"type","label":"Тип сделки"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var type = ref.row.type;
return [(type == 'sell')?[_vm._v("Продажа")]:[_vm._v("Покупка")]]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"Мои сделки","name":"my"}},[_c('el-table',{attrs:{"data":_vm.myTrades,"stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"timestamp","label":"Время"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var timestamp = ref.row.timestamp;
return [_vm._v(_vm._s(new Date(timestamp).toLocaleTimeString()))]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"Цена"}}),_c('el-table-column',{attrs:{"prop":"volume","label":("Всего " + (_vm.currentPair[0]))}}),_c('el-table-column',{attrs:{"prop":"total","label":("Всего " + (_vm.currentPair[1]))}}),_c('el-table-column',{attrs:{"prop":"type","label":"Тип сделки"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var type = ref.row.type;
return [(type == 'sell')?[_vm._v("Продажа")]:[_vm._v("Покупка")]]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }