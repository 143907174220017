




















import { mapGetters } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar';
import TronAddress from '../../components/TronAddress.vue'


export default
  components: {TronAddress}
  computed:
    mapGetters 'KingOfTheHill', ['losers']
  mounted: ->
    @ps = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[0]

  beforeDestroy: ->
    @ps.destroy()
    @ps = null
