
















































import {mapState, mapGetters} from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'
import BigNumber from 'bignumber.js'
# import { MessageBox } from 'element-ui'
import {verboseSend, repeat} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

# window.test = checkAllowance

# window.BigNumber = BigNumber

export default
  components: {TokenIcon, Allowance, Registration}
  data: ->
    processing: false
    amount: null
  computed: {
    ...mapGetters 'Staking', [
      'userAddress'
    ]
    ...mapState 'Staking', ['swap', 'contracts', 'currentToken']
    staking: -> @$store.getters['Staking/staking'].basic
    minPrice_bn: -> BigNumber tronWeb.toSun(@staking.price.min)
    maxPrice_bn: -> BigNumber tronWeb.toSun(@staking.price.max)
    rules: ->
      amount: [
        {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            repeat(=> @currentTokenContract.balanceOf(@userAddress).call()).then (balance) =>
              if BigNumber(tronWeb.toSun(value)).gt BigNumber(balance.toString())
                callback(new Error("Недостаточно #{@currentToken} на балансе"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить баланс"))
        }, {
          trigger: 'change',
          validator: (rule, value, callback) =>
            if +value < +@staking.price.min
              callback(new Error("Минимальное значение #{@staking.price.min} #{@currentToken}"))
            else if +value > +@staking.price.max
              callback(new Error("Максимальное значение #{@staking.price.max} #{@currentToken}"))
            else
              callback()
        }
      ]
  }
  mounted: ->
    await repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20[@currentToken])).then (contract) => @currentTokenContract = contract
  methods:
    push: ->
      @$refs.form.validate (valid) =>
        if valid
          @processing = true

          amount = tronWeb.toSun(@amount)

          if await checkRegistration() and await checkAllowance(
            amount, @currentToken
            @contracts.staking[1].address
            'Разрешить контракту Staking Basic списывать с вашего адреса'
          )

            verboseSend @contracts.staking[1].push(amount), feeLimit: tronWeb.toSun(200)
            .then (msg) =>
              msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
            return

          @processing = false
        else
          return false
      return

