



















import {mapState, mapGetters} from 'vuex'

import icon from '../../components/icons/gear.vue'

export default
  components: {icon}
  computed: {
    # ...mapState 'Cashback', []
    ...mapGetters 'Staking', ['myStakes']
    ...mapGetters 'Staking', ['currentToken']
    start: -> Math.min +new Date, ...@myStakes.filter((e) -> not e.complited).map (e) -> e.timer.start
    end: -> Math.max @start, ...@myStakes.filter((e) -> not e.complited).map (e) -> e.timer.end
  }
  # mounted: -> console.info @start
  methods:
    currentValue: ->
      value = 0
      for {timer: {end, start}, amount} in @myStakes
        now = +new Date
        if now > end
          value += amount.final
        else
          if now > start
            value += amount.mined + amount.leftToMine * (now - start) / (end - start)
      return Math.round(value)

