import { id as keccak256 } from 'ethers/utils/hash'
import { defaultAbiCoder } from 'ethers/utils/abi-coder'

strip0x = (str) -> str.replace /^0x/, ''
encodeParameters = (types, vals) -> defaultAbiCoder.encode(types, vals)
decodeParameters = (types, data) -> defaultAbiCoder.decode(types, data)

export address2hex = (a) -> tronWeb.address.toHex(a).replace(/^41/, '0x')
export hex2address = (h) -> tronWeb.address.fromHex h.replace(/^0x/,'41')

encodeCalls = (calls) ->
  types = [{
    name: 'data'
    type: 'tuple[]'
    components: [ { type: 'address' }, { type: 'bytes' } ]
  }]
  values = for { contract, functionSelector, signature, inputs, parameters } in calls
    signature = keccak256(functionSelector)[0..9] unless signature?
    encodedParameters = strip0x encodeParameters(
      inputs || []
      parameters || []
    )
    [contract, signature + encodedParameters]
  encodeParameters types, [values]

decodeCallReturns = (calls, returnData) ->
  for i, call of calls
    result = decodeParameters(call.outputs, returnData[i])
    result = result[0] if result.length == 1
    if call.transform?
      call.transform(result)
    else
      result

MULTICALL_CONTRACT_ABI = [{"outputs":[{"name":"returnData","type":"bytes[]"}],"inputs":[{"name":"encodedCalls","type":"bytes"}],"name":"aggregate","stateMutability":"View","type":"Function"},{"outputs":[{"name":"blockHash","type":"bytes32"}],"inputs":[{"name":"blockNumber","type":"uint256"}],"name":"getBlockHash","stateMutability":"View","type":"Function"},{"outputs":[{"name":"blockNumber","type":"uint256"}],"name":"getBlockNumber","stateMutability":"View","type":"Function"},{"outputs":[{"name":"timestamp","type":"uint256"}],"name":"getCurrentBlockTimestamp","stateMutability":"View","type":"Function"},{"outputs":[{"name":"blockHash","type":"bytes32"}],"name":"getLastBlockHash","stateMutability":"View","type":"Function"},{"outputs":[{"name":"balance","type":"uint256"}],"inputs":[{"name":"addr","type":"address"}],"name":"getTrxBalance","stateMutability":"View","type":"Function"},{"outputs":[{"name":"flags","type":"bool[]"},{"name":"returnData","type":"bytes[]"}],"inputs":[{"name":"encodedCalls","type":"bytes"}],"name":"tryAggregate","stateMutability":"View","type":"Function"}]

export Multicall = (tronWeb, multicallContract) ->
  aggregator = tronWeb.contract()
  aggregator.address = tronWeb.address.toHex multicallContract
  aggregator.deployed = true
  aggregator.loadAbi(MULTICALL_CONTRACT_ABI)
  (calls) ->
    payload = encodeCalls(calls)
    aggregator.aggregate(payload).call().then (res) ->
      returnData = res.returnData or res
      # decodeCallReturns(calls, res.returnData)
      decodeCallReturns(calls, returnData)