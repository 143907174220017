# KingOfTheHill

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
} from './functions.coffee'
import config from '../config.coffee'

import {address2hex, Multicall} from '../multicall.coffee'

export default
  namespaced: true
  state: =>
    initialized: false
    contract: null
    events: []
    bank: 0
    stopped: null
    stopTimestamp: 0
    prices: null
    referralParts: []
    saleRate: 0.0
    frozenTokens: 0
    availableTokens: 0
    prize: 0
    loading: false
    currentRound: 0
    survivalTimeInterval: 0
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'loading'
      'contract'
      'events'
      'bank'
      'stopped'
      'stopTimestamp'
      'prices'
      'referralParts'
      'saleRate'
      'frozenTokens'
      'availableTokens'
      'prize'
      'currentRound'
      'survivalTimeInterval'
    ]
  }
  actions:
    load: ({ state, dispatch }) -> dispatch(if state.initialized then 'reload' else 'initialize')
    initialize: ({state, commit, dispatch}) ->
      return if state.loading
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('contracts/load', config.contracts.CompetitiveTokenSale, root: true).then((c) =>
          commit('contract', c)
          await dispatch('getContractState')
        ).catch(console.error)
      ]
      commit('loading', false)
      commit('initialization')
    reload: ({ state, commit, dispatch }) ->
      return if state.loading
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      commit('loading', false)
    silentReload: ({dispatch}) -> Promise.all [
      dispatch('getContractEvents')
      dispatch('getContractState')
    ]
    getContractEvents: ({commit}) -> getAllContractEvents(config.contracts.CompetitiveTokenSale).then((events) => commit('events', events)).catch(console.error)
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        stopped: contract.methodInstances.isStopped
        stopTimestamp: contract.methodInstances.stopTimestamp
        prices: contract.methodInstances.prices
        referralParts: contract.methodInstances.referralParts
        saleRate: contract.methodInstances.saleRate
        bank: contract.methodInstances.bank
        currentRound: contract.methodInstances.currentRound
        survivalTimeInterval: contract.methodInstances.survivalTimeInterval
      }

      contractHexAddress = contract.address.replace(/^41/,'0x')

      for name, method of methods
        methods[name] = {
          contract: contractHexAddress
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          # parameters: []
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # return
      # Promise.all [
      #   repeat(-> contract.isStopped().call()).then commitResult 'stopped'
      #   repeat(-> contract.stopTimestamp().call()).then commitResult 'stopTimestamp'
      #   repeat(-> contract.prices().call()).then commitResult 'prices'
      #   repeat(-> contract.referralParts().call()).then commitResult 'referralParts'
      #   repeat(-> contract.saleRate().call()).then commitResult 'saleRate'
      #   repeat(-> contract.bank().call()).then commitResult 'bank'
      #   repeat(-> contract.currentRound().call()).then commitResult 'currentRound'
      #   repeat(-> contract.survivalTimeInterval().call()).then commitResult 'survivalTimeInterval'

      #   # contract.rewards().call().then (rewards) ->
      #   #   commit('rewards', rewards)
      #   #   if getters.queueItems[0]
      #   #     contract.balance(getters.queueItems[0].address).call().then (amount) -> commit('nextGameAmount', amount.toNumber()/1000000)
      #   #   else
      #   #     commit('nextGameAmount', 0)
      #   #   if getters.personalQueue[0]
      #   #     contract.balance(getters.personalQueue[0].address).call().then (amount) -> commit('nextPersonalGameAmount', amount.toNumber()/1000000)
      #   #   else
      #   #     commit('nextPersonalGameAmount', 0)
      # ]
    getContractUserState: ({state: {contract}, commit, rootState, getters: {userAddress}}) ->
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        frozenTokens: contract.methodInstances.frozenTokensOf
        availableTokens: contract.methodInstances.availableTokensOf
        prize: contract.methodInstances.prizeOf
      }

      contractHexAddress = contract.address.replace(/^41/,'0x')

      userHexAddress = address2hex userAddress

      for name, method of methods
        methods[name] = {
          contract: contractHexAddress
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          parameters: [userHexAddress]
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

      # Promise.all [
      #   repeat(-> contract.frozenTokensOf(userAddress).call()).then commitResult 'frozenTokens'
      #   repeat(-> contract.availableTokensOf(userAddress).call()).then commitResult 'availableTokens'
      #   repeat(-> contract.prizeOf(userAddress).call()).then commitResult 'prize'
      # ]
  getters:
    userAddress: -> arguments[2].TronLink.account.address
    bank: (state) -> tronWeb.fromSun(state.bank.toNumber())
    stopAt: (state) -> state.stopTimestamp.toNumber()*1000
    startAt: (state) -> (state.stopTimestamp.toNumber() - state.survivalTimeInterval.toNumber())*1000
    isStopped: (state) -> state.stopped
    currentRound: (state) -> state.currentRound.toNumber()
    lastBuyer: (state, getters) ->
      # return null if state.stopped
      list = state.events.filter((e) -> e.name == "Purchased" and +e.result.round == getters.currentRound)
      # console.info(list)
      return null unless list.length > 0
      # console.info(list)
      tronWeb.address.fromHex(list.slice(-1)[0].result.user)
    prize: (state) -> tronWeb.fromSun state.prize.toNumber()
    frozenTokens: (state) -> tronWeb.fromSun state.frozenTokens.toNumber()
    availableTokens: (state) -> tronWeb.fromSun state.availableTokens.toNumber()
    purchases: (state) -> state.events.filter((e) -> e.name == "Purchased").map ({result, timestamp}) ->
      ts: timestamp
      round: +result.round
      address: tronWeb.address.fromHex result.user
      tokenPrice: tronWeb.fromSun result.tokenPrice
      accrued: tronWeb.fromSun result.accrued
      trxPrice: tronWeb.fromSun result.trxPrice
    tokensPurchased: (_, {purchases, userAddress}) ->
      userPurchases = purchases.filter((p) -> p.address == userAddress)
      return 0 if userPurchases.length == 0
      userPurchases.map((p) -> +p.accrued).reduce((a,b) -> a + b)
    losers: (_, {purchases, currentRound}) ->
      purchases.filter((p) -> p.round == currentRound).slice(0, -1).sort((a,b) -> b.ts - a.ts ).map ({ts, address}) ->
        time: new Date(ts).toTimeString().slice(0, 8)
        address: address
    minPriceTRX: (state) -> state.prices.minTrxPrice.toNumber()
    minPriceHYHO: (state) -> state.prices.minTokenPrice.toNumber()
    saleRate: (state) -> state.saleRate.rate.toNumber()
    achievments: (state, getters) ->
      return [] if getters.currentRound < 1
      rounds = {}
      for round in [1..getters.currentRound]
        rounds[round] = {purchased: 0, won: 0, round}
      for {name, result} in state.events
        if name == "Purchased" and tronWeb.address.fromHex(result.user) == getters.userAddress
          rounds[result.round || 1].purchased += +tronWeb.fromSun(result.accrued)
        if name == "Winner" and tronWeb.address.fromHex(result.user) == getters.userAddress
          rounds[result.round || 1].won += +tronWeb.fromSun(result.amount)
      Object.values(rounds).sort (a,b) -> b.round - a.round


      # for round in [1..getters.currentRound]
      #   result.push
    winners: (state) ->
      state.events.filter((e) -> e.name == "Winner").map ({result: {round, user, amount}, timestamp}) ->
        address = tronWeb.address.fromHex user
        amount = tronWeb.fromSun amount
        {timestamp, round, address, amount}
      .sort (a,b) -> b.timestamp - a.timestamp
      # state.events.filter((e) -> e.name == "Winner").map ({result: {round, user, amount}, timestamp}) ->
      #   {timestamp, round, user, amount}