




































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/Tron.coffee"

import CompletedGames from "./Tron/CompletedGames.vue"
import GamesPlayed from "./Tron/GamesPlayed.vue"
import MainQueue from "./Tron/MainQueue.vue"
import NextRewardProgress from "./Tron/NextRewardProgress.vue"
import PartnerGames from "./Tron/PartnerGames.vue"
import Partners from "./Tron/Partners.vue"
import PersonalQueue from "./Tron/PersonalQueue.vue"
import Players from "./Tron/Players.vue"
import Rewards from "./Tron/Rewards.vue"
import YourGames from "./Tron/YourGames.vue"
import YourReward from "./Tron/YourReward.vue"
import Play from "./Tron/Play.vue"
import YourRewardProgress from "./Tron/YourRewardProgress.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    CompletedGames
    GamesPlayed
    MainQueue
    NextRewardProgress
    PartnerGames
    Partners
    PersonalQueue
    Players
    Rewards
    YourGames
    YourReward
    Play
    YourRewardProgress
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState('Tron', ['loading'])
    ...WYSIWYG.computed
  }
  mounted: ->
    @$store.dispatch("Tron/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
    # refresh: -> @$store.dispatch("Tron/reload")
    reload: (options) ->
      if options?.silent
        @$store.dispatch 'Tron/silentReload'
      else
        @$store.dispatch("Tron/reload")
    # reload: (interval=0) ->
    #   setTimeout (=> @$store.dispatch 'Tron/silentReload'), interval
  }

}
