import { render, staticRenderFns } from "./Frozen.vue?vue&type=template&id=1e973c44&scoped=true&lang=pug&"
import script from "./Frozen.vue?vue&type=script&lang=coffee&"
export * from "./Frozen.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e973c44",
  null
  
)

export default component.exports