















import { BProgress } from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'

export default
  components: {BProgress}
  computed: {
    ...mapState 'Tron', ['nextGameAmount']
    ...mapGetters 'Tron', ['nextGameTotal']
  }
