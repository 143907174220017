













import {mapState, mapGetters} from 'vuex'
# import {verboseSend} from '../../store/functions.coffee'

# import icon from '../../components/icons/coins.vue'

export default
  # components: {icon}
  data: ->
    # value: 0
    token: "USDT"
  computed: {
    # ...mapState 'InvestBox', ['contract']
    ...mapGetters 'TokenMarket', {value: 'available'}
    # value: -> @available
    # ...mapState 'InvestBox',
    #   token: (s) -> s.OUT_tokenInfo.symbol
  }
  # methods:
    # collect: ->
    #   @processing = true
    #   verboseSend @contract.takeProfit()
    #   .then (msg) =>
    #     msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
    #     msg.duration = 3000
    #     @$emit 'reload', silent: true
    #   .finally =>
    #     @processing = false
