













import Summary from './Cashback/Summary.vue'
import Actions from './Cashback/Actions.vue'
import Events from './Cashback/Events.vue'
import Ownership from './Cashback/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
