












































































































































import TokenIcon from '@/components/TokenIcon.vue'
import {mapGetters, mapState} from 'vuex'
import {
  verboseSend
  tokenSymbol
} from '@/store/functions.coffee'
import config from '@/config.coffee'
import num2fraction from 'num2fraction'

export default
  components: {TokenIcon}
  data: ->
    loading:
      addToken: false
      collect: false
      newRate: {}
      newPkg: false
    newToken:
      address: null
      rate: null
    package: {
      id: null
      extraToken: null
      payoutToken: null
    }
    newRate: {}
    personalBonuses: {}
    newPersonalBonus: {}
  watch:
    'package.id': (id) ->
      if id == 'new'
        @$set this, 'package', {id: 'new', extraToken: null, payoutToken: null}
      else
        pkg = @packages[id - 1]
        @$set @package, 'basePrice', pkg.price[0].amount
        @$set @package, 'extraPrice', pkg.price[1].amount
        @$set @package, 'extraToken', pkg.price[1].token
        @$set @package, 'payout', pkg.payoutEquivalent
        @$set @package, 'payoutToken', pkg.payoutToken
        @$set @package, 'period', pkg.period
        @$set @package, 'bonus', pkg.bonus
        # @$set this, 'package', {id: 'new'}
  computed: {
    ...mapState 'Binar', [
      'contract'
    ]
    ...mapGetters 'Binar', [
      'tokenSymbols'
      'commission'
      'rates'
      'additionalTokens'
      'personalBonusShares'
      'packages'
    ]
  }
  mounted: ->
    @package.extraToken =  @additionalTokens[0].symbol if @additionalTokens.length > 0
    @package.payoutToken = @additionalTokens[0].symbol if @additionalTokens.length > 0
  methods: {
    setRateFor: (symbol) ->
      @$set @loading.newRate, symbol, true
      [numerator, denominator] = num2fraction(@newRate[symbol]).split('/')
      verboseSend @contract.setRate(
        config.contracts.TRC20[symbol],
        numerator,
        denominator
      ), feeLimit: 200000000
      .then (msg) =>
        @$store.dispatch('Binar/silentReload')
      .finally =>
        @loading.newRate[symbol] = false
        delete @newRate[symbol]
    addToken: ->
      @loading.addToken = true
      [numerator, denominator] = num2fraction(@newToken.rate).split('/')
      verboseSend @contract.addToken(
        @newToken.address,
        numerator,
        denominator
      ), feeLimit: 200000000
      .then (msg) =>
        @$store.dispatch('Binar/silentReload')
      .finally =>
        @loading.addToken = false
    reconfigure: ->
      # @loading.reconfigure = true
      # verboseSend @contract.reconfigurePackages(
      #   tronWeb.toSun(@newCfg.basePrice || 0),
      #   tronWeb.toSun(@newCfg.extraPrice || 0),
      #   tronWeb.toSun(@newCfg.earlyPayout || 0),
      #   tronWeb.toSun(@newCfg.regularPayout || 0),
      #   Math.round((@newCfg.earlyReceiptPeriod || 0) * 24 * 3600),
      #   Math.round((@newCfg.freezePeriod || 0) * 24 * 3600),
      #   Math.round(@newCfg.referralShare || 0),
      #   @newCfg.extraToken || '410000000000000000000000000000000000000000',
      #   @newCfg.payoutToken || '410000000000000000000000000000000000000000'
      # ), feeLimit: 200000000
      # .then (msg) =>
      #   @$store.dispatch('TokenMarket/silentReload')
      # .finally =>
      #   @loading.reconfigure = false
    collect: ->
      # @loading.collect = true
      # verboseSend @contract.getCommission()
      # .then (msg) =>
      #   # @$emit 'reload', silent: true
      #   # @$store.dispatch('KingOfTheHill/reload')
      # .finally =>
      #   @loading.collect = false
    address2symbol: (addr) -> tokenSymbol[addr] or '???'
    setToken: (obj, field) ->
      console.info arguments
      (cmd) ->
        console.info obj, field, cmd
    test: () -> console.info arguments
  }
