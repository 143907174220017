
























































































































import { mapState } from 'vuex'
import TronAddress from './TronAddress.vue'
import AddressIcon from './AddressIcon.vue'

export default
  components: {TronAddress, AddressIcon}
  props:
    showControl: false
  data: ->
    activeLink: "/"
  beforeRouteEnter: (to, from, next) -> next((vm) => vm.activeLink = to.path)
  beforeRouteUpdate: (to, from, next) ->
    @activeLink = to.path
    next()
  computed: mapState 'TronLink',
    address: (state) -> state.account.address
  mounted: ->
    @activeLink = @$route.path
    # console.info(@$route)
