













import { mapGetters, mapState } from 'vuex'
import icon from '../../components/icons/coins.vue'
import {verboseSend} from '../../store/functions.coffee'

export default
  components: {icon}
  computed: {
    ...mapGetters 'KingOfTheHill', ['prize']
    ...mapState 'KingOfTheHill', ['contract']
  }
  methods:
    getPrizes: ->
      verboseSend @contract.getPrizes()
      .then (msg) =>
        msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
        msg.duration = 3000
        @$emit 'reload', silent: true
      .finally =>
        @processing = false
