var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"full-height",attrs:{"body-style":"padding-top: 0px"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("История моих обменов")])]),_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"prop":"timestamp","label":"Дата","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var timestamp = ref.row.timestamp;
return [_vm._v(_vm._s(new Date(timestamp).toLocaleString()))]}}])}),_c('el-table-column',{attrs:{"label":"Отдано","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var in1 = ref_row.in1;
var in2 = ref_row.in2;
return [_vm._v(_vm._s(in1.toLocaleString('ru-RU'))+" "+_vm._s(_vm.tokens.in1)+" + "+_vm._s(in2.toLocaleString('ru-RU'))+" "+_vm._s(_vm.tokens.in2))]}}])}),_c('el-table-column',{attrs:{"prop":"ars","label":"Получено","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var out = ref.row.out;
return [_vm._v(_vm._s(out.toLocaleString('ru-RU'))+" "+_vm._s(_vm.tokens.out))]}}])}),_c('el-table-column',{attrs:{"label":"TX","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tx = ref.row.tx;
return [_c('el-link',{attrs:{"href":_vm.href(tx),"target":"_blank"}},[_vm._v(_vm._s(tx.slice(0,4))+"…"+_vm._s(tx.slice(-4))),_c('img',{staticStyle:{"margin-left":"3px"},attrs:{"src":require("../../assets/icons/open-in-new-window2.png"),"width":"12"}})])]}}])})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-top":"5px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.items.length,"pager-count":5,"hide-on-single-page":"","background":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }