













import {mapState, mapGetters} from 'vuex'
import config from '../../config.coffee'

export default
  computed: {
    contractAddress: -> tronWeb.address.fromHex(@$store.state.Cabinet.contract.address)
  }
  methods:
    tronscan: (section, argument) ->
      if config.tronLink.network == 'mainnet'
        "https://tronscan.org/#/#{section}/#{argument}"
      else
        "https://#{config.tronLink.network}.tronscan.org/#/#{section}/#{argument}"


