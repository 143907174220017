





































































import { mapGetters } from 'vuex'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

import {
  verboseSend,
  checkTrxBalance,
  checkTokenBalance,
  repeat,
  humanizeDuration
} from '@/store/functions.coffee'

import {randomPackage} from '@/mockup'

export default
  components: {Allowance, Registration}
  data: ->
    id: 1
    currentUserOffer: 1
    processing: false
    # packages: [1..16].map randomPackage
  computed: {
    ...mapGetters 'Binar', [
      'packages'
    ]
    maxId: -> @packages.length
    selectedPackage: -> @packages[@id-1]
  }
  filters:
    duration: humanizeDuration
  methods:
    buy: ->

