




















import {mapState, mapGetters} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'
import icon from '../../components/icons/coins.vue'

export default
  components: {icon}
  data: ->
    amounts: [0, 100, 200]
    showGroup: false
    processing1: false
    processing2: false
    processing3: false
  computed: {
    # ...mapState 'Cashback', {
    #   contract: 'contractCashback'
    # }
    ...mapState 'Staking', ['contracts']
    ...mapGetters 'Staking', ['profit']
    ...mapGetters 'Staking', ['currentToken']
  }
  methods:
    collect1: -> @collect @contracts.staking[0]
    collect2: -> @collect @contracts.staking[1]
    collect3: -> @collect @contracts.staking[2]
    collect: (contract) ->
      # @processing = true
      verboseSend contract.takeProfit()
      .then (msg) =>
        msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
        msg.duration = 3000
        @$emit 'reload', silent: true
      .finally =>
        @processing = false
