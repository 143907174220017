






import {mapGetters, mapState} from 'vuex'

import {verboseSend} from '../../store/functions.coffee'

export default
  computed: {
    ...mapState 'Tron', ['contract']
  }
  methods:
    collect: ->
      verboseSend @contract.collect()


