import { render, staticRenderFns } from "./CompletedGames.vue?vue&type=template&id=23d8c191&scoped=true&lang=pug&"
import script from "./CompletedGames.vue?vue&type=script&lang=coffee&"
export * from "./CompletedGames.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d8c191",
  null
  
)

export default component.exports