import { render, staticRenderFns } from "./BranchSelector.vue?vue&type=template&id=52b276df&scoped=true&lang=pug&"
import script from "./BranchSelector.vue?vue&type=script&lang=coffee&"
export * from "./BranchSelector.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b276df",
  null
  
)

export default component.exports