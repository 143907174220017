









import TokenIcon from '../../components/TokenIcon.vue'
import {mapGetters, mapState, mapActions} from 'vuex'

export default
  components: {TokenIcon}
  computed: {
    ...mapState 'TokenMarket', ['contract']
    ...mapGetters 'TokenMarket', ['tokenSymbols', 'commission', 'balances']
  }
  mounted: ->
    @loadContractBalances()
  methods: {
    ...mapActions 'TokenMarket', ['loadContractBalances']
  }
