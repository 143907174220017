export default [{
	breakpoint: 'MobilS'
	breakpointWidth: 1
	numberOfCols: 1
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 1, height: 22, id: 1, component: 'CashBackCard'}
		{ x: 0, y: 22, width: 1, height: 9, id: 2, component: 'CashBackTrons'}
		{ x: 0, y: 36, width: 1, height: 9, id: 3, component: 'CashBackTokens'}
		{ x: 0, y: 71, width: 1, height: 22, id: 4, component: 'CashBackProCard'}
		{ x: 0, y: 93, width: 1, height: 9, id: 5, component: 'CashBackProTrons'}
		{ x: 0, y: 107, width: 1, height: 9, id: 6, component: 'CashBackProTokens'}
		{ x: 0, y: 31, width: 1, height: 5, id: 7, component: 'CashBackTrxFrozen'}
		{ x: 0, y: 45, width: 1, height: 5, id: 8, component: 'CashBackTokensFrozen'}
		{ x: 0, y: 102, width: 1, height: 5, id: 9, component: 'CashBackProTrxFrozen'}
		{ x: 0, y: 116, width: 1, height: 5, id: 10, component: 'CashBackProTokensFrozen'}
		{ x: 0, y: 50, width: 1, height: 21, id: 11, component: 'CashBackSubscriptions'}
		{ x: 0, y: 121, width: 1, height: 24, id: 12, component: 'CashBackProSubscriptions'}
	]
}
{
	breakpoint: 'MobilL'
	breakpointWidth: 424
	numberOfCols: 24
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 24, height: 22, id: 1, component: 'CashBackCard'}
		{ x: 0, y: 22, width: 12, height: 11, id: 2, component: 'CashBackTrons'}
		{ x: 12, y: 22, width: 12, height: 11, id: 3, component: 'CashBackTokens'}
		{ x: 0, y: 58, width: 24, height: 22, id: 4, component: 'CashBackProCard'}
		{ x: 0, y: 80, width: 12, height: 11, id: 5, component: 'CashBackProTrons'}
		{ x: 12, y: 80, width: 12, height: 11, id: 6, component: 'CashBackProTokens'}
		{ x: 0, y: 33, width: 12, height: 5, id: 7, component: 'CashBackTrxFrozen'}
		{ x: 12, y: 33, width: 12, height: 5, id: 8, component: 'CashBackTokensFrozen'}
		{ x: 0, y: 91, width: 12, height: 5, id: 9, component: 'CashBackProTrxFrozen'}
		{ x: 12, y: 91, width: 12, height: 5, id: 10, component: 'CashBackProTokensFrozen'}
		{ x: 0, y: 38, width: 24, height: 20, id: 11, component: 'CashBackSubscriptions'}
		{ x: 0, y: 96, width: 24, height: 22, id: 12, component: 'CashBackProSubscriptions'}
	]
}
{
	breakpoint: 'M'
	breakpointWidth: 767
	numberOfCols: 24
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 10, height: 22, id: 1, component: 'CashBackCard'}
		{ x: 10, y: 0, width: 7, height: 11, id: 2, component: 'CashBackTrons'}
		{ x: 17, y: 0, width: 7, height: 11, id: 3, component: 'CashBackTokens'}
		{ x: 0, y: 42, width: 10, height: 22, id: 4, component: 'CashBackProCard'}
		{ x: 10, y: 42, width: 7, height: 11, id: 5, component: 'CashBackProTrons'}
		{ x: 17, y: 42, width: 7, height: 11, id: 6, component: 'CashBackProTokens'}
		{ x: 10, y: 11, width: 7, height: 11, id: 7, component: 'CashBackTrxFrozen'}
		{ x: 17, y: 11, width: 7, height: 11, id: 8, component: 'CashBackTokensFrozen'}
		{ x: 10, y: 53, width: 7, height: 11, id: 9, component: 'CashBackProTrxFrozen'}
		{ x: 17, y: 53, width: 7, height: 11, id: 10, component: 'CashBackProTokensFrozen'}
		{ x: 0, y: 22, width: 24, height: 20, id: 11, component: 'CashBackSubscriptions'}
		{ x: 0, y: 64, width: 24, height: 22, id: 12, component: 'CashBackProSubscriptions'}
	]
}
{
	breakpoint: 'L'
	breakpointWidth: 999
	numberOfCols: 12
	rowHeight: 10
	items: [
		{ x: 0, y: 0, width: 4, height: 24, id: 1, component: 'CashBackCard'}
		{ x: 4, y: 0, width: 2, height: 12, id: 2, component: 'CashBackTrons'}
		{ x: 4, y: 12, width: 2, height: 12, id: 3, component: 'CashBackTokens'}
		{ x: 6, y: 0, width: 4, height: 24, id: 4, component: 'CashBackProCard'}
		{ x: 10, y: 0, width: 2, height: 12, id: 5, component: 'CashBackProTrons'}
		{ x: 10, y: 12, width: 2, height: 12, id: 6, component: 'CashBackProTokens'}
		{ x: 0, y: 24, width: 3, height: 8, id: 7, component: 'CashBackTrxFrozen'}
		{ x: 3, y: 24, width: 3, height: 8, id: 8, component: 'CashBackTokensFrozen'}
		{ x: 6, y: 24, width: 3, height: 8, id: 9, component: 'CashBackProTrxFrozen'}
		{ x: 9, y: 24, width: 3, height: 8, id: 10, component: 'CashBackProTokensFrozen'}
		{ x: 0, y: 32, width: 6, height: 24, id: 11, component: 'CashBackSubscriptions'}
		{ x: 6, y: 32, width: 6, height: 24, id: 12, component: 'CashBackProSubscriptions'}
	]
}
]

# export default [{
#   breakpoint: 'minimal'
#   numberOfCols: 12
#   rowHeight: 10
#   items: [
#     { x:  0, y:  0, width: 4, height: 24, id:  1, component: 'CashBackCard' }
#     { x:  4, y:  0, width: 2, height: 12, id:  2, component: 'CashBackTrons' }
#     { x:  4, y: 12, width: 2, height: 12, id:  3, component: 'CashBackTokens' }
#     { x:  6, y:  0, width: 4, height: 24, id:  4, component: 'CashBackProCard' }
#     { x: 10, y:  0, width: 2, height: 12, id:  5, component: 'CashBackProTrons' }
#     { x: 10, y: 12, width: 2, height: 12, id:  6, component: 'CashBackProTokens' }
#     { x:  0, y: 24, width: 3, height:  8, id:  7, component: 'CashBackTrxFrozen' }
#     { x:  3, y: 24, width: 3, height:  8, id:  8, component: 'CashBackTokensFrozen' }
#     { x:  6, y: 24, width: 3, height:  8, id:  9, component: 'CashBackProTrxFrozen' }
#     { x:  9, y: 24, width: 3, height:  8, id: 10, component: 'CashBackProTokensFrozen' }
#     { x:  0, y: 32, width: 6, height: 24, id: 11, component: 'CashBackSubscriptions' }
#     { x:  6, y: 32, width: 6, height: 24, id: 12, component: 'CashBackProSubscriptions' }
#   ]
# }]




# }, {
#   breakpoint: 'lg'
#   breakpointWidth: 1200
#   numberOfCols: 6
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 1, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 2, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 3, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 4, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 5, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 1, width: 2, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 2, y: 1, width: 2, height: 1, id:  8, component: 'Play' }
#     { x: 4, y: 1, width: 2, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 2, width: 3, height: 1, id: 10, component: 'MainQueue' }
#     { x: 3, y: 2, width: 3, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 3, width: 3, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 3, y: 3, width: 3, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'md'
#   breakpointWidth: 996
#   numberOfCols: 8
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'sm'
#   breakpointWidth: 768
#   numberOfCols: 4
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xs'
#   breakpointWidth: 480
#   numberOfCols: 2
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }, {
#   breakpoint: 'xxs'
#   breakpointWidth: 0
#   numberOfCols: 1
#   items: [
#     { x: 0, y: 0, width: 1, height: 1, id:  1, component: 'Rewards' }
#     { x: 0, y: 0, width: 1, height: 1, id:  2, component: 'GamesPlayed' }
#     { x: 0, y: 0, width: 1, height: 1, id:  3, component: 'Players' }
#     { x: 0, y: 0, width: 1, height: 1, id:  4, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id:  5, component: 'YourGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  6, component: 'PartnerGames' }
#     { x: 0, y: 0, width: 1, height: 1, id:  7, component: 'NextRewardProgress' }
#     { x: 0, y: 0, width: 1, height: 1, id:  8, component: 'Play' }
#     { x: 0, y: 0, width: 1, height: 1, id:  9, component: 'YourReward' }
#     { x: 0, y: 0, width: 1, height: 1, id: 10, component: 'MainQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 11, component: 'PersonalQueue' }
#     { x: 0, y: 0, width: 1, height: 1, id: 12, component: 'CompletedGames' }
#     { x: 0, y: 0, width: 1, height: 1, id: 13, component: 'Partners' }
#   ]
# }]
