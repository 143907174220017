import config from '../config.coffee'
# import contracts from '../contracts/nile'
import contracts from '../contracts/mainnet'

export default
  namespaced: true
  state: =>
    cached: {}
  mutations:
    cache: (state, {address, contract}) -> state.cached[address] = contract
  actions:
    load: ({commit, state}, address) ->
      @loadContract = contracts(tronWeb) unless @loadContract?
      if state.cached[address]
        state.cached[address]
      else
        contract = await @loadContract address
        commit 'cache', {address, contract}
        contract
  getters: {}
