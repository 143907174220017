



































import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash"
import { OrbitSpinner } from 'epic-spinners'
import dlayouts from "../layouts/Cabinet.coffee"

import AllowancesInfo from "./Cabinet/AllowancesInfo.vue"
import Level1 from "./Cabinet/Level1.vue"
import Level2 from "./Cabinet/Level2.vue"
import Level3 from "./Cabinet/Level3.vue"
import Level4 from "./Cabinet/Level4.vue"
import Level5 from "./Cabinet/Level5.vue"
import MyAllowances from "./Cabinet/MyAllowances.vue"
import PartnerLink from "./Cabinet/PartnerLink.vue"
import PartnersList from "./Cabinet/PartnersList.vue"
import TotalPartners from "./Cabinet/TotalPartners.vue"
import Upliner from "./Cabinet/Upliner.vue"

import { mapState } from 'vuex'

import WYSIWYG from '../components/WYSIWYG.coffee'

export default {
  components: {
    OrbitSpinner
    Dashboard, DashLayout, DashItem
    OrbitSpinner
    AllowancesInfo
    Level1
    Level2
    Level3
    Level4
    Level5
    MyAllowances
    PartnerLink
    PartnersList
    TotalPartners
    Upliner
  }
  data: ->
    currentBreakpoint: dlayouts[0].breakpoint
    newBreakpoint: null
    editMode: false
    dlayouts: dlayouts
  computed: {
    ...mapState('Cabinet', ['loading'])
    ...WYSIWYG.computed
  }
  mounted: ->
    @$store.dispatch("Cabinet/load")

    @handler = (e) =>
      @switchEditMode() if e.code == "KeyE" and e.ctrlKey and e.shiftKey
    document.addEventListener('keydown', @handler)
  beforeDestroy: ->
    document.removeEventListener('keydown', @handler)
  methods: {
    ...WYSIWYG.methods
  }
}
