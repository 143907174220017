export default [{
	breakpoint: 'minimal'
	breakpointWidth: 1
	numberOfCols: 48
	rowHeight: 1
	items: [
		{ x: 33, y: 41, width: 15, height: 12, id: 1, component: 'BinarAccruals'}
		{ x: 33, y: 30, width: 15, height: 11, id: 2, component: 'BinarBonuses'}
		{ x: 33, y: 0, width: 15, height: 30, id: 4, component: 'BinarDetails'}
		{ x: 15, y: 0, width: 18, height: 53, id: 5, component: 'BinarGraph'}
		{ x: 0, y: 53, width: 24, height: 28, id: 6, component: 'BinarHistory'}
		{ x: 0, y: 0, width: 15, height: 53, id: 7, component: 'BinarPackage'}
		{ x: 24, y: 53, width: 24, height: 28, id: 8, component: 'BinarPurchasesUnderneath'}
	]
}
]
# { x: 18, y: 0, width: 6, height: 13, id: 3, component: 'BinarBranchSelector'}