










































import TokenIcon from '../../components/TokenIcon.vue'
import {mapGetters, mapState} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'

export default
  components: {TokenIcon}
  data: ->
    loading:
      startCashback: false
      startCashbackPro: false
    # newRound:
    #   trxPrice: 100
    #   tokenPrice: 100
    #   saleRate: 240
    #   survivalTime: 12
    #   referralParts: "15% 10% 5%"
  computed: {
    # newRoundArguments: ->
    #   trxPrice: tronWeb.toSun(@newRound.trxPrice)
    #   tokenPrice: tronWeb.toSun(@newRound.tokenPrice)
    #   saleRate: Math.round(@newRound.saleRate)
    #   survivalTime: @newRound.survivalTime*3600
    #   referralParts: @newRound.referralParts.split(' ').map((e) -> parseInt e).filter((x) -> x >= 0)
    ...mapState 'Cashback', [
      'contractCashback'
      'contractCashbackPro'
    ]
  }
  methods: {
    startCashback: ->
      @loading.startCashback = true
      verboseSend @contractCashback.start()
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.startCashback = false
    startCashbackPro: ->
      @loading.startCashbackPro = true
      verboseSend @contractCashbackPro.start()
      .then (msg) => @$store.dispatch('Cashback/silentReload')
      .finally => @loading.startCashbackPro = false
  }
