












import {mapState, mapGetters} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'

import icon from '../../components/icons/dollar-in-hand.vue'

export default
  components: {icon}
  computed: {
    ...mapState 'InvestBox', ['contract']
    ...mapGetters 'InvestBox', ['invested']
    value: -> @invested.token1
    ...mapState 'InvestBox',
      token: (s) -> s.IN1_tokenInfo.symbol
  }
