














import {mapState, mapGetters} from 'vuex'
import icon from '../../components/AddressIcon.vue'
# import icon from '../../components/icons/handshake.vue'

export default
  components: {icon}
  computed: mapGetters "Cabinet", ["parent"]
