












import {mapState, mapGetters} from 'vuex'
import icon from '../../components/icons/lock.vue'

export default
  components: {icon}
  computed: {
    ...mapState 'Cashback', []
    ...mapGetters 'Cashback',
      value: 'cashbackProTrxFrozen'
  }
