
























import { mapGetters } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar';

take = (field) -> (el) -> el[field]
sum = (a,b) -> a + b

export default
  computed:
    mapGetters 'KingOfTheHill', ['achievments', 'currentRound']
  mounted: ->
    @ps = new PerfectScrollbar @$el.getElementsByClassName('el-table__body-wrapper')[0]
    # window.el = @$el.getElementsByClassName('el-table__body-wrapper')[0]

  beforeDestroy: ->
    @ps.destroy()
    @ps = null

  methods:
    summary: -> [
      "Всего:"
      Math.round(@achievments.map(take "purchased").reduce(sum, 0))
      Math.round(@achievments.map(take "won").reduce(sum, 0))
    ]
