













import Summary from './Tron/Summary.vue'
import Actions from './Tron/Actions.vue'
import Events from './Tron/Events.vue'
import Ownership from './Tron/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
