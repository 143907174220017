# Token Market

import {
  singularMutations
  mutate
  getAllContractEvents
  repeat
  getTokenBalances
  tokenSymbol as address2symbol
} from './functions.coffee'

import config from '../config.coffee'

import {address2hex, Multicall} from '../multicall.coffee'



export default
  namespaced: true
  state: =>
    initialized: false
    loading: false
    currentOffer: null
    numberOfPackages: null
    contract: null
    events: null
    terms: []
    payouts: []
    addressToPayouts: {}
    addressToPartnerPayouts: {}
    partnership: {}
    tokens: []
    referralSpeedups: {}
    commission: null
    balances: null
  mutations: {
    initialization: (state) -> state.initialized = true
    ... singularMutations [
      'loading'
      'currentOffer'
      'numberOfPackages'
      'contract'
      'events'
      'terms'
      'payouts'
      'addressToPayouts'
      'addressToPartnerPayouts'
      'partnership'
      'tokens'
      'commission'
      'balances'
      'referralSpeedups'
    ]
  }
  actions:
    load: ({ state, dispatch }) -> dispatch (if state.initialized then 'reload' else 'initialize')
    initialize: ({state, commit, dispatch, getters}) ->
      return if state.loading
      console.info 'loading start'
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('contracts/load', config.contracts.TokenMarket, root: true).then (c) ->
          commit 'contract', c
          dispatch('getContractState')
      ]
      await dispatch('processEvents')
      console.info 'loading end'
      commit('loading', false)
      commit('initialization')
    reload: ({ commit, dispatch, state }) ->
      return if state.loading
      commit('loading', true)
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      await dispatch('processEvents')
      commit('loading', false)
    silentReload: ({dispatch}) ->
      await Promise.all [
        dispatch('getContractEvents')
        dispatch('getContractState')
      ]
      await dispatch('processEvents')
    getContractEvents: ({commit, state}) ->
      events = await getAllContractEvents(config.contracts.TokenMarket)
      commit 'events', events
    getContractState: ({dispatch}) -> Promise.all [
      dispatch('getContractCommonState')
      dispatch('getContractUserState')
    ]
    getContractCommonState: ({state, commit, rootState, getters}) ->
      contract = state.contract
      commitResult = (name) -> (value) -> commit(name, value)

      @multicall ||= Multicall(tronWeb, config.contracts.Multicall)

      methods = {
        currentOffer: contract.methodInstances.currentOffer
        numberOfPackages: contract.methodInstances.numberOfPackages
        commission: contract.methodInstances.commission_
      }

      contractHexAddress = contract.address.replace(/^41/,'0x')

      for name, method of methods
        methods[name] = {
          contract: contractHexAddress
          signature: '0x'+method.signature
          # functionSelector: method.functionSelector
          inputs: method.inputs
          outputs: method.outputs
          # parameters: []
        }

      calls = []; names = []
      for name, method of methods
        names.push name
        calls.push method

      results = await @multicall(calls)

      for i, value of results
        commit names[i], value

    getContractUserState: ({state, commit, rootState, getters: {userAddress}}) ->
      # contract = state.contract
      # Promise.all [
      #   # repeat(-> contract.availableFor(userAddress).call()).then((val) -> commit('available', val.available))
      # ]
    processEvents: ({state: {events}, commit, getters: {userAddress}}) ->
      terms = []
      payouts = {}
      addressToPayouts = {}
      addressToPartnerPayouts = {}
      partnership = {}
      tokens = []
      referralSpeedups = {}
      now = +new Date
      for {name: eventName, result, timestamp, transaction: txid} in events
        switch eventName
          when "ReferralSpeedup"
            id = +result.byPayoutId
            amount = +tronWeb.fromSun result.amount
            referrer = tronWeb.address.fromHex result.referrer
            referralSpeedups[id] = {amount, referrer}
          when "NewToken"
            tokens.push tronWeb.address.fromHex result.token
          when "NewOffer"
            extraToken = address2symbol[tronWeb.address.fromHex result.extraToken]
            payoutToken = address2symbol[tronWeb.address.fromHex result.payoutToken]
            basePrice = +tronWeb.fromSun result.basePrice
            earlyPayout = +tronWeb.fromSun result.earlyPayout
            extraPrice = +tronWeb.fromSun result.extraPrice
            regularPayout  = +tronWeb.fromSun result.regularPayout
            earlyReceiptPeriod = (+result.earlyReceiptPeriod)*1000
            freezePeriod = (+result.freezePeriod)*1000
            referralShare = +result.referralShare
            terms[result.termsId] = {
              basePrice
              earlyPayout
              earlyReceiptPeriod
              extraPrice
              extraToken
              freezePeriod
              payoutToken
              referralShare
              regularPayout
            }
          when "PackageBought"
            payoutId = +result.payoutId
            termsId = +result.termsId
            user = tronWeb.address.fromHex result.user
            parent = tronWeb.address.fromHex result.parent
            progress = 0
            payouts[payoutId] = {termsId, user, createdAt: timestamp, progress}
            addressToPayouts[user] ||= []
            addressToPayouts[user].push payoutId
            addressToPartnerPayouts[parent] ||= []
            addressToPartnerPayouts[parent].push payoutId
            partnership[user] = parent
          when "PayoutCollected" #(uint payoutId, address user);
            id = +result.payoutId
            payouts[id].state = "PaidOut"
          when "EarlyPayoutUpdate" #(uint payoutId, address user, uint current, uint total);
            id = +result.payoutId
            current = +tronWeb.fromSun result.current
            total = +tronWeb.fromSun result.total
            payouts[id].progress = current

          when "EarlyPayoutComplete" #(uint payoutId, address user);
            id = +result.payoutId
            total = +tronWeb.fromSun result.total
            payouts[id].frozenAt = timestamp
            payouts[id].progress = total

      for id, payout of payouts
        {earlyReceiptPeriod, freezePeriod, earlyPayout} = terms[payout.termsId]
        if payout.state == "PaidOut"
          payout.isEarly = (payout.progress == earlyPayout)
        else
          payout.isEarly = true
          if payout.progress == earlyPayout
            if payout.frozenAt + freezePeriod < now
              payout.state = "ReadyToPay"
            else
              payout.state = "Unfreezing"
          else
            if payout.createdAt + earlyReceiptPeriod < now
              payout.state = "ReadyToPay"
              payout.isEarly = false
            else
              payout.state = "Accumulation"

      commit 'terms', terms
      commit 'payouts', payouts
      commit 'addressToPayouts', addressToPayouts
      commit 'addressToPartnerPayouts', addressToPartnerPayouts
      commit 'partnership', partnership
      commit 'referralSpeedups', referralSpeedups
      commit 'tokens', tokens

    loadContractBalances: ({commit}) ->
      commit 'balances', null
      commit 'balances', await getTokenBalances(config.contracts.TokenMarket)
  getters:
    userAddress: -> arguments[2].TronLink.account.address
    packagesLeft: ({numberOfPackages}) -> numberOfPackages.toNumber()
    price1: ({currentOffer: {basePrice}}) ->
      amount: +tronWeb.fromSun(basePrice)
      token: 'USDT'
    price2: ({currentOffer: {extraPrice, extraToken}}) ->
      amount: +tronWeb.fromSun(extraPrice)
      token: address2symbol[tronWeb.address.fromHex(extraToken)]
    payout: ({currentOffer: {regularPayout, payoutToken, earlyPayout}}) ->
      regular:
        amount: +tronWeb.fromSun(regularPayout)
        token: address2symbol[tronWeb.address.fromHex(payoutToken)]
      early:
        amount: +tronWeb.fromSun(earlyPayout)
        token: 'USDT'
    referralSpeedup: ({currentOffer: {referralShare}}) -> referralShare.toNumber()
    period: ({currentOffer: {earlyReceiptPeriod}}) ->
      val = earlyReceiptPeriod.toNumber() / (24*3600)
      if val < 1
        +val.toFixed(4)
      else
        Math.round(val)
    available: ({payouts, terms}, {userAddress}) ->
      sum = (a,b) -> a + b
      payouts = Object.values(payouts)
      payouts.filter (p) -> (p.isEarly) and (p.user == userAddress) and (p.state == "ReadyToPay")
             .map ({termsId}) -> terms[termsId].earlyPayout
             .reduce(sum, 0)
    frozen: ({payouts, terms}, {userAddress}) ->
      sum = (a,b) -> a + b
      payouts = Object.values(payouts)
      payouts.filter (p) -> (p.isEarly) and (p.user == userAddress) and (p.state == "Unfreezing")
             .map ({termsId}) -> terms[termsId].earlyPayout
             .reduce(sum, 0)
    userPayoutProgress: ({payouts, terms}, {userAddress}) ->
      len = Object.keys(payouts).length
      if len > 0
        for id in [1..len]
          if payouts[id].state == "Accumulation" and payouts[id].user == userAddress
            {earlyPayout: total} = terms[payouts[id].termsId]
            {progress: current} = payouts[id]
            return {exists: true, number: id, total, current}
      exists: false
    payoutProgress: ({payouts,terms}) ->
      len = Object.keys(payouts).length
      if len > 0
        for id in [1..len]
          if payouts[id].state == "Accumulation"
            {earlyPayout: total} = terms[payouts[id].termsId]
            {progress: current} = payouts[id]
            return {exists: true, number: id, total, current}
      exists: false
    partnerPurchases: (state, {userAddress}) ->
      return [] unless state.addressToPartnerPayouts[userAddress]
      for number in state.addressToPartnerPayouts[userAddress]
        { termsId, user: address } = state.payouts[number]
        {
          basePrice: base, extraPrice: extra, referralShare,
          extraToken: extraTokenSymbol
        } = state.terms[termsId]
        price = {base, extra}
        # speedup = base*referralShare/100
        speedup = state.referralSpeedups[number]?.amount || 0
        { number, address, price, speedup, extraTokenSymbol}
    userPayouts: (state, {userAddress}) ->
      now = +new Date()
      return [] unless state.addressToPayouts[userAddress]
      for number in state.addressToPayouts[userAddress]
        {termsId, createdAt, isEarly, state: payoutState} = state.payouts[number]
        terms = state.terms[termsId]
        price = {base: terms.basePrice, extra: terms.extraPrice}
        extraTokenSymbol = terms.extraToken
        days =
          current: +((now - createdAt)/(24*3600*1000)).toFixed(4)
          total: +(terms.earlyReceiptPeriod/(24*3600*1000)).toFixed(4)
        days.current = days.total if days.current > days.total
        days.current = Math.ceil(days.current) unless days.current < 1
        days.total = Math.ceil(days.total) unless days.total < 1

        payout = {regular: {}, early: {}}
        payout.regular.amount = terms.regularPayout
        payout.regular.symbol = terms.payoutToken
        payout.early.amount = terms.earlyPayout

        if(isEarly)
          payout.regular.status = 'unavailable'
          switch payoutState
            when "ReadyToPay"
              payout.early.status = "ready"
            when "Unfreezing"
              payout.early.status = "unfreezing"
            when "Accumulation"
              payout.early.status = "awaiting"
              payout.regular.status = 'awaiting'
            when "PaidOut"
              payout.early.status = "paid"
        else
          payout.early.status = "unproduced"
          switch payoutState
            when "ReadyToPay"
              payout.regular.status = 'ready'
            when "PaidOut"
              payout.regular.status = 'paid'
        { number, createdAt, price, extraTokenSymbol, days, payout }
    tokenSymbols: ({tokens}) ->
      map = {}
      for symbol, address of config.contracts.TRC20
        map[address] = symbol
      map[token] for token in tokens
    commission: ({commission}) -> +tronWeb.fromSun(commission.toString())
    balances: ({balances}) ->
      if balances
        for key, value of balances
          balances[key] = +tronWeb.fromSun(value.toString())
        return {loading: false, ...balances}
      else
        return {loading: true}
    oldCfg: ({currentOffer}) ->
      fromSun = (bn) -> +tronWeb.fromSun(bn.toString())
      toDays = (bn) -> +(bn.toNumber()/(24*3600)).toFixed(6)
      basePrice = fromSun currentOffer.basePrice
      extraPrice = fromSun currentOffer.extraPrice
      earlyPayout = fromSun currentOffer.earlyPayout
      regularPayout = fromSun currentOffer.regularPayout
      earlyReceiptPeriod = toDays currentOffer.earlyReceiptPeriod
      freezePeriod = toDays currentOffer.freezePeriod
      referralShare = currentOffer.referralShare.toNumber()
      extraToken = tronWeb.address.fromHex currentOffer.extraToken
      payoutToken = tronWeb.address.fromHex currentOffer.payoutToken
      {basePrice,extraPrice,earlyPayout,regularPayout,earlyReceiptPeriod,freezePeriod,referralShare,extraToken,payoutToken}