
















































































import {mapState, mapGetters} from 'vuex'
import {verboseSend, repeat} from '../../store/functions.coffee'
import BigNumber from 'bignumber.js'

export default
  data: ->
    new_maxBaseAsset: null
    new_maxBaseValue: null
    new_minBaseAsset: null
    new_minBaseValue: null
    new_maxQuoteAsset: null
    new_maxQuoteValue: null
    new_minQuoteAsset: null
    new_minQuoteValue: null
    new_sellPrice:
      max: null
      min: null
    new_buyPrice:
      max: null
      min: null
  computed: {
    ...mapGetters 'Exchange', [
      'currentPair'
      'maxBaseAsset'
      'maxBaseValue'
      'minBaseAsset'
      'minBaseValue'
      'maxQuoteAsset'
      'maxQuoteValue'
      'minQuoteAsset'
      'minQuoteValue'
      'sellPrice'
      'buyPrice'
      'priceDivisor'
    ]
    ...mapState 'Exchange', ['contract']
  }
  mounted: ->
    @new_maxBaseAsset = @fromSun @maxBaseAsset
    @new_maxBaseValue = @fromSun @maxBaseValue
    @new_minBaseAsset = @fromSun @minBaseAsset
    @new_minBaseValue = @fromSun @minBaseValue
    @new_maxQuoteAsset = @fromSun @maxQuoteAsset
    @new_maxQuoteValue = @fromSun @maxQuoteValue
    @new_minQuoteAsset = @fromSun @minQuoteAsset
    @new_minQuoteValue = @fromSun @minQuoteValue
    @new_sellPrice.min = @fromPrice @sellPrice.min
    @new_sellPrice.max = @fromPrice @sellPrice.max
    @new_buyPrice.min = @fromPrice @buyPrice.min
    @new_buyPrice.max = @fromPrice @buyPrice.max
  methods:
    fromSun: (value) -> +tronWeb.fromSun value
    toSun: (value) -> BigNumber tronWeb.toSun value
    fromPrice: (value) -> value.div(@priceDivisor).toNumber()
    toPrice: (value) -> BigNumber(value).times(@priceDivisor)
    setLimitBuyRanges: ->
      minValue = @toSun( @new_minBaseValue || 0).toString()
      maxValue = @toSun( @new_maxBaseValue || 0).toString()
      minAsset = @toSun( @new_minQuoteAsset || 0).toString()
      maxAsset = @toSun( @new_maxQuoteAsset || 0).toString()
      minPrice = @toPrice( @new_buyPrice.min || 0).toString()
      maxPrice = @toPrice( @new_buyPrice.max || 0).toString()
      console.info minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice
      verboseSend @contract.setLimitBuyRanges(minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice)
    setLimitSellRanges: ->
      minAsset = @toSun( @new_minBaseAsset || 0).toString()
      maxAsset = @toSun( @new_maxBaseAsset || 0).toString()
      minValue = @toSun( @new_minQuoteValue || 0).toString()
      maxValue = @toSun( @new_maxQuoteValue || 0).toString()
      minPrice = @toPrice( @new_sellPrice.min || 0).toString()
      maxPrice = @toPrice( @new_sellPrice.max || 0).toString()
      console.info minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice
      verboseSend @contract.setLimitSellRanges(minAsset, maxAsset, minValue, maxValue, minPrice, maxPrice)


