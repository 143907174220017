






import TokenIcon from '../../components/TokenIcon.vue'
import {mapGetters, mapState} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'

export default {}
