

































import TronAddress from '../../components/TronAddress.vue'
import AddressIcon from '../../components/AddressIcon.vue'
import config from '../../config.coffee'

BASE_URL = if config.tronLink.network == 'mainnet'
  "https://tronscan.org/#/transaction/"
else
  "https://#{config.tronLink.network}.tronscan.org/#/transaction/"


import { mapGetters } from 'vuex'

export default
  components: {TronAddress, AddressIcon}
  data: ->
    currentPage: 1
    pageSize: 5
  computed: {
    ...mapGetters 'Staking', {items: 'mySwaps'}
    ...mapGetters 'Staking', tokens: 'swapTokens'
    pageData: ->
      @items.slice(
        (@currentPage-1) * @pageSize,
        @currentPage * @pageSize
      )
  }
  methods:
    href: (tx) -> BASE_URL + tx
