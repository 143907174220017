

















































import {mapState, mapGetters} from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'
import BigNumber from 'bignumber.js'
import {repeat} from '../../store/functions.coffee'
# import { MessageBox } from 'element-ui'
import {verboseSend} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

# window.test = checkAllowance

# window.BigNumber = BigNumber

export default
  components: {TokenIcon, Allowance, Registration}
  data: ->
    processing: false
    in1Amount: BigNumber(0)
    in2Amount: BigNumber(0)
    outAmount: BigNumber(0)
  computed: {
    ...mapGetters 'Staking', [
      'userAddress'
    ]
    ...mapGetters 'Staking', tokens: 'swapTokens'
    ...mapState 'Staking', ['swap', 'contracts', 'currentToken']
    baseIN1: -> BigNumber(@swap.parameters.IN1_amount.toString())
    baseIN2: -> BigNumber(@swap.parameters.IN2_amount.toString())
    baseOUT: -> BigNumber(@swap.parameters.OUT_amount.toString())
    sponsorAddress: -> tronWeb.address.fromHex(@swap.parameters.sponsor)
    swapAddress: -> tronWeb.address.fromHex(@contracts.swap.address)
    in1AmountModel:
      get: -> tronWeb.fromSun(@in1Amount.integerValue().toString())
      set: (usdt) ->
        @in1Amount = BigNumber(tronWeb.toSun(usdt))
        @in2Amount = @in1Amount.times(@baseIN2).div(@baseIN1)
        @outAmount = @in2Amount.times(@baseOUT).div(@baseIN2)
    in2AmountModel:
      get: -> tronWeb.fromSun(@in2Amount.integerValue().toString())
      set: (hyho) ->
        @in2Amount = BigNumber(tronWeb.toSun(hyho))
        @in1Amount = @in2Amount.times(@baseIN1).div(@baseIN2)
        @outAmount = @in2Amount.times(@baseOUT).div(@baseIN2)
    outAmountModel:
      get: -> tronWeb.fromSun(@outAmount.integerValue().toString())
      set: (ars) ->
        @outAmount = BigNumber(tronWeb.toSun(ars))
        # @in1Amount = @outAmount.times(100).div(@saleRate)
        @in2Amount = @outAmount.times(@baseIN2).div(@baseOUT)
        @in1Amount = @outAmount.times(@baseIN1).div(@baseOUT)
    rules: ->
      in1Amount: [
        {
          trigger: 'change',
          validator: (rule, value, callback) =>
            window.value = value
            if value.eq(BigNumber(0)) and not value.isNaN()
              callback(new Error("Должно быть больше нуля"))
            else
              callback()
        }, {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            @in1Contract.balanceOf(@userAddress).call().then (balance) =>
              if value.gt BigNumber(balance)
                callback(new Error("Недостаточно #{@tokens.in1} на балансе"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить баланс"))
        }
      ]
      in2Amount: [
        {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            @in2Contract.balanceOf(@userAddress).call().then (balance) =>
              if value.gt BigNumber(balance)
                callback(new Error("Недостаточно #{@tokens.in2} на балансе"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить баланс"))
        }, {
          trigger: 'change',
          validator: (rule, value, callback) =>
            if value.eq(BigNumber(0)) and not value.isNaN()
              callback(new Error("Должно быть больше нуля"))
            else
              callback()
        }
      ]
      outAmount: [
        {
          trigger: 'blur',
          validator: (rule, value, callback) =>
            Promise.all([
              @outContract.balanceOf(@sponsorAddress).call()
              @outContract.allowance(@sponsorAddress, @swapAddress).call()
            ]).then ([balance, allowance]) ->
              # console.info tronWeb.fromSun balance
              # console.info tronWeb.fromSun allowance
              # console.info value.gt BigNumber(allowance)
              if value.gt(BigNumber(allowance))
                if value.gt(BigNumber(balance))
                  callback(new Error("Не хватает резервов"))
                else
                  callback(new Error("Не хватает разрешения для резерва"))
              else
                callback()
            .catch (e) =>
              console.error(e)
              callback(new Error("Не удалось проверить резерв"))
        }, {
          trigger: 'change',
          validator: (rule, value, callback) =>
            if value.eq(BigNumber(0)) and not value.isNaN()
              callback(new Error("Должно быть больше нуля"))
            else
              callback()
        }
      ]
  }
  mounted: ->
    @$store.dispatch('contracts/load', config.contracts.TRC20[@tokens.in1]).then (c) => @in1Contract = c
    @$store.dispatch('contracts/load', config.contracts.TRC20[@tokens.in2]).then (c) => @in2Contract = c
    @$store.dispatch('contracts/load', config.contracts.TRC20[@tokens.out]).then (c) => @outContract = c
  methods:
    send: ->
      @$refs.form.validate (valid) =>
        if valid
          @processing = true

          in1Amount = @in1Amount.integerValue().toString()
          in2Amount = @in2Amount.integerValue().toString()

          if await checkRegistration() and await checkAllowance(
            in1Amount, @tokens.in1
            config.contracts.ComboSwap[@currentToken]
            "Разрешить контракту Swap to #{@currentToken} списывать с вашего адреса"
          ) and await checkAllowance(
            in2Amount, @tokens.in2
            config.contracts.ComboSwap[@currentToken]
            "Разрешить контракту Swap to #{@currentToken} списывать с вашего адреса"
          )

            verboseSend @contracts.swap.swap(in1Amount, in2Amount), feeLimit: tronWeb.toSun(200)
            .then (msg) =>
              msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
            # @processing = false
            return

          @processing = false
        else
          return false
      return

