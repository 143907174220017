


































import {mapState, mapGetters} from 'vuex'

import {verboseSend, checkTrxBalance, checkTokenBalance, repeat} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
import Registration, { checkRegistration } from '../../components/Registration.vue'

export default
  components: {Allowance, Registration}
  data: ->
    processing: false
  computed: {
    # ...mapState 'Cashback',
    #   started: 'isStartedCashback'
    #   contract: 'contractCashback'
    # ...mapGetters 'Cashback', ['currentPackage']
    ...mapGetters 'InvestBox', ['userAddress', 'package']
    price1: -> tronWeb.fromSun(@package.price1)
    price2: -> tronWeb.fromSun(@package.price2)
    payout: -> tronWeb.fromSun(@package.payout)
    ...mapState 'InvestBox',
      price1Token: (s) -> s.IN1_tokenInfo.symbol
      price2Token: (s) -> s.IN2_tokenInfo.symbol
      payoutToken: (s) -> s.OUT_tokenInfo.symbol
    ...mapState 'InvestBox', ['contract']
    days: -> Math.round(@package.timespan/(24*3600))
  }
  mounted: ->
    repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20[@price1Token])).then (contract) => @tokenContract1 = contract
    repeat(=> @$store.dispatch('contracts/load', config.contracts.TRC20[@price2Token])).then (contract) => @tokenContract2 = contract
  methods:
    buy: ->
      # trxPrice = tronWeb.toSun(@currentPackage.trxPrice)
      # tokenPrice = tronWeb.toSun(@currentPackage.tokenPrice)
      @processing = true
      # console.info @package.price1, @price1Token
      # console.info @package.price2, @price2Token
      if  await checkTokenBalance(@userAddress, @package.price1, @price1Token, @tokenContract1) and
          await checkTokenBalance(@userAddress, @package.price2, @price2Token, @tokenContract2) and
          await checkRegistration() and
          await checkAllowance(@package.price1, @price1Token, @contract.address, 'Разрешить контракту InvestBox списывать с вашего адреса') and
          await checkAllowance(@package.price2, @price2Token, @contract.address, 'Разрешить контракту InvestBox списывать с вашего адреса')
          # await checkAllowance(tokenPrice, "HYHO", config.contracts.MultistageSwap.HYHO, 'Разрешить контракту InvestBox списывать с вашего адреса')
        # verboseSend @contract.buy(), callValue: trxPrice, feeLimit: tronWeb.toSun(200)
        verboseSend @contract.buy(), feeLimit: tronWeb.toSun(200)
        .then (msg) =>
          msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
          msg.duration = 3000
          @$emit 'reload', silent: true
        .finally =>
          @processing = false
        return
      @processing = false

