













import Summary from './Staking/Summary.vue'
import Actions from './Staking/Actions.vue'
import Events from './Staking/Events.vue'
import Ownership from './Staking/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
