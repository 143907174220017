













import Summary from './Binar/Summary.vue'
import Actions from './Binar/Actions.vue'
import Events from './Binar/Events.vue'
import Ownership from './Binar/Ownership.vue'

export default
  components: {
    Summary
    Actions
    Events
    Ownership
  }
  # mounted: ->
  #   console.info Summary
