const map = {
  TP9iootzX2mopLAKAnAmzdxGSqm1V2TJmH: import(/* webpackChunkName: 'abi' */'../abi/e58974ff3b1cef052215fdcaf7ecef88.yaml'),
  TY2LYWz8bHjPCtvr1gc1ugH7jH237kiMdW: import(/* webpackChunkName: 'abi' */'../abi/1b4300e20392f93539b4702b269bc073.yaml'),
  TPPEzPZxr722WZAVfqPPKBYzgh1yDc6pMw: import(/* webpackChunkName: 'abi' */'../abi/97f3d0d17b2dc0aae655ef0777d8d17e.yaml'),
  TRyiNsUAGRR8HeYnBPjZFNBuGAqMSA1TeA: import(/* webpackChunkName: 'abi' */'../abi/97f3d0d17b2dc0aae655ef0777d8d17e.yaml'),
  TVi4GM7E8EaVqVZGkTTWUpD5sYeALx91EL: import(/* webpackChunkName: 'abi' */'../abi/97f3d0d17b2dc0aae655ef0777d8d17e.yaml'),
  TRpvsp9vbhmChRiaTCwSRL9B7UyDs2mZ79: import(/* webpackChunkName: 'abi' */'../abi/305f54a988848b97e65814c7cbf8c817.yaml'),
  TUD5uX31yKkXeSPgwCfEorFnjBqCvu5cR9: import(/* webpackChunkName: 'abi' */'../abi/305f54a988848b97e65814c7cbf8c817.yaml'),
  TL47PsFHi7ijtGjZWHEepVfZUY7Z65y6LD: import(/* webpackChunkName: 'abi' */'../abi/305f54a988848b97e65814c7cbf8c817.yaml'),
  TM3s1G1nik396JMbTx1Mp8RFCY6cwfxKvZ: import(/* webpackChunkName: 'abi' */'../abi/305f54a988848b97e65814c7cbf8c817.yaml'),
  TXj8UpfPcXvxWshj4v1wCgoDGURwPpvLWV: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TGpmyAuqgs3dEett3kfwCa8SCgRg3pD979: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TD9KC2qpcSJ6eRuN4NE9H4dkNKYYvBSEk9: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TTDVN4ySBHXv5r3Gt8e6MDEgG2TW9arE5S: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TAdEr3qfargUYTcPFHphAHDE8G6LGGpA7M: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TN1tE3LTqWmJNgo7JBWbjA8yPGEeaxxeSj: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TRVDQaK8FzUKEhpmMPMaUqd1h17XZ6KvSY: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TL9ayipAZjEmzySYJMGdNjKgX3hp8nyqWT: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TJ1kMXT3fcB9K5dNBmAxteC7oVHQyZj8UK: import(/* webpackChunkName: 'abi' */'../abi/49cd6b26e4bfa1bd66271e806adeac7a.yaml'),
  TB8d7PCgaCmTHN2dvGpD1eweoewWNUkVkj: import(/* webpackChunkName: 'abi' */'../abi/ae4b06b341f894dfade82888bb4cf959.yaml'),
  TKSufaNZB6ZMjZQajVfoTPw8a2VodjPndE: import(/* webpackChunkName: 'abi' */'../abi/14d26b2f18911d6d9c28132caf2aac59.yaml'),
  TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t: import(/* webpackChunkName: 'abi' */'../abi/cdb29be83a395bd03d62342dd3d98d0e.yaml'),
  TXWigUud8ErHPKTo3ueAKTnxgdh7rSS3tm: import(/* webpackChunkName: 'abi' */'../abi/8c3d6767bffb27d633bef00ccd5b8d1f.yaml'),
  TY1kDKr6Qo22fk9sWx2P68zLnwxUKDnJgV: import(/* webpackChunkName: 'abi' */'../abi/8c3d6767bffb27d633bef00ccd5b8d1f.yaml'),
  TNEZySF7a3u14psR3PjWQJEXjQ88XWed8R: import(/* webpackChunkName: 'abi' */'../abi/8c3d6767bffb27d633bef00ccd5b8d1f.yaml'),
  TVgx7WrWUBu7QNDLvRiFYaQnUKQvGYYsC3: import(/* webpackChunkName: 'abi' */'../abi/8c3d6767bffb27d633bef00ccd5b8d1f.yaml'),
  TPjNUMPiHgwutcvGxYCxEowLb9KaPqZDtj: import(/* webpackChunkName: 'abi' */'../abi/a69e5f4d13ca00e5da6153a8d24ed38a.yaml'),
  TSGnhZD3Ro6RJteaLNEaG6cajJqFzHczRD: import(/* webpackChunkName: 'abi' */'../abi/876963ff225f1a1cc25da7e30aaaea50.yaml'),
  TCtbBkgNiSUvNdKkNSecGuqwUp4D4GNiXe: import(/* webpackChunkName: 'abi' */'../abi/876963ff225f1a1cc25da7e30aaaea50.yaml'),
  TT3RQoAp55Efwca7rMSRNw1n2ppfx9bhTX: import(/* webpackChunkName: 'abi' */'../abi/14d26b2f18911d6d9c28132caf2aac59.yaml'),
  TKmLbFYqKshCp52H1MActJQVReQKuUS59W: import(/* webpackChunkName: 'abi' */'../abi/14d26b2f18911d6d9c28132caf2aac59.yaml'),
  TYwyWEmZtihgUWFMMqtNuo4YAwcng6C7AM: import(/* webpackChunkName: 'abi' */'../abi/0585cc15e75a128162791c8425bd562a.yaml'),
  TRbyUhtoHWGLHKTCRzfpgQy3nESuq55tCT: import(/* webpackChunkName: 'abi' */'../abi/8c3d6767bffb27d633bef00ccd5b8d1f.yaml'),
}

import {repeat} from "../../store/functions.coffee"

export default function(tronWeb) {
  return async function(address) {
    if(map[address]) {
      const abi = (await map[address]).default
      const contract = tronWeb.contract()
      contract.address = tronWeb.address.toHex(address)
      contract.deployed = true
      contract.loadAbi(abi)
      return contract
    } else {
      return repeat(() => tronWeb.contract().at(address))
    }
  }
}
