













import {mapState, mapGetters} from 'vuex'
import {verboseSend} from '../../store/functions.coffee'
import icon from '../../components/icons/wallet.vue'

export default
  components: {icon}
  data: ->
    processing: false
  computed: {
    ...mapState 'Cashback', {
      contract: 'contractCashback'
    }
    ...mapGetters 'Cashback',
      value: 'cashbackTokensAvailable'
  }
  methods:
    collect: ->
      @processing = true
      verboseSend @contract.getTokens()
      .then (msg) =>
        msg.message = 'Транзакция отправлена. Информация на странице обновится через несколько секунд'
        msg.duration = 3000
        @$emit 'reload', silent: true
      .finally =>
        @processing = false
