
















import { mapGetters, mapState } from 'vuex'
import icon from '../../components/AddressIcon.vue'

export default
  components: {icon}
  computed: {
    ...mapGetters 'KingOfTheHill', ['lastBuyer']
    ...mapState 'KingOfTheHill', ['stopped']
  }
